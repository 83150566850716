
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import TenantWizard from '@/ui/components/wizards/tenantWizard/components/index.vue';
import BasicTenantModelEditor from '@/ui/components/wizards/tenantWizard/components/BasicTenantModelEditor.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import TenantStatistic from '@/ui/components/wizards/tenantWizard/components/TenantStatistic.vue';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { IProject } from '@/types/project.types';
import { IAppState } from '@/store/modules/app/types';
import { IMember } from '@/types/members.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    TenantWizard,
    BasicTenantModelEditor,
    TenantStatistic,
    LeaveConfirmationModal,
  },
})
export default class Tenant extends Vue {
  @State('tenantWizard') wizardState!: ITenantWizardState;
  @State('app') appState!: IAppState;
  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: IMember;
  @Mutation('tenantWizard/setWasTenantDoneState') setWasTenantDoneState!: (data: boolean) => void;
  @Mutation('tenantWizard/resetPageStepIndex') resetPageStepIndex!: () => void;
  @Mutation('tenantWizard/reset') reset!: () => void;
  @Action('tenantWizard/loadTenant') loadTenant!: (project_id: string) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('members/fetchMember') fetchMember!: (payload: { memberId: string; projectId: string }) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('devices/fetchDevices') fetchDevices!: (payload: any) => Promise<void>;

  currentViewIndex= 0;
  showLoader = true;

  /**
   * Returns list of all Navigation Buttons for the User
   */
  get navigationOptions() {
    const items = [
      {
        id: 'basicModelEditor',
        title: this.$t('tenantWizard.basicTenantModelEditor.title') as string,
        component: BasicTenantModelEditor,
      },
      {
        id: 'tenantStatistic',
        title: this.$t('tenantWizard.tenantStatistics.title') as string,
        component: TenantStatistic,
      },
    ];
    return items;
  }

  get user() {
    return this.appState.user;
  }

  // if this value is true, then we show BasicTenantModelEditor else we show TenantWizard
  get wasTenantDone(): boolean {
    return this.wizardState.wasTenantDone;
  }

  get wasTenantRestarted(): boolean {
    return this.wizardState.wasTenantRestarted;
  }

  wasModelUpdated = false;

  async created() {
    // show loader
    this.setProjectLoader(true);
    this.showLoader = true;

    // load/reset data
    this.reset();
    await Promise.allSettled([
      this.fetchMeasurements(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
      this.fetchMember({ memberId: this.user.id, projectId: this.$route.params.id }),
      this.loadTenant(this.$route.params.id),
      this.fetchDevices({ projectId: this.$route.params.id }),
      this.fetchRooms(this.$route.params.id),
    ]);
    // disbale loader
    this.showLoader = false;
    this.setProjectLoader(false);
    // check if tenant was done (if yes we show basic editor, if not we show wizard)
    // if tenant was done, we also check if the billing settings are set (for backwards compatibility) or if the wizard was completed is in the project meta
    if (this.wizardState.tenant.billingSettings.mail !== '' || this.project.meta.wasTenantWizardCompleted === true) {
      this.setWasTenantDoneState(true);
    } else {
      this.setWasTenantDoneState(false);
    }
    this.currentViewIndex = 0;
  }

  tenantModelUpdated(value: boolean) {
    this.wasModelUpdated = value;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.wizardState.wasTenantDone === true) {
      // dont show confirmation modal if we are in stats view
      if (this.currentViewIndex === 1) next();
      //  dont show confirmation modal if model wasnt updated inside BasicTenantModelEditor
      if (this.wasModelUpdated === false) next();
    }
    const lastStep = this.wizardState.currentStep;
    this.resetPageStepIndex();
    if (lastStep === 5) {
      next();
    }
    await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction(to, next);
  }
}
