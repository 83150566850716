
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

/**
 * Heating pump single system settings.
 */
@Component({
  components: {
    OutputFieldBase,
    ShowEventDotBase,
    PushButtonBase,
    SliderBase,
    Switch2VBase,
    InputFieldBase,
    LabelUnitWrapper,
    ModalWindow,
  },
})
export default class HeatingPumpSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get outputFieldActualPower() {
    return {
      OutputField_actualValue: this.instanceData.power,
    };
  }
  get showEventDotState() {
    return {
      ShowEventDot_errorWarningState: this.instanceData.state_heating_pump,
    };
  }
  get outputFieldFlowTemperature() {
    return {
      OutputField_actualValue: this.instanceData.flow_temperature,
    };
  }
  get outputFieldReturnTemperature() {
    return {
      OutputField_actualValue: this.instanceData.return_temperature,
    };
  }
  get outputFieldSourceInlet() {
    return {
      OutputField_actualValue: this.instanceData.inlet_temperature,
    };
  }
  get outputFieldSourceOutlet() {
    return {
      OutputField_actualValue: this.instanceData.outlet_temperature,
    };
  }
  get outputFieldBoilerTempHeatingSystem() {
    return {
      OutputField_actualValue: this.instanceData.boiler_temperature,
    };
  }
  get outputFieldBoilerTempServiceWater() {
    return {
      OutputField_actualValue: this.instanceData.boiler_water_temperature,
    };
  }
  get outputFieldTargetPowerHeatingPump() {
    return {
      OutputField_actualValue: this.instanceData.target_power,
    };
  }
  get inputFieldHourOn() {
    return {
      InputField_targetValue: this.instanceData.hour_on,
    };
  }
  get inputFieldMinOn() {
    return {
      InputField_targetValue: this.instanceData.minute_on,
    };
  }
  get inputFieldHourOff() {
    return {
      InputField_targetValue: this.instanceData.hour_off,
    };
  }
  get inputFieldMinOff() {
    return {
      InputField_targetValue: this.instanceData.minute_off,
    };
  }

  get inputFieldPriorityEMS() {
    return {
      InputField_targetValue: this.instanceData.priority,
    };
  }
  get inputFieldEnableSoc() {
    return {
      InputField_targetValue: this.instanceData.enable_soc,
    };
  }
  get inputFieldDisableSOC() {
    return {
      InputField_targetValue: this.instanceData.disable_soc,
    };
  }

  get slider1Mappings() {
    return {
      Slider_targetValue: this.instanceData.slider_manual,
    };
  }
  get slider1ActualValueState() {
    return this.measurements.get(this.instanceData.manual_power);
  }
  get slider1IsActive() {
    return true;
  }

  get slider2Mappings() {
    return {
      Slider_targetValue: this.instanceData.slider_power,
    };
  }
  get slider2ActualValueState() {
    return this.measurements.get(this.instanceData.state_time_power);
  }
  get slider2IsActive() {
    return true;
  }

  get switch2VManualOn() {
    return {
      Switch2V_onOff: this.instanceData.switch_manual,
      Switch2V_state: this.instanceData.state_manual,
    };
  }
  get switch2VOnEmergencyPowerOff() {
    return {
      Switch2V_onOff: this.instanceData.switch_emergency,
      Switch2V_state: this.instanceData.state_emergency,
    };
  }
  get switch2VActivateTimeControl() {
    return {
      Switch2V_onOff: this.instanceData.switch_time,
      Switch2V_state: this.instanceData.state_time,
    };
  }
  get switch2VEnableHeatingPump() {
    return {
      Switch2V_onOff: this.instanceData.switch_enable,
      Switch2V_state: this.instanceData.state_enable,
    };
  }
  get pushButtonReset() {
    return {
      PushButton_onOff: this.instanceData.switch_reset,
      PushButton_state: this.instanceData.state_reset,
    };
  }

  get maxPowerList() {
    return Object.keys(this.instanceData.max_power);
  }
}
