
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import Wizard from '@/ui/components/wizards/baseComponents/Wizard.vue';
import {
  IInstallationWizardState,
} from '@/store/modules/installationWizard/types';
import { BatteryType } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { IDevice } from '@/types/devices.types';
import { IWizardLoadingState, IWizardPage } from '@/types/wizards/wizard.general.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    Wizard,
  },
})
export default class InstallationWizard extends Vue {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('installationWizard/currentStep') activeStep!: number;
  @Getter('installationWizard/wizardPages') wizardPages!: IWizardPage[];
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/batterySystemType') batterySystemType!: BatteryType | undefined;
  @Getter('installationWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('installationWizard/loadingState') loadingState!: IWizardLoadingState;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;

  loading = true;

  get currentPage() {
    return this.wizardState.currentPageIndex;
  }

  get hasBatteryDefined() {
    return !!this.batterySystemType;
  }

  get doesEnergyDevicesHaveBattery() {
    const hasEmsBattery = this.allDevices.find((device: IDevice) => device.data.type === 'EMSV2')?.data.meta.controllerMappings.battery.count > 0;
    const hasEnergyViewBattery = this.allDevices.find((device: IDevice) => device.data.type === 'EnergyViewV2')?.data.meta.controllerMappings.battery.count > 0;
    return hasEmsBattery && hasEnergyViewBattery;
  }

  get pages() {
    return this.wizardPages;
  }

  async mounted() {
    await new Promise((resolve, reject) => setTimeout(() => {
      this.loading = false;
      resolve();
    }, 1000));
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.all([
      this.fetchDevices(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
    ]);
    this.setProjectLoader(false);
  }
}
