
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent StaticIconAndTextButton type for PushButton base control
 */
@Component
export default class StaticIconAndTextButton extends BasicControl {
  @Prop({ default: null }) currentState!: number | null;
  @Prop({ default: 150 }) buttonWidth!: number;
  @Prop({ default: 36 }) buttonHeight!: number;
  @Prop({ default: 'theme' }) iconColor !: string;
  @Prop() iconSize?: number | string;
  @Prop({ default: 'white' }) buttonColor!: string;
  @Prop({ default: 'push_button' }) icon!: string;
}
