
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { IProject } from '@/types/project.types';
import { connectWithLineItems, getSelectedSystem } from '@/utils/installationWizardUtilsFunctions';
import { IDevice } from '@/types/devices.types';
import { IIncludedSystemsTypes } from '@/types/wizards/installationWizard.types';

const DEFINITIONS = {
  heating_pump_consumer: { connectWithLine: '', powerSGR1: '', powerSGR2: '' },
  big_consumer: { connectWithLine: '', power: '' },
  load_shedding: { maxBatteryPower: '', powerConsumer1: '', powerConsumer2: '' },
};

@Component({
  components: {
    InfoTooltip,
  },
})
export default class DeyeConsumerComponentSettings extends mixins(Validation, BasicControl) {
  @Prop({ default: {} }) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;
  @Getter('installationWizard/connectedLines') connectedLines!: number[];
  @Getter('installationWizard/consumerOptionsEnabled') consumerOptionsEnabled!: boolean;
  @Getter('installationWizard/wasInstallationWizardCompleted') wasInstallationWizardCompleted!: boolean;
  @Getter('installationWizard/getDisablePilotDevices') getDisablePilotDevices!: boolean;
  @Mutation('installationWizard/setEMSSystemCount') setEMSSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setConsumerOptionsEnabled') setConsumerOptionsEnabled!: (data: boolean) => void;
  @Mutation('installationWizard/setEnergyViewSystemCount') setEnergyViewSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    { systemName, systemIndex, prop, value }: any
  ) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystem') handleIncludedSystemsTypesSystem!: (
    { system, key, data }: { system: keyof IIncludedSystemsTypes; key: string; data: any }
  ) => void;

  rerenderKey = 0;
  valid = false;
  doesHover = false;
  localConnectWithLineItems = connectWithLineItems();
  selectedConsumer = 'load_shedding';
  optionsEnabled = false;

  systemLimits = {
    heating_pump_consumer: {
      min: 1,
      max: 1,
    },
    big_consumer: {
      min: 1,
      max: 2,
    },
    load_shedding: {
      min: 1,
      max: 1,
    },
  };

  localIncludedSystemTypes: any = {
    heating_pump_consumer: {
      isSelected: false,
      count: 0,
      definition: [],
    },
    big_consumer: {
      isSelected: false,
      count: 0,
      definition: [],
    },
    load_shedding: {
      isSelected: true,
      count: 1,
      definition: [{
        maxBatteryPower: 0,
        powerConsumer1: 0,
        powerConsumer2: 0,
      }],
    },
  }

  checkIsConsumer(elementIndex: number, deviceType: string) {
    if (!this.emsDevice.data.meta.controllerMappings[deviceType].components[`${deviceType}${elementIndex}`]) return true;
    return this.emsDevice.data.meta.controllerMappings[deviceType].components[`${deviceType}${elementIndex}`]?.power === '' &&
      this.emsDevice.data.meta.controllerMappings[deviceType].components[`${deviceType}${elementIndex}`]?.error === '' &&
      this.emsDevice.data.meta.controllerMappings[deviceType].components[`${deviceType}${elementIndex}`]?.external_energy_measurement === undefined;
  }

  get availableItems() {
    return [
      {
        text: 'SG Ready',
        value: 'heating_pump_consumer',
      },
      {
        text: this.$t('installationWizard.defineComponents.componentsPage.systems.big_consumer.title'),
        value: 'big_consumer',
      },
      {
        text: this.$t('installationWizard.defineComponents.componentsPage.systems.load_shedding.title'),
        value: 'load_shedding',
      },
    ];
  }

  filterConnectWithLine(systemType: any[]): any[] {
    return systemType.map((element: any) => {
      return typeof element.connectWithLine !== 'string' ? element.connectWithLine : undefined;
    }).filter(element => element !== undefined);
  }

  get hasDuplicateValue(): boolean | string {
    const heatingPumpArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.heating_pump_consumer.definition);
    const chargeStationArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.charge_station.definition);
    const bigConsumerArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.big_consumer.definition);

    const array = [heatingPumpArray, chargeStationArray, bigConsumerArray].flat();
    // Create an object to store the count of each value
    const count: any = {};

    // Iterate over each element in the array
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      // If the value is already in the count object, it's a duplicate
      if (count[value]) {
        return this.$t('installationWizard.defineComponents.componentsPage.duplicatePilotLineSelection').toString();
      }

      // Otherwise, add the value to the count object
      count[value] = 1;
    }

    // No duplicates found
    return true;
  }

  async handleSelectionChange(event: string) {
    const clone = JSON.parse(JSON.stringify(DEFINITIONS));

    this.handleIncludedSystemsTypesSystem({
      system: event as keyof IIncludedSystemsTypes, key: 'count', data: 1,
    });
    if (this.getDisablePilotDevices && event === 'heating_pump_consumer' || event === 'big_consumer') {
      clone[event].connectWithLine = 'noLine';
    }
    this.handleIncludedSystemsTypesSystem({
      system: event as keyof IIncludedSystemsTypes, key: 'definition', data: [clone[event]],
    });
    this.handleIncludedSystemsTypesSystem({
      system: event as keyof IIncludedSystemsTypes, key: 'isSelected', data: true,
    });
    if (event !== 'load_shedding') {
      const system = event === 'heating_pump_consumer' ? 'heating_pump' : event;
      const count = event === 'heating_pump_consumer' ? this.includedSystemsTypes.heating_pump.definition.length + 1 : 1;
      this.setEMSSystemCount({ system, count });
      this.setEnergyViewSystemCount({ system, count });
    }

    // will update counts and isSelected for other systems
    if (event === 'heating_pump_consumer') {
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.setEMSSystemCount({ system: 'big_consumer', count: 0 });
      this.setEnergyViewSystemCount({ system: 'big_consumer', count: 0 });
    }
    if (event === 'big_consumer') {
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.setEMSSystemCount({ system: 'heating_pump', count: 0 });
      this.setEnergyViewSystemCount({ system: 'heating_pump', count: 0 });
    }
    if (event === 'load_shedding') {
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.setEMSSystemCount({ system: 'heating_pump', count: 0 });
      this.setEnergyViewSystemCount({ system: 'heating_pump', count: 0 });
      this.setEMSSystemCount({ system: 'big_consumer', count: 0 });
      this.setEnergyViewSystemCount({ system: 'big_consumer', count: 0 });
    }

    // Validate form to see rules errors
    await this.$nextTick();
    this.validate();
  }

  async handleQuantitySelect(newCount: number, system_name: 'heating_pump_consumer' | 'big_consumer' | 'load_shedding') {
    const oldCount = this.localIncludedSystemTypes[system_name].count;
    const currentDefinitions = this.localIncludedSystemTypes[system_name].definition;

    if (newCount > oldCount) {
      const amount = newCount - oldCount;
      const newObjects = JSON.parse(JSON.stringify(
        Array(amount).fill({ ...DEFINITIONS[system_name] }),
      ));
      if (this.getDisablePilotDevices && system_name === 'heating_pump_consumer' || system_name === 'big_consumer') {
        newObjects.forEach((element: any) => {
          element.connectWithLine = 'noLine';
        });
      }
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes,
        key: 'definition',
        data: [...currentDefinitions, ...newObjects],
      });
    }
    if (newCount < oldCount) {
      const amount = oldCount - newCount;
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes,
        key: 'definition',
        data: currentDefinitions.slice(0, -amount),
      });
    }

    this.handleIncludedSystemsTypesSystem({
      system: system_name as keyof IIncludedSystemsTypes, key: 'count', data: newCount,
    });
    this.setEMSSystemCount({ system: system_name === 'heating_pump_consumer' ? 'heating_pump' : system_name, count: newCount });
    this.setEnergyViewSystemCount({ system: system_name === 'heating_pump_consumer' ? 'heating_pump' : system_name, count: newCount });
    this.rerenderKey += 1;

    // Validate form to see rules errors
    await this.$nextTick();
    this.validate();
  }

  // updates IncludedSystemsDefinitions in Store
  async handleChange(system_index: number | string, prop: string, value: any, systemType: string) {
    const index = typeof system_index === 'string' ? parseInt(system_index, 10) : system_index;
    this.handleIncludedSystemsTypesSystemDefinitionProps({
      systemName: systemType,
      systemIndex: index,
      prop,
      value,
    });
    this.rerenderKey++;
    this.$nextTick(() => {
      this.validate();
    });
  }

  async handlePilotLineChange(system_index: number | string, prop: string, value: any, systemType: string) {
    this.handleIncludedSystemsTypesSystemDefinitionProps({
      systemName: systemType, systemIndex: system_index, prop, value,
    });
    this.rerenderKey++;
    this.$nextTick(() => {
      this.validate();
    });
  }

  @Watch('connectedLines', { deep: true })
  updateLineItems() {
    this.localConnectWithLineItems = this.localConnectWithLineItems.map((item: any) => {
      item.disabled = this.connectedLines.includes(item.value);
      return item;
    });
  }

  validate() {
    if (!this.optionsEnabled) {
      this.valid = true;
    } else {
      (this.$refs.form as any).validate();
    }
    this.$emit('deyeConsumerValidationStatus', { system: 'deyeConsumer', status: this.valid });
  }

  created() {
    if (this.wasInstallationWizardCompleted) {
      const allConsumers = ['heating_pump', 'big_consumer', 'load_shedding'];
      this.selectedConsumer = this.consumerOptionsEnabled ? getSelectedSystem(this.emsDevice.data.meta.controllerMappings, allConsumers, 'load_shedding') : '';
      this.selectedConsumer = this.selectedConsumer === 'heating_pump' ? 'heating_pump_consumer' : this.selectedConsumer;
    } else {
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'heating_pump_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'big_consumer' as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'count', data: 1,
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes,
        key: 'definition',
        data: [{ maxBatteryPower: 0, powerConsumer1: 0, powerConsumer2: 0 }],
      });
      this.handleIncludedSystemsTypesSystem({
        system: 'load_shedding' as keyof IIncludedSystemsTypes, key: 'isSelected', data: true,
      });
    }

    this.optionsEnabled = (this.consumerOptionsEnabled || this.selectedConsumer !== 'load_shedding') && this.selectedConsumer !== '';
    this.handleConsumerOptionsEnabled(this.optionsEnabled, true);
  }

  async handleConsumerOptionsEnabled(checked: boolean, init = false) {
    if (!checked) this.clearSystem();
    else {
      if (!init) {
        this.selectedConsumer = 'load_shedding';
        await this.handleSelectionChange('load_shedding');
      }
    }
    this.setConsumerOptionsEnabled(checked);
    this.$nextTick(() => this.validate());
    this.$emit('reRender');
  }

  clearSystem() {
    const allConsumers = ['heating_pump_consumer', 'big_consumer', 'load_shedding'];
    allConsumers.forEach((consumer) => {
      this.handleIncludedSystemsTypesSystem({
        system: consumer as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
      this.handleIncludedSystemsTypesSystem({
        system: consumer as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.handleIncludedSystemsTypesSystem({
        system: consumer as keyof IIncludedSystemsTypes, key: 'definition', data: [],
      });
      if (consumer !== 'load_shedding') {
        const emsConsumer = consumer === 'heating_pump_consumer' ? 'heating_pump' : consumer;
        this.setEMSSystemCount({ system: emsConsumer, count: 0 });
        this.setEnergyViewSystemCount({ system: emsConsumer, count: 0 });
      }
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
    this.updateLineItems();
  }
}
