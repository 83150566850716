import mlModelTypes from '@/store/modules/mpc/mlModelTypes';

/**
 * A collection of device schemas with these options:
 * - **mappings** - collection of mappings used in device, are also the form fields in the manage modal form.
 * mapping name consists of Basic Control name and variable.
 * Example: Switch_on: {...} = BasicControl_variable: {...}
 * - **manageSchema** - manage modal form used to create, update device
 * - **isSettingsView** - status whether the device has a settings window
 * - **devicesSchemas** - basicDevices: basic controls which used on device main view;
 *                        additionalBasicDevices: basic controls which used on device settings view;
 */
const devicesTypes = {
  LightSwitch: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch'],
      additionalBasicDevices: [],
    },
  },
  GeneralSwitchV2: {
    mappings: {
      'Switch2V_onOff': {
        optional: false,
      },
      'Switch2V_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch'],
      additionalBasicDevices: [],
    },
  },
  LightDimmer: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'Slider_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch', 'OutputField', 'Slider'],
      additionalBasicDevices: [],
    },
  },
  Temperature: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: -100, max: 1500 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  VariableOutputField: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    iconSelection: true,
    unitSelection: true,
    devicesSchemas: {
    },
  },
  VariableInputField: {
    mappings: {
      'InputField_targetValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    iconSelection: true,
    unitSelection: true,
    devicesSchemas: {
      basicDevices: ['InputField'],
      additionalBasicDevices: [],
    },
  },
  VariableTextOutputField: {
    mappings: {
      'OutputFieldText_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 30 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    iconSelection: true,
    devicesSchemas: {
      basicDevices: ['OutputFieldText'],
      additionalBasicDevices: [],
    },
  },
  VariableTextInputField: {
    mappings: {
      'InputFieldText_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 30 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    iconSelection: true,
    devicesSchemas: {
      basicDevices: ['InputFieldText'],
      additionalBasicDevices: [],
    },
  },
  LightRGB: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'SliderRGB_targetValue_red': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'SliderRGB_targetValue_green': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'SliderRGB_targetValue_blue': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'OutputField1_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField2_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'OutputField3_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'OutputField4_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 255 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['Switch', 'SliderRGB', 'OutputField2', 'OutputField3', 'OutputField4'],
      additionalBasicDevices: ['Slider', 'OutputField1'],
    },
  },
  LightPushButton: {
    mappings: {
      'PushButton_onOff': {
        optional: false,
      },
      'PushButton_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['PushButton'],
      additionalBasicDevices: [],
    },
  },
  SocketSwitch: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch'],
      additionalBasicDevices: [],
    },
  },
  ControlBlinds: {
    mappings: {
      'ArrowUp_up': {
        optional: false,
      },
      'ArrowUp_state': {
        optional: false,
      },
      'ArrowDown_down': {
        optional: false,
      },
      'ArrowDown_state': {
        optional: false,
      },
      'DropDown_targetValue': {
        optional: true,
      },
      'PushButton_onOff': {
        optional: true,
      },
      'PushButton_state': {
        optional: true,
      },
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'InputField3_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10 },
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['ArrowUp', 'ArrowDown', 'Slider', 'OutputField', 'DropDown'],
      additionalBasicDevices: ['PushButton', 'InputField1', 'InputField2', 'InputField3'],
    },
  },
  ControlAwning: {
    mappings: {
      'ArrowOut_out': {
        optional: false,
      },
      'ArrowOut_state': {
        optional: false,
      },
      'ArrowIn_in': {
        optional: false,
      },
      'ArrowIn_state': {
        optional: false,
      },
      'PushButton_onOff': {
        optional: true,
      },
      'PushButton_state': {
        optional: true,
      },
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['ArrowOut', 'ArrowIn', 'Slider', 'OutputField'],
      additionalBasicDevices: ['PushButton', 'InputField1', 'InputField2'],
    },
  },
  ControlShutter: {
    mappings: {
      'ArrowUp_up': {
        optional: false,
      },
      'ArrowUp_state': {
        optional: false,
      },
      'ArrowDown_down': {
        optional: false,
      },
      'ArrowDown_state': {
        optional: false,
      },
      'PushButton_onOff': {
        optional: true,
      },
      'PushButton_state': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 600 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['ArrowUp', 'ArrowDown', 'Slider', 'OutputField'],
      additionalBasicDevices: ['PushButton', 'InputField1', 'InputField2'],
    },
  },
  ThermostatDigital: {
    mappings: {
      'Plus_commandPlus': {
        optional: false,
      },
      'Minus_commandMinus': {
        optional: false,
      },
      'Switch1_on': {
        optional: false,
      },
      'Switch1_off': {
        optional: false,
      },
      'Switch1_state': {
        optional: false,
      },
      'Switch2_on': {
        optional: false,
      },
      'Switch2_off': {
        optional: false,
      },
      'Switch2_state': {
        optional: false,
      },
      'Switch3_on': {
        optional: false,
      },
      'Switch3_off': {
        optional: false,
      },
      'Switch3_state': {
        optional: false,
      },
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 50 },
      },
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField3_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField4_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'ShowEventDot1_errorWarningState': {
        optional: true,
      },
      'ShowEventDot2_errorWarningState': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['Plus', 'Minus', 'OutputField', 'ShowEventDot1', 'ShowEventDot2'],
      additionalBasicDevices: ['InputField1', 'InputField2', 'InputField3', 'InputField4', 'Switch1', 'Switch2', 'Switch3'],
    },
  },
  ThermostatAnalog: {
    mappings: {
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 50 },
      },
      'InputField2_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 50 },
      },
      'InputField3_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField4_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField5_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField6_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 59 },
      },
      'Switch1_on': {
        optional: true,
      },
      'Switch1_off': {
        optional: true,
      },
      'Switch1_state': {
        optional: true,
      },
      'Switch2_on': {
        optional: true,
      },
      'Switch2_off': {
        optional: true,
      },
      'Switch2_state': {
        optional: true,
      },
      'Switch3_on': {
        optional: true,
      },
      'Switch3_off': {
        optional: true,
      },
      'Switch3_state': {
        optional: true,
      },
      'ShowEventDot1_errorWarningState': {
        optional: true,
      },
      'ShowEventDot2_errorWarningState': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['InputField1', 'ShowEventDot1', 'ShowEventDot2'],
      additionalBasicDevices: [
        'InputField2',
        'InputField3',
        'InputField4',
        'InputField5',
        'InputField6',
        'Switch1',
        'Switch2',
        'Switch3',
      ],
    },
  },
  AirHumiditySensor: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  BrightnessSensor: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  SensorCO2: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 5000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  SensorLevel: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  WeatherStation: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 200 },
      },
      'OutputField2_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 200 },
      },
      'OutputField3_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 200 },
      },
      'OutputField4_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'OutputField5_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField6_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 50 },
      },
      'OutputField7_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField1', 'OutputField2', 'OutputField3', 'OutputField4', 'OutputField5', 'OutputField6', 'OutputField7'],
      additionalBasicDevices: [],
    },
  },
  HotWaterMeter: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField1'],
      additionalBasicDevices: ['OutputField2'],
    },
  },
  ColdWaterMeter: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField1'],
      additionalBasicDevices: ['OutputField2'],
    },
  },
  HeatingMeter: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: false,
      },
      'OutputField3_actualValue': {
        optional: true,
      },
      'OutputField4_actualValue': {
        optional: true,
      },
      'OutputField5_actualValue': {
        optional: true,
      },
      'OutputField6_actualValue': {
        optional: true,
      },
      'OutputField7_actualValue': {
        optional: true,
      },
      'OutputField8_actualValue': {
        optional: true,
      },
      'OutputField9_actualValue': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField2'],
      additionalBasicDevices: ['OutputField1', 'OutputField3', 'OutputField4', 'OutputField5', 'OutputField6', 'OutputField7', 'OutputField8', 'OutputField9'],
    },
  },
  ElectricityMeter: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: false,
      },
      'OutputField3_actualValue': {
        optional: false,
      },
      'OutputField4_actualValue': {
        optional: true,
      },
      'OutputField5_actualValue': {
        optional: true,
      },
      'OutputField6_actualValue': {
        optional: true,
      },
      'OutputField7_actualValue': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField1', 'OutputField2', 'OutputField3'],
      additionalBasicDevices: ['OutputField4', 'OutputField5', 'OutputField6', 'OutputField7'],
    },
  },
  MotionSensor: {
    mappings: {
      'InputField_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1200 },
      },
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'PushButton_onOff': {
        optional: false,
      },
      'PushButton_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['InputField', 'Switch', 'PushButton'],
      additionalBasicDevices: [],
    },
  },
  Battery: {
    mappings: {
      'Battery_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField1_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'OutputField2_actualValue': {
        optional: true,
        minMaxBorders: { min: -30, max: 120 },
      },
      'OutputField3_actualValue': {
        optional: true,
        minMaxBorders: { min: -1000, max: 1000 },
      },
      'OutputField4_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['Battery'],
      additionalBasicDevices: ['OutputField1', 'OutputField2', 'OutputField3', 'OutputField4'],
    },
  },
  PVSystem: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'OutputField2_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'OutputField3_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField', 'OutputField2', 'OutputField3'],
      additionalBasicDevices: [],
    },
  },
  HouseConsumption: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  Generator: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'Switch_on': {
        optional: true,
      },
      'Switch_off': {
        optional: true,
      },
      'Switch_state': {
        optional: true,
      },
      'PushButton_onOff': {
        optional: true,
      },
      'PushButton_state': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: ['Switch', 'PushButton'],
    },
  },
  MainsConnection: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: -5000, max: 5000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['OutputField'],
      additionalBasicDevices: [],
    },
  },
  ElectricChargingStation: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'OutputField2_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 2000 },
      },
      'InputField_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'Switch1_on': {
        optional: true,
      },
      'Switch1_off': {
        optional: true,
      },
      'Switch1_state': {
        optional: true,
      },
      'Switch2_on': {
        optional: true,
      },
      'Switch2_off': {
        optional: true,
      },
      'Switch2_state': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEventDot_errorWarningState': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField1', 'ShowEventDot'],
      additionalBasicDevices: ['OutputField2', 'InputField', 'Switch1', 'Switch2', 'Slider', 'OutputField'],
    },
  },
  ElectronicBoiler: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'InputField1_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField2_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField3_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField4_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 59 },
      },
      'Switch1_on': {
        optional: true,
      },
      'Switch1_off': {
        optional: true,
      },
      'Switch1_state': {
        optional: true,
      },
      'Switch2_on': {
        optional: true,
      },
      'Switch2_off': {
        optional: true,
      },
      'Switch2_state': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField1'],
      additionalBasicDevices: ['InputField1', 'InputField2', 'InputField3', 'InputField4', 'Switch1', 'Switch2', 'Slider', 'OutputField'],
    },
  },
  VentilatorSwitch: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch'],
      additionalBasicDevices: [],
    },
  },
  MusicSystem: {
    mappings: {
      'Plus_commandPlus': {
        optional: true,
      },
      'Minus_commandMinus': {
        optional: true,
      },
      'Play_commandPlay': {
        optional: true,
      },
      'Stop_commandStop': {
        optional: true,
      },
      'Pause_commandPause': {
        optional: true,
      },
      'Forward_commandForward': {
        optional: true,
      },
      'Reverse_commandReverse': {
        optional: true,
      },
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Plus', 'Minus', 'Play', 'Stop', 'Pause', 'Forward', 'Reverse', 'Switch'],
      additionalBasicDevices: [],
    },
  },
  TV: {
    mappings: {
      'Plus_commandPlus': {
        optional: true,
      },
      'Minus_commandMinus': {
        optional: true,
      },
      'Forward_commandForward': {
        optional: true,
      },
      'Reverse_commandReverse': {
        optional: true,
      },
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Plus', 'Minus', 'Forward', 'Reverse', 'Switch'],
      additionalBasicDevices: [],
    },
  },
  MotorWithoutVFD: {
    mappings: {
      'PushButton1_onOff': {
        optional: true,
      },
      'PushButton1_state': {
        optional: true,
      },
      'PushButton2_onOff': {
        optional: true,
      },
      'PushButton2_state': {
        optional: true,
      },
      'PushButton3_onOff': {
        optional: true,
      },
      'PushButton3_state': {
        optional: true,
      },
      'PushButton4_onOff': {
        optional: true,
      },
      'PushButton4_state': {
        optional: true,
      },
      'ArrowLeft_commandLeft': {
        optional: false,
      },
      'ArrowLeft_state': {
        optional: false,
      },
      'ArrowRight_commandRight': {
        optional: false,
      },
      'ArrowRight_state': {
        optional: false,
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['PushButton1', 'PushButton2', 'PushButton3', 'PushButton4', 'ArrowLeft', 'ArrowRight'],
      additionalBasicDevices: ['OutputField'],
    },
  },
  PumpWithoutVFD: {
    mappings: {
      'PushButton1_onOff': {
        optional: true,
      },
      'PushButton1_state': {
        optional: true,
      },
      'PushButton2_onOff': {
        optional: true,
      },
      'PushButton2_state': {
        optional: true,
      },
      'PushButton3_onOff': {
        optional: true,
      },
      'PushButton3_state': {
        optional: true,
      },
      'PushButton4_onOff': {
        optional: true,
      },
      'PushButton4_state': {
        optional: true,
      },
      'ArrowLeft_commandLeft': {
        optional: false,
      },
      'ArrowLeft_state': {
        optional: false,
      },
      'ArrowRight_commandRight': {
        optional: false,
      },
      'ArrowRight_state': {
        optional: false,
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['PushButton1', 'PushButton2', 'PushButton3', 'PushButton4', 'ArrowLeft', 'ArrowRight'],
      additionalBasicDevices: ['OutputField'],
    },
  },
  MotorWithVFD: {
    mappings: {
      'PushButton1_onOff': {
        optional: true,
      },
      'PushButton1_state': {
        optional: true,
      },
      'PushButton2_onOff': {
        optional: true,
      },
      'PushButton2_state': {
        optional: true,
      },
      'PushButton3_onOff': {
        optional: true,
      },
      'PushButton3_state': {
        optional: true,
      },
      'PushButton4_onOff': {
        optional: true,
      },
      'PushButton4_state': {
        optional: true,
      },
      'ArrowLeft_commandLeft': {
        optional: false,
      },
      'ArrowLeft_state': {
        optional: false,
      },
      'ArrowRight_commandRight': {
        optional: false,
      },
      'ArrowRight_state': {
        optional: false,
      },
      'OutputField1_actualValue': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: true,
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['PushButton1', 'PushButton2', 'PushButton3', 'PushButton4', 'ArrowLeft', 'ArrowRight', 'OutputField', 'Slider'],
      additionalBasicDevices: ['OutputField1'],
    },
  },
  PumpWithVFD: {
    mappings: {
      'PushButton1_onOff': {
        optional: true,
      },
      'PushButton1_state': {
        optional: true,
      },
      'PushButton2_onOff': {
        optional: true,
      },
      'PushButton2_state': {
        optional: true,
      },
      'PushButton3_onOff': {
        optional: true,
      },
      'PushButton3_state': {
        optional: true,
      },
      'PushButton4_onOff': {
        optional: true,
      },
      'PushButton4_state': {
        optional: true,
      },
      'ArrowLeft_commandLeft': {
        optional: false,
      },
      'ArrowLeft_state': {
        optional: false,
      },
      'ArrowRight_commandRight': {
        optional: false,
      },
      'ArrowRight_state': {
        optional: false,
      },
      'OutputField1_actualValue': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: true,
      },
      'OutputField_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 1000 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['PushButton1', 'PushButton2', 'PushButton3', 'PushButton4', 'ArrowLeft', 'ArrowRight', 'OutputField', 'Slider'],
      additionalBasicDevices: ['OutputField1'],
    },
  },
  MixingValve: {
    mappings: {
      'PushButton1_onOff': {
        optional: true,
      },
      'PushButton1_state': {
        optional: true,
      },
      'PushButton2_onOff': {
        optional: true,
      },
      'PushButton2_state': {
        optional: true,
      },
      'PushButton3_onOff': {
        optional: true,
      },
      'PushButton3_state': {
        optional: true,
      },
      'PushButton4_onOff': {
        optional: true,
      },
      'PushButton4_state': {
        optional: true,
      },
      'Slider_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['PushButton1', 'PushButton2', 'PushButton3', 'PushButton4', 'Slider', 'OutputField'],
      additionalBasicDevices: [],
    },
  },
  Ventilation: {
    mappings: {
      'Switch_on': {
        optional: false,
      },
      'Switch_off': {
        optional: false,
      },
      'Switch_state': {
        optional: false,
      },
      'InputField_targetValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 100 },
      },
      'OutputField1_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 3000 },
      },
      'OutputField2_actualValue': {
        optional: true,
        minMaxBorders: { min: 0, max: 3000 },
      },
      'PushButton_onOff': {
        optional: true,
      },
      'PushButton_state': {
        optional: true,
      },
      'ShowEventDot1_errorWarningState': {
        optional: true,
      },
      'ShowEventDot2_errorWarningState': {
        optional: true,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['Switch', 'InputField', 'OutputField1', 'OutputField2', 'PushButton', 'ShowEventDot1', 'ShowEventDot2'],
      additionalBasicDevices: [],
    },
  },
  Gauge: {
    mappings: {
      'CurrentValueGaugeChart_actualValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'GaugeSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['CurrentValueGaugeChart'],
      additionalBasicDevices: [],
    },
  },
  RegentLighting: {
    mappings: {
      'clc_presence': {
        optional: false,
      },
      'clc_deskinuse': {
        optional: false,
      },
      'clc_brightness': {
        optional: false,
      },
      'clc_temperature': {
        optional: false,
      },
      'clc_humidity': {
        optional: false,
      },
      'clc_tvoc': {
        optional: false,
      },
      'clc_co2eq': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: [],
      additionalBasicDevices: [],
    },
  },
  DropDownOutputFieldWithVariable: {
    mappings: {
      'DropDown_targetValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DropDownSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['DropDown'],
      additionalBasicDevices: [],
    },
  },
  DropDownInputFieldWithVariable: {
    mappings: {
      'DropDown_targetValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DropDownSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: ['DropDown'],
      additionalBasicDevices: [],
    },
  },
  TSGFrischwasser: {
    mappings: {
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'InputField3_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'PushButton1_onOff': {
        optional: false,
      },
      'PushButton1_state': {
        optional: false,
      },
      'PushButton2_onOff': {
        optional: false,
      },
      'PushButton2_state': {
        optional: false,
      },
      'InputField4_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField5_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField6_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 25000 },
      },
      'InputField7_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField8_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField9_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField10_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField11_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 60 },
      },
      'InputField12_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField13_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField14_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'ShowEventDot1_errorWarningState': {
        optional: false,
      },
      'ShowEventDot2_errorWarningState': {
        optional: false,
      },
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: false,
      },
      'OutputField3_actualValue': {
        optional: false,
      },
      'OutputField4_actualValue': {
        optional: false,
      },
      'OutputField5_actualValue': {
        optional: false,
      },
      'DropDown1_targetValue': {
        optional: false,
      },
      'DropDown2_targetValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: [
        // reset button
        'PushButton2',
        'ShowEventDot1',
        'ShowEventDot2',
        'OutputField1',
        'OutputField2',
        'OutputField3',
        'OutputField4',
        'OutputField5',
        'DropDown1',
        'DropDown2',
      ],
      additionalBasicDevices: [
        'InputField1',
        'InputField2',
        'InputField3',
        // other button
        'PushButton1',
        'InputField4',
        'InputField5',
        'InputField6',
        'InputField7',
        'InputField8',
        'InputField9',
        'InputField10',
        'InputField11',
        'InputField12',
        'InputField13',
        'InputField14',
      ],
    },
  },
  TSGBrauchwasser: {
    mappings: {
      'InputField1_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'InputField2_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'InputField3_targetValue': {
        optional: false,
        minMaxBorders: { min: -999, max: 999 },
      },
      'PushButton1_onOff': {
        optional: false,
      },
      'PushButton1_state': {
        optional: false,
      },
      'PushButton2_onOff': {
        optional: false,
      },
      'PushButton2_state': {
        optional: false,
      },
      'InputField4_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField5_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField6_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 25000 },
      },
      'InputField7_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField8_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField9_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 23 },
      },
      'InputField10_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 59 },
      },
      'InputField11_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'InputField12_targetValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 10000 },
      },
      'ShowEventDot1_errorWarningState': {
        optional: false,
      },
      'ShowEventDot2_errorWarningState': {
        optional: false,
      },
      'OutputField1_actualValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: false,
      },
      'OutputField3_actualValue': {
        optional: false,
      },
      'DropDown1_targetValue': {
        optional: false,
      },
      'DropDown2_targetValue': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: [
        // reset Button
        'PushButton2',
        'ShowEventDot1',
        'ShowEventDot2',
        'OutputField1',
        'OutputField2',
        'OutputField3',
        'DropDown1',
        'DropDown2',
      ],
      additionalBasicDevices: [
        'InputField1',
        'InputField2',
        'InputField3',
        // other button
        'PushButton1',
        'InputField4',
        'InputField5',
        'InputField6',
        'InputField7',
        'InputField8',
        'InputField9',
        'InputField10',
        'InputField11',
        'InputField12',
      ],
    },
  },
  Robot: {
    mappings: {
      'StatusIcon1_currentValue': {
        optional: false,
      },
      'OutputField1_actualValue': {
        optional: false,
      },
      'StatusIcon2_currentValue': {
        optional: false,
      },
      'StatusIcon3_currentValue': {
        optional: false,
      },
      'OutputField7_actualValue': {
        optional: false,
      },
      'StatusIcon4_currentValue': {
        optional: false,
      },
      'OutputFieldText2_actualValue': {
        optional: false,
      },
      'StatusIcon5_currentValue': {
        optional: false,
      },
      'OutputFieldText3_actualValue': {
        optional: false,
      },
      'OutputFieldText4_actualValue': {
        optional: false,
      },
      'FillLevelDisplay1_actualValue': {
        optional: false,
      },
      'Slider1_targetValue': {
        optional: false,
      },
      'Slider2_targetValue': {
        optional: false,
      },
      'PushButton1_onOff': {
        optional: false,
      },
      'PushButton1_state': {
        optional: false,
      },
      'PushButton2_onOff': {
        optional: false,
      },
      'PushButton2_state': {
        optional: false,
      },
      'DropDown1_targetValue': {
        optional: false,
      },
      'OutputField5_actualValue': {
        optional: false,
      },
      'DateTimePicker1_actualValue': {
        optional: false,
      },
      'PushButton3_onOff': {
        optional: false,
      },
      'PushButton3_state': {
        optional: false,
      },
      'OutputField6_actualValue': {
        optional: false,
      },
      'OutputField8_actualValue': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: [
        // Status Leiste
        'StatusIcon1',
        // Number Satellites
        'OutputField1',
        'StatusIcon2',
        'StatusIcon3',
        'OutputField7',
        'StatusIcon4',
        // Meldungs Box
        'OutputFieldText2',
        'StatusIcon5',
        'OutputFieldText3',
        // Position/Progress Part
        'OutputFieldText4',
        'FillLevelDisplay1',
        // anhalten
        'PushButton1',
        // abbrechen
        'PushButton2',
        // sliderpart
        'Slider1',
        'Slider2',
        'OutputField8',
      ],
      additionalBasicDevices: [
        // muster info: only text no image will be shown for dash
        'DropDown1',
        'OutputField5',
        'DateTimePicker1',
        // upload Job
        'PushButton3',
        // text mowing Job
        'OutputField6',
        'Slider1',
        'Slider2',
        'OutputFieldText4',
        'OutputField8',
      ],
    },
  },
  SurveyClient: {
    mappings: {
      'OutputField1_actualValue': {
        optional: false,
      },
      'StatusIcon1_currentValue': {
        optional: false,
      },
      'StatusIcon2_currentValue': {
        optional: false,
      },
      'OutputField2_actualValue': {
        optional: false,
      },
      'StatusIcon3_currentValue': {
        optional: false,
      },
      'InputFieldText1_targetValue': {
        optional: false,
      },
      'InputFieldText1_state': {
        optional: false,
      },
      'DropDown1_targetValue': {
        optional: false,
      },
      'DropDown1_state': {
        optional: false,
      },
      'OutputFieldText1_actualValue': {
        optional: false,
      },
      'StatusIcon4_currentValue': {
        optional: false,
      },
      'OutputFieldText2_actualValue': {
        optional: false,
      },
      'OutputFieldText3_actualValue': {
        optional: false,
      },
      'ShowEventDot1_errorWarningState': {
        optional: false,
      },
      'PushButton1_onOff': {
        optional: false,
      },
      'PushButton2_onOff': {
        optional: false,
      },
      'PushButton3_onOff': {
        optional: false,
      },
      'PushButton3_state': {
        optional: false,
      },
      'PushButton4_onOff': {
        optional: false,
      },
      'PushButton4_state': {
        optional: false,
      },
      'PushButton5_onOff': {
        optional: false,
      },
      'PushButton5_state': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: [
        // Status Leiste
        'StatusIcon1',
        'OutputField1',
        'StatusIcon2',
        'OutputField2',
        'StatusIcon3',
        // InputFieldTexts Fieldname/type
        'InputFieldText1',
        'DropDown1',
        // InstructionsField with Icon
        'OutputFieldText1',
        'StatusIcon4',
        'OutputFieldText2',
        // Stepnumber Stuff
        'OutputFieldText3',
        'ShowEventDot1',
        // Buttons
        'PushButton1',
        'PushButton2',
        'PushButton3',
        'PushButton4',
        'PushButton5',
      ],
      additionalBasicDevices: [],
    },
  },
  TSGLadestationNotAus: {
    mappings: {
      'DropDown1_targetValue': {
        optional: false,
      },
      'DropDown2_targetValue': {
        optional: false,
      },
      'PushButton_onOff': {
        optional: false,
      },
      'PushButton_state': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: [
        'DropDown1',
        'DropDown2',
        'ShowEventDot1',
        'ShowEventDot2',
        'PushButton',
      ],
      additionalBasicDevices: [],
    },
  },
  TSGModulLadestation: {
    mappings: {
      'ShowEventDot_errorWarningState': {
        optional: false,
      },
      // aktulle leistung kW
      'OutputField1_actualValue': {
        optional: false,
      },
      // geladene energie der session kWh
      'OutputField2_actualValue': {
        optional: false,
      },
      // ladedauer der session min
      'OutputField3_actualValue': {
        optional: false,
      },
      // soc %
      'OutputField4_actualValue': {
        optional: true,
      },
      // total energy kWh
      'OutputField5_actualValue': {
        optional: false,
      },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: false,
    devicesSchemas: {
      basicDevices: [
        'ShowEventDot_errorWarningState',
        'OutputField1_actualValue',
        'OutputField2_actualValue',
        'OutputField3_actualValue',
        'OutputField4_actualValue',
        'OutputField5_actualValue',
      ],
      additionalBasicDevices: [],
    },
  },
  EMSV2: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'state_enable_ems', 'allow_charging_state', 'message', 'state_main_fuse', 'inverter_power', 'force_charge', 'force_charge_2', 'calibration_charge', 'maintenance_charge', 'charge_maintenance'],
      outputMappings: [
        'heartbeat', 'mpcReady', 'operation_mode',
        'max_depth', 'reserve_battery', 'min_charge_battery', 'reserve_charge', 'update_time',
        'enable_ems', 'calculated_battery_power', 'activate_heating_pump', 'allow_charging_button',
        'enable_controlling', 'activate_main_fuse', 'size_main_fuse',
      ],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      activate_heating_pump: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      calculated_battery_power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      state_enable_ems: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      enable_ems: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      size_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        isDynamic: true,
        maxRange: 30,
        minMaxBorders: { min: 0, max: 500 },
      },
      operation_mode: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      max_depth: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 10, max: 100 },
      },
      reserve_battery: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 11, max: 100 },
      },
      min_charge_battery: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 25, max: 75 },
      },
      reserve_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      update_time: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 10, max: 600 },
      },
      message: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      allow_charging_button: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      allow_charging_state: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      enable_controlling: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      state_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      inverter_power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      activate_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      force_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      force_charge_2: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      calibration_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      maintenance_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      charge_maintenance: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
    },
    groups: {
      producer: {
        id: 'producer',
        title: 'Producer',
        systems: ['pv', 'chp', 'generator'],
      },
      producerConsumer: {
        id: 'producerConsumer',
        title: 'Producer Consumer',
        systems: ['grid', 'battery'],
      },
      consumer: {
        id: 'consumer',
        title: 'Consumer',
        systems: ['house', 'charge_station', 'electric_heating', 'heating_pump', 'big_consumer'],
      },
    },
    systems: {
      pv: {
        id: 'pv',
        title: 'PV System',
        required: false,
        group: 'producer',
        quantity: 40,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      chp: {
        id: 'chp',
        title: 'CHP System',
        required: false,
        group: 'producer',
        quantity: 3,
        mappingsByColumns: {
          input: [
            'power', 'error', 'status', 'state_enable', 'operation', 'state_activate_cooling',
            'state_activate_heating', 'target_power', 'return_temperature', 'flow_temperature',
            'buffer_temperature_top', 'buffer_temperature_bottom', 'thermal_power_output', 'energy_counter',
            'energy_counter_thermal',
          ],
          output: [
            'switch_enable', 'switch_activate_cooling', 'switch_activate_heating',
            'parallel_grid_target_power', 'parallel_grid_mode', 'buffer_storage_capacity', 'thermal_power_input', 'enable_soc', 'disable_soc',
          ],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          status: '',
          state_enable: '',
          operation: '',
          state_activate_cooling: '',
          state_activate_heating: '',
          target_power: '',
          return_temperature: '',
          flow_temperature: '',
          buffer_temperature_top: '',
          buffer_temperature_bottom: '',
          thermal_power_output: '',
          energy_counter: '',
          energy_counter_thermal: '',
          enable_soc: '',
          disable_soc: '',
          switch_enable: '',
          switch_activate_cooling: '',
          switch_activate_heating: '',
          parallel_grid_target_power: '',
          parallel_grid_mode: '',
          buffer_storage_capacity: '',
          thermal_power_input: '',
          title: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          status: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          operation: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_activate_cooling: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_activate_heating: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          target_power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          return_temperature: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          flow_temperature: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          buffer_temperature_top: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          buffer_temperature_bottom: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          thermal_power_output: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter_thermal: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          enable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          disable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_activate_cooling: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_activate_heating: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          parallel_grid_target_power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          parallel_grid_mode: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          buffer_storage_capacity: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          thermal_power_input: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
        },
      },
      generator: {
        id: 'generator',
        title: 'Generator',
        required: false,
        group: 'producer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'state_reset', 'error', 'state_generator', 'state_enable', 'energy_counter', 'state_timelock'],
          output: ['enable_soc', 'disable_soc', 'switch_enable', 'switch_reset', 'hour_on', 'minute_on', 'hour_off', 'minute_off', 'enable_timelock'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          state_reset: '',
          state_generator: '',
          enable_soc: '',
          disable_soc: '',
          switch_enable: '',
          switch_reset: '',
          state_enable: '',
          energy_counter: '',
          hour_on: '',
          minute_on: '',
          hour_off: '',
          minute_off: '',
          enable_timelock: '',
          state_timelock: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          state_reset: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_generator: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          enable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          disable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_reset: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: 'power',
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          hour_on: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          minute_on: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          hour_off: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          minute_off: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          enable_timelock: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_timelock: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      battery: {
        id: 'battery',
        title: 'Battery',
        required: false,
        group: 'producerConsumer',
        quantity: 20,
        mappingsByColumns: {
          input: ['power', 'error', 'soc', 'target_power', 'state_enable', 'state_reset', 'capacity', 'energy_counter', 'reverse_energy_counter'],
          output: ['size_capacity', 'priority', 'switch_enable', 'switch_reset'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          target_power: '',
          state_enable: '',
          state_reset: '',
          error: '',
          title: '',
          soc: '',
          size_capacity: '',
          capacity: '',
          priority: '',
          switch_enable: '',
          switch_reset: '',
          energy_counter: '',
          reverse_energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          target_power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_reset: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          size_capacity: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          capacity: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          priority: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_reset: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      grid: {
        id: 'grid',
        title: 'Grid',
        required: true,
        group: 'producerConsumer',
        quantity: 1,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter', 'reverse_energy_counter', 'is_on_grid'],
          output: ['size'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          size: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          size: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          is_on_grid: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      house: {
        id: 'house',
        title: 'House Consumption',
        required: false,
        group: 'consumer',
        quantity: 40,
        mappingsByColumns: {
          input: ['power', 'error', 'state_enable', 'energy_counter'],
          output: ['switch_enable'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          state_enable: '',
          switch_enable: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      charge_station: {
        id: 'charge_station',
        title: 'EV Charging Station',
        required: false,
        group: 'consumer',
        quantity: 250,
        mappingsByColumns: {
          input: [
            'power', 'error', 'car_connected', 'charging_time', 'state_charging_station', 'state_emergency',
            'slider_target_power', 'state_manual', 'min_power', 'target_power', 'state_enable', 'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority',
            'enable_soc', 'disable_soc', 'max_power', 'switch_manual', 'slider_manual',
            'slider_min_power', 'switch_enable',
          ],
          titles: ['title'],
        },
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'car_connected': '',
          'charging_time': '',
          'state_charging_station': '',
          'state_emergency': '',
          'state_manual': '',
          'min_power': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'max_power': '',
          'slider_manual': '',
          'slider_target_power': '',
          'slider_min_power': '',
          'target_power': '',
          'state_enable': '',
          'switch_enable': '',
          'switch_manual': '',
          'energy_counter': '',
          'external_energy_measurement': false,
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'car_connected': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'charging_time': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_charging_station': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'min_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_min_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'energy_counter': {
            optional: 'power',
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'external_energy_measurement': {
            optional: true,
          },
        },
      },
      electric_heating: {
        id: 'electric_heating',
        title: 'Electric Heating Element',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'temperature', 'state_electric_heating', 'state_emergency', 'state_manual',
            'state_time', 'state_disable_protection', 'slider_target_power', 'target_power', 'state_enable',
            'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_disable_protection', 'switch_manual', 'slider_manual', 'switch_time', 'hour_on', 'minute_on',
            'hour_off', 'minute_off', 'target_temp_on', 'target_temp_off', 'target_temp_max', 'switch_enable', 'max_power',
          ],
          titles: ['title'],
        },
        isDynamicFields: true,
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'temperature': '',
          'state_electric_heating': '',
          'state_emergency': '',
          'state_manual': '',
          'state_time': '',
          'state_disable_protection': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_disable_protection': '',
          'switch_manual': '',
          'slider_target_power': '',
          'switch_time': '',
          'hour_on': '',
          'minute_on': '',
          'hour_off': '',
          'minute_off': '',
          'target_temp_on': '',
          'target_temp_off': '',
          'target_temp_max': '',
          'max_power': {},
          'target_power': '',
          'state_enable': '',
          'switch_enable': '',
          'slider_manual': '',
          'energy_counter': '',
          'external_energy_measurement': false,
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'external_energy_measurement': {
            optional: true,
          },
          'temperature': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_electric_heating': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_disable_protection': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_disable_protection': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_max': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            vuetifyComponent: 'DynamicField',
            isDynamic: true,
            optional: false,
            maxRange: 10,
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: 'power',
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      heating_pump: {
        id: 'heating_pump',
        title: 'Heating Pump',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'state_reset', 'state_heating_pump', 'state_emergency', 'state_manual', 'manual_power',
            'state_time', 'state_time_power', 'flow_temperature', 'return_temperature', 'inlet_temperature',
            'outlet_temperature', 'boiler_temperature', 'boiler_water_temperature', 'target_power', 'state_enable',
            'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_reset', 'switch_manual', 'slider_manual', 'switch_time', 'slider_power', 'hour_on', 'minute_on',
            'hour_off', 'minute_off', 'switch_enable', 'max_power',
          ],
          titles: ['title'],
        },
        isDynamicFields: true,
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'state_reset': '',
          'state_heating_pump': '',
          'state_emergency': '',
          'state_manual': '',
          'manual_power': '',
          'state_time': '',
          'state_time_power': '',
          'flow_temperature': '',
          'return_temperature': '',
          'inlet_temperature': '',
          'outlet_temperature': '',
          'boiler_temperature': '',
          'boiler_water_temperature': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_reset': '',
          'switch_manual': '',
          'slider_power': '',
          'switch_time': '',
          'hour_on': '',
          'minute_on': '',
          'hour_off': '',
          'minute_off': '',
          'max_power': {},
          'switch_enable': '',
          'slider_manual': '',
          'target_power': '',
          'state_enable': '',
          'energy_counter': '',
          'external_energy_measurement': false,
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'state_reset': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_heating_pump': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'manual_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'flow_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'return_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'inlet_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'outlet_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'boiler_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'boiler_water_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_reset': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            vuetifyComponent: 'DynamicField',
            isDynamic: true,
            optional: false,
            maxRange: 2,
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: 'power',
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'external_energy_measurement': {
            optional: true,
          },
        },
      },
      big_consumer: {
        id: 'big_consumer',
        title: 'Other Big Consumer',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'state_consumer', 'state_emergency', 'state_manual', 'state_enable', 'target_power',
            'slider_target_power', 'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_manual', 'switch_enable', 'slider_manual', 'max_power',
          ],
          titles: ['title'],
        },
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'state_consumer': '',
          'state_emergency': '',
          'state_manual': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_manual': '',
          'state_enable': '',
          'switch_enable': '',
          'target_power': '',
          'slider_manual': '',
          'slider_target_power': '',
          'max_power': '',
          'energy_counter': '',
          'external_energy_measurement': false,
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'state_consumer': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: 'power',
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'external_energy_measurement': {
            optional: true,
          },
        },
      },
    },
    settingsMappings: ['size_main_fuse', 'max_depth', 'reserve_battery', 'min_charge_battery', 'reserve_charge', 'update_time'],
    manageSchema: 'EMSSchema',
    isSettingsView: true,
  },
  EnergyIO: {
    mappingsByColumns: {
      inputMappings: ['startDate'],
      outputMappings: [
        'heartbeat',
      ],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
    },
    groups: {
      inputs: {
        id: 'inputs',
        title: 'Inputs',
        systems: ['inputs'],
        isDynamicFields: true,
      },
      outputs: {
        id: 'outputs',
        title: 'Outputs',
        systems: ['outputs'],
        isDynamicFields: true,
      },
      hybrids: {
        id: 'hybrids',
        title: 'Hybrids',
        systems: ['hybrids'],
        isDynamicFields: true,
      },
    },
    systems: {
      inputs: {
        id: 'inputs',
        title: 'Input Systems',
        required: false,
        group: 'inputs',
        quantity: 5,
        isDynamicFields: true,
        mappingsByColumns: {
          input: ['power', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          title: '',
        },
        mappings: {
          power: {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: false,
            isSystem: false,
            isDynamic: true,
            maxRange: 10,
            minMaxBorders: { min: 0, max: 500 },
            dependency: 'energy_counter',
          },
          energy_counter: {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: false,
            isSystem: false,
            isDynamic: true,
            maxRange: 10,
            minMaxBorders: { min: 0, max: 500 },
            dependency: 'power',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
        },
      },
      outputs: {
        id: 'outputs',
        title: 'Output Systems',
        required: false,
        group: 'outputs',
        quantity: 5,
        isDynamicFields: true,
        mappingsByColumns: {
          input: ['power', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          title: '',
        },
        mappings: {
          power: {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: false,
            isSystem: false,
            isDynamic: true,
            maxRange: 10,
            minMaxBorders: { min: 0, max: 500 },
            dependency: 'energy_counter',
          },
          energy_counter: {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: false,
            isSystem: false,
            isDynamic: true,
            maxRange: 10,
            minMaxBorders: { min: 0, max: 500 },
            dependency: 'power',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
        },
      },
      hybrids: {
        id: 'hybrids',
        title: 'Hybrid Systems',
        required: false,
        group: 'hybrids',
        quantity: 4,
        isDynamicFields: false,
        mappingsByColumns: {
          input: ['power', 'energy_counter', 'reverse_energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          title: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
        },
      },
    },
    manageSchema: 'EMSSchema',
  },
  EnergyViewV2: {
    mappingsByColumns: {
      inputMappings: ['force_charge', 'force_charge_2', 'calibration_charge', 'maintenance_charge', 'charge_maintenance'],
      outputMappings: [],
    },
    controllerMappings: {
      force_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      force_charge_2: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      calibration_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      maintenance_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      charge_maintenance: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
    },
    groups: {
      producer: {
        id: 'producer',
        title: 'Producer',
        systems: ['pv', 'chp', 'generator'],
      },
      producerConsumer: {
        id: 'producerConsumer',
        title: 'Producer Consumer',
        systems: ['grid', 'battery'],
      },
      consumer: {
        id: 'consumer',
        title: 'Consumer',
        systems: ['house', 'charge_station', 'electric_heating', 'heating_pump', 'big_consumer'],
      },
    },
    systems: {
      pv: {
        id: 'pv',
        title: 'PV System',
        required: true,
        group: 'producer',
        quantity: 40,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      chp: {
        id: 'chp',
        title: 'CHP System',
        required: false,
        group: 'producer',
        quantity: 3,
        mappingsByColumns: {
          input: [
            'power', 'error', 'energy_counter', 'energy_counter_thermal', 'thermal_power_output',
            'flow_temperature', 'return_temperature', 'buffer_temperature_top',
            'buffer_temperature_bottom',
          ],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          thermal_power_output: '',
          flow_temperature: '',
          return_temperature: '',
          buffer_temperature_top: '',
          buffer_temperature_bottom: '',
          energy_counter_thermal: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          thermal_power_output: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          flow_temperature: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          return_temperature: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          buffer_temperature_top: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          buffer_temperature_bottom: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter_thermal: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      generator: {
        id: 'generator',
        title: 'Generator',
        required: false,
        group: 'producer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      battery: {
        id: 'battery',
        title: 'Battery',
        required: false,
        group: 'producerConsumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'soc', 'energy_counter', 'reverse_energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          soc: '',
          energy_counter: '',
          reverse_energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          external_energy_measurement: {
            optional: true,
          },
        },
      },
      grid: {
        id: 'grid',
        title: 'Grid',
        required: true,
        group: 'producerConsumer',
        quantity: 1,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter', 'reverse_energy_counter', 'is_on_grid'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          is_on_grid: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      house: {
        id: 'house',
        title: 'House Consumption',
        required: false,
        group: 'consumer',
        quantity: 40,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      charge_station: {
        id: 'charge_station',
        title: 'EV Charging Station',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          external_energy_measurement: {
            optional: true,
          },
        },
      },
      electric_heating: {
        id: 'electric_heating',
        title: 'Electric Heating Element',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          external_energy_measurement: {
            optional: true,
          },
        },
      },
      heating_pump: {
        id: 'heating_pump',
        title: 'Heating Pump',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          external_energy_measurement: {
            optional: true,
          },
        },
      },
      big_consumer: {
        id: 'big_consumer',
        title: 'Other Big Consumer',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          external_energy_measurement: false,
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          external_energy_measurement: {
            optional: true,
          },
        },
      },
    },
    manageSchema: 'EMSSchema',
    isSettingsView: false,
  },
  ElectricChargingStationV2: {
    mappings: {
      'OutputField_actualValue': {
        optional: false,
        minMaxBorders: { min: 0, max: 100 },
      },
      'Switch2V_onOff': {
        optional: false,
      },
      'Switch2V_state': {
        optional: false,
      },
      'ShowEventDot_errorWarningState': {
        optional: false,
      },
      'ShowEvent_errorWarningState': {
        optional: true,
      },
    },
    manageSchema: 'DefaultSchemaWithUserSelection',
    isSettingsView: true,
    devicesSchemas: {
      basicDevices: ['OutputField', 'ShowEventDot'],
      additionalBasicDevices: ['Switch'],
    },
  },
};

export default devicesTypes;
