
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import DefaultButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/DefaultButton.vue';
import RonovatecConfirmationDialogButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/RonovatecConfirmationDialogButton.vue';
import RonovatecPlayPauseButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/RonovatecPlayPauseButton.vue';
import StaticIconAndTextButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/StaticIconAndTextButton.vue';
import RonovatecUpDownButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/RonovatecUpDownButton.vue';
import RonovatecOverEachOtherButton
  from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/RonovatecOverEachOtherButton.vue';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent PushButton Basic Control
 */
@Component({
  components: {
    FeedbackProvider,
    DefaultButton,
    RonovatecConfirmationDialogButton,
    RonovatecPlayPauseButton,
    StaticIconAndTextButton,
    RonovatecUpDownButton,
    RonovatecOverEachOtherButton,
  },
})
export default class PushButtonBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: 'push_button' }) icon!: string;
  // pushButtonBaseType can be: DefaultButton, RonovatecConfirmationDialogButton, RonovatecPlayPauseButton,
  // StaticIconAndTextButton, RonovatecOverEachOtherButton, RonovatecUpDownButton
  @Prop({ default: 'DefaultButton' }) pushButtonBaseType!: string;
  @Prop({ default: null }) iconSize!: null | number | string;
  @Prop({ default: 40 }) buttonSize!: string | number;
  @Prop({ default: 150 }) buttonWidth!: number;
  @Prop({ default: 36 }) buttonHeight!: number;
  @Prop({ default: 'theme' }) iconColor !: string;
  @Prop({ default: 'white' }) buttonColor!: string;
  @Prop({ default: 0 }) singleSendValue !: number;
  @Prop({ default: -1 }) customButtonDisabledValue!: number;
  @Prop({ default: false }) isSendSingleValue !: boolean;

  // not used
  @Prop({ default: -1 }) upDownOutputVal !: number;

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.onOff === '' || this.mappingsClean.state === '';
  }
  get state() {
    return !!this.measurements.get(this.mappingsClean.state);
  }
  get isStateEqualToCustomButtonDisabledValue() {
    return this.measurements.get(this.mappingsClean.state) === this.customButtonDisabledValue;
  }

  down() {
    if (this.isSendSingleValue) this.send([{ v: this.singleSendValue, n: this.mappingsClean.onOff, u: '' }]);
    else this.send([{ v: 1, n: this.mappingsClean.onOff, u: '' }]);
  }
  up() {
    if (this.isSendSingleValue) return;

    this.send([{ v: 0, n: this.mappingsClean.onOff, u: '' }]);
  }
  playPauseUp() {
    const isZero: boolean = this.measurements.get(this.mappingsClean.state) === 0;
    if (isZero) this.send([{ v: 0, n: this.mappingsClean.onOff, u: '' }]);
    else this.send([{ v: 1, n: this.mappingsClean.onOff, u: '' }]);
  }
  sendConfirmed() {
    this.send([{ v: 0, n: this.mappingsClean.onOff, u: '' }]);
  }
  sendSingleValue() {
    this.send([{ v: this.singleSendValue, n: this.mappingsClean.onOff, u: '' }]);
  }

  trigger(fn: any) {
    this.onSendStart();
    fn();
    this.onSendSuccess();
    this.$emit('sent', this.sendingState);
  }
}
