
import { Component } from 'vue-property-decorator';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { minMaxValidation } from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { setVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';
import {
  IChargeStationSystem, IElectricHeatingElementSystem,
  IHeatingPumpSystem, IIncludedSystemsHeatingPumpDefinition,
  IMQTTVariable, NavigationDirection,
} from '@/types/wizards/installationWizard.types';
import { Action, Getter, Mutation } from 'vuex-class';
import { IProject } from '@/types/project.types';
import { TranslateResult } from 'vue-i18n';
import { newChargeStationLimitDate, plcVersionDate } from '@/utils/versionManagementUtils';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { IDevice } from '@/types/devices.types';
import _ from 'lodash';

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
  },
})
export default class HeatingPumpSettings extends WizardComponent {
  @Getter('projects/project') project!: IProject;
  @Mutation('installationWizard/updateEMSDeviceData') updateEMSDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('installationWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Mutation('installationWizard/resetSystemsPropInIncludedSystemTypes')
  resetSystemsPropInIncludedSystemTypes!: (
    systemType: 'battery' | 'electric_heating' | 'charge_station' | 'heating_pump',
  ) => void;
  @Mutation('installationWizard/addSystemPropertyValues') addSystemPropertyValues!: (data: {
    systemType: string;
    data: any;
  }) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => Promise<IDevice>;

  @Mutation('installationWizard/handleIncludedSystemsTypesSystemSystemsProps')
  handleIncludedSystemsTypesSystemSystemsProps!: ({
    systemName,
    systemIndex,
    prop,
    value,
  }: {
    systemName: string;
    systemIndex: number;
    prop: string;
    value: any;
  }) => void;

  doesHover = false;
  valid = false;

  maxPowerByHeatingPumpType: Record<string, number> = {
    'Soltop M-Tech': 20,
    'Lambda Eureka': 20,
  };

  formVariables: Map<string, IMQTTVariable> = new Map([
    [
      'priority',
      {
        variable: 'prgHP.fbHP.stSetupHP.byPriority',
        value: 4,
      },
    ],
  ]);
  backgroundVariables: Map<string, IMQTTVariable> = new Map([
    [
      'enableSOC',
      {
        variable: 'prgHP.fbHP.stSetupHP.byEnableSOC',
        value: 1,
      },
    ],
    [
      'minutesOn',
      {
        variable: 'prgHP.fbHP.stSetupHP.byMinutesOn',
        value: 0,
      },
    ],
    [
      'hourOff',
      {
        variable: 'prgHP.fbHP.stSetupHP.byHourOff',
        value: 0,
      },
    ],
    [
      'minutesOff',
      {
        variable: 'prgHP.fbHP.stSetupHP.byMinutesOff',
        value: 0,
      },
    ],
    [
      'disableSOC',
      {
        variable: 'prgHP.fbHP.stSetupHP.byDisableSOC',
        value: 0,
      },
    ],
    [
      'hourOn',
      {
        variable: 'prgHP.fbHP.stSetupHP.byHourOn',
        value: 0,
      },
    ],
    [
      'enable',
      {
        variable: 'prgHP.fbHP.bEnable',
        value: 1,
        feedbackVariable: 'prgHP.fbHP.stDataHP.bEnabled',
        isBoolean: true,
        customTimeout: 30,
      },
    ],
    [
      'timeEnabledTargetPower',
      {
        variable: 'prgHP.fbHP.stSetupHP.byTimeEnabledTargetPower',
        value: 0,
        feedbackVariable: 'prgHP.fbHP.stDataHP.bySTimeEnabledTargetPower',
      },
    ],
    [
      'timeEnabled',
      {
        variable: 'prgHP.fbHP.stSetupHP.bTimeEnabled',
        value: 0,
        feedbackVariable: 'prgHP.fbHP.stDataHP.bSTimeEnabled',
        isBoolean: true,
      },
    ],
    [
      'reset',
      {
        variable: 'prgHP.fbHP.stSetupHP.bReset',
        value: 0,
        feedbackVariable: 'prgHP.fbHP.stDataHP.bSReset',
        isBoolean: true,
      },
    ],
  ]);

  handleChange(elementIndex: number, event: any, prop: string) {
    this.handleIncludedSystemsTypesSystemSystemsProps({
      systemName: 'heating_pump',
      systemIndex: elementIndex,
      prop,
      value: event,
    });
  }

  async prepareVariablesToSend() {
    if (this.includedSystems.heating_pump_consumer.definition.length) this.variablesToSend = new Map([...this.formVariables, ...this.backgroundVariables]);

    this.prepareHeatingPumpVariables();

    // Update the names given initially in the ems mappings
    await this.updateEMSNaming();
  }

  async updateEMSNaming() {
    const emsMappings = _.cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = _.cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    // Update ems mappings
    Object.values(emsMappings.heating_pump.components)
      .filter((component: any, index) => {
        const notConsumer = component.power.includes('prgHP.lrPowerHP_');
        const notDummy = !(component.external_energy_measurement && component.error === undefined);
        if (!notConsumer && notDummy) {
          component.title = this.includedSystems.heating_pump_consumer.definition[0]?.title || component.title;
        }
        return notConsumer && notDummy;
      })
      .forEach((component: any, index: number) => {
        component.title = this.includedSystems.heating_pump.definition[index]?.title || component.title;
      });

    // Update energy view mappings
    Object.values(energyViewMappings.heating_pump.components)
      .filter((component: any) => component.power.includes('prgHP.lrPowerHP_'))
      .forEach((component: any, index: number) => {
        component.title = this.includedSystems.heating_pump.definition[index].title;
      });

    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
    if (this.emsDevice.id) await this.updateDevice({ device: this.emsDevice, skipReport: false });
    if (this.energyViewDevice.id) await this.updateDevice({ device: this.energyViewDevice, skipReport: false });
  }

  prepareHeatingPumpVariables() {
    this.includedSystems.heating_pump.systems.forEach((heating_pump: IHeatingPumpSystem, index: number) => {
      Object.entries(heating_pump).forEach(([key, value]) => {
        if (key === 'title') return;
        this.variablesToSend.set(
          heating_pump[key as keyof IHeatingPumpSystem].variable,
          heating_pump[key as keyof IHeatingPumpSystem] as IMQTTVariable,
        );
      });
    });
  }

  getVariableValuesFromMeasurements() {
    this.formVariables = setVariableValuesWithMeasurements(this.formVariables, this.measurements);

    // loop over existing heating elements
    this.includedSystems.heating_pump.systems.forEach((system: IHeatingPumpSystem, index) => {
      const maxPower = this.measurements[system.power.variable];
      const priority = this.measurements[system.priority.variable];

      this.handleIncludedSystemsTypesSystemSystemsProps({
        systemName: 'heating_pump',
        systemIndex: index,
        prop: 'power',
        value: maxPower,
      });
      this.handleIncludedSystemsTypesSystemSystemsProps({
        systemName: 'heating_pump',
        systemIndex: index,
        prop: 'priority',
        value: priority,
      });
    });
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  get includedSystemsHeatingPumpSystems(): IHeatingPumpSystem[] {
    return this.includedSystems.heating_pump.systems;
  }

  validate() {
    this.valid = (this.$refs.form as any).validate();
  }

  mounted() {
    this.validate();
  }

  getInstallationWizardVariable(variable: string, value: number, systemIndex: number) {
    return installationWizardVariables(variable, 'componentsPage', value, systemIndex);
  }

  async created() {
    this.resetSystemsPropInIncludedSystemTypes('heating_pump');
    if (this.isNewChargeStationPlcVersion) {
      Object.values(this.emsDevice.data.meta.controllerMappings.heating_pump.components).filter(
        (component: any) => {
          return component.power.includes('prgHP.lrPowerHP_') && !(component.external_energy_measurement && component.error === undefined)
        },
      ).forEach((component: any, index) => {
        const ind = index + 1;
        const defaultValues = {
          power: this.getInstallationWizardVariable('heatingPumpMaxPower1', 0, ind),
          priority: this.getInstallationWizardVariable('heatingPumpPriority', 2, ind),
          max_power_2: this.getInstallationWizardVariable('heatingPumpMaxPower2', 0, ind),
          enableSOC: this.getInstallationWizardVariable('heatingPumpEnableSOC', 1, ind),
          minutesOn: this.getInstallationWizardVariable('heatingPumpMinuteOn', 0, ind),
          hourOff: this.getInstallationWizardVariable('heatingPumpHourOff', 0, ind),
          minutesOff: this.getInstallationWizardVariable('heatingPumpMinuteOff', 0, ind),
          disableSOC: this.getInstallationWizardVariable('heatingPumpDisableSOC', 0, ind),
          hourOn: this.getInstallationWizardVariable('heatingPumpHourOn', 0, ind),
          enable: this.getInstallationWizardVariable('heatingPumpEnable', 1, ind),
          timeEnabledTargetPower: this.getInstallationWizardVariable('heatingPumpTimeEnabledPower', 0, ind),
          timeEnabled: this.getInstallationWizardVariable('heatingPumpTimeEnabled', 0, ind),
          reset: this.getInstallationWizardVariable('heatingPumpReset', 0, ind),
          title: component.title,
        };
        if (!this.includedSystems.heating_pump.systems[index]) {
          this.addSystemPropertyValues({ systemType: 'heating_pump', data: defaultValues });
        }
      });
    }

    if (this.wasInstallationWizardCompleted || this.navigationDirection === 0) {
      this.getVariableValuesFromMeasurements();
    }
  }
}
