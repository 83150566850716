
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { minMaxValidation } from '@/utils/utilsFunctions';
import { setVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import {
  IMQTTVariable,
  WaitForMQTTFeedbackAction,
} from '@/types/wizards/installationWizard.types';
import { IWizardLoadingState } from '@/types/wizards/wizard.general.types';
import WizardComponent from '../../../baseComponents/WizardComponent';

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class GridDrmSettings extends WizardComponent {
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Mutation('installationWizard/incrementLoadingSentCount') incrementLoadingSentCount!: () => void;
  @Mutation('installationWizard/setLoadingState') setLoadingState!: (payload: IWizardLoadingState) => void;
  @Action('installationWizard/waitForMQTTFeedback') waitForMQTTFeedback!: WaitForMQTTFeedbackAction;

  doesHover = false;

  drmVariables: Map<string, IMQTTVariable> = new Map([
    [
      'drm1',
      {
        variable: 'prgEnergy.fbI.byDRMStep1',
        value: 75,
      },
    ],
    [
      'drm2',
      {
        variable: 'prgEnergy.fbI.byDRMStep2',
        value: 50,
      },
    ],
    [
      'drm3',
      {
        variable: 'prgEnergy.fbI.byDRMStep3',
        value: 25,
      },
    ],
    [
      'drm4',
      {
        variable: 'prgEnergy.fbI.byDRMStep4',
        value: 0,
      },
    ],
  ]);
  otherVariables: Map<string, IMQTTVariable> = new Map([
    [
      'maxSellPowerToGrid',
      {
        variable: 'prgEnergy.fbI.wMaxSellPowerToGrid',
        value: 5000,
      },
    ],
  ]);
  valid = false;

  prepareVariablesToSend() {
    this.variablesToSend = new Map([...this.drmVariables, ...this.otherVariables]);
  }

  getVariableValuesFromMeasurements() {
    // sets all drm varibles
    this.drmVariables = setVariableValuesWithMeasurements(this.drmVariables, this.measurements);

    // sets maxSellPowerToGrid variable
    this.setMaxSellPowerToGrid();
  }

  setMaxSellPowerToGrid() {
    // sets maxSellPowerToGrid variable
    const defaultMaxPowerValue = this.otherVariables.get('maxSellPowerToGrid')?.value;
    const variableName: any = this.otherVariables.get('maxSellPowerToGrid')?.variable;
    const currentMaxPowerValue = this.measurements[variableName];
    if (defaultMaxPowerValue !== currentMaxPowerValue) {
      this.otherVariables.set('maxSellPowerToGrid', {
        variable: variableName,
        value: currentMaxPowerValue,
      });
    }
  }

  async created() {
    if (this.isSolarmax) {
      this.otherVariables.set('maxSellPowerToGrid', {
        variable: 'prgEnergy.fb.I.rMaxSellPowerToGrid',
        value: 5000,
      });
    }
    if (this.wasInstallationWizardCompleted) {
      this.getVariableValuesFromMeasurements();
    } else {
      this.setMaxSellPowerToGrid();
    }
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }
}
