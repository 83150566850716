
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent DefaultButton type for PushButton base control
 */
@Component
export default class DefaultButton extends BasicControl {
  @Prop({ default: null }) defaultButtonCurrentState!: number | null;
  @Prop({ default: 'push_button' }) icon!: string;
  @Prop({ default: 40 }) buttonSize!: string | number;
  @Prop() iconSize?: number | string;
}
