
import { Vue, Component } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import TenantWizard from '@/ui/components/wizards/tenantWizard/components/index.vue';
import TenantStatistic from '@/ui/components/wizards/tenantWizard/components/TenantStatistic.vue';
import BasicTenantModelEditor from '@/ui/components/wizards/tenantWizard/components/BasicTenantModelEditor.vue';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    TenantWizard,
    BasicTenantModelEditor,
    TenantStatistic,
    LeaveConfirmationModal,
  },
})
export default class InvoiceHistory extends Vue {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Action('tenantWizard/downLoadTenant') downLoadTenant!: (data: {start: number; end: number; project_id: string}) => any;

  invoiceHistoryItems: {startDate: number; endDate: number}[] = [];
  showLoader = false;
  showLoadingIndex = 0;
  currentPage = 1;
  numberOfPages = 0;

  get tableHeaders() {
    return [
      {
        text: this.$t('tenantWizard.invoiceHistory.tableHeaders.startInvoice'),
        sortable: false,
        width: '30%',
      },
      {
        text: this.$t('tenantWizard.invoiceHistory.tableHeaders.endInvoice'),
        sortable: false,
        width: '30%',
      },
      {
        text: this.$t('tenantWizard.invoiceHistory.tableHeaders.actions'),
        sortable: false,
        width: '10%',
      },
    ];
  }

  async created() {
    this.fillInvoiceHistoryItems();
  }

  async fillInvoiceHistoryItems() {
    const startDate = new Date(this.tenantState.tenant.billingSettings.start * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    const startMonth = startDate.getMonth() + 1; // Months are 0-based, so add 1 to get the actual month
    const startYear = startDate.getFullYear();

    const endDate = new Date();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();

    // get difference between start and end date in months
    const monthDiff = (endYear - startYear) * 12 + (endMonth - startMonth);
    let monthMuliplicator = 1;
    let monthsToAdd = -1; // number of months to add for the end date

    // get multiplicator for month according to billing interval
    switch (this.tenantState.tenant.billingSettings.interval) {
      case 'Month': {
        monthMuliplicator = 1;
        monthsToAdd = -1;
        break;
      }
      case 'QuarterYear': {
        monthMuliplicator = 3;
        monthsToAdd = 1;
        break;
      }
      case 'HalfYear': {
        monthMuliplicator = 6;
        monthsToAdd = 4;
        break;
      }
      case 'Year': {
        monthMuliplicator = 12;
        monthsToAdd = 10;
        break;
      }
    }

    // fill invoice history items
    for (let currentIndex = 1; currentIndex <= monthDiff; currentIndex += monthMuliplicator) {
      const currentMonth = startMonth + currentIndex;
      const currentMonthModulo = currentMonth % 12;

      const startDateInvoiceItem = new Date(startYear + Math.floor(currentMonth / 12), currentMonthModulo - 2, 1);
      const endDateInvoiceItem = new Date(startYear + Math.floor(currentMonth / 12), currentMonthModulo + monthsToAdd, 1);

      const invoiceHistoryItem = {
        startDate: startDateInvoiceItem.getTime() / 1000,
        endDate: endDateInvoiceItem.getTime() / 1000,
      };

      // add invoice history item if end date is in the past
      if (invoiceHistoryItem.endDate < new Date().getTime() / 1000) {
        this.invoiceHistoryItems.push(invoiceHistoryItem);
      }
    }

    this.numberOfPages = Math.ceil(this.invoiceHistoryItems.length / 20);
  }

  /**
   * Converts timestamp to date format (dd Month yyyy)
   * @param timestamp
   */
  convertTimestampToDate(timestamp: number) {
    const date = new Date(timestamp * 1000);

    // Define arrays for month names and day numbers
    const monthNames = [
      this.$t('uiComponents.months.january').toString(),
      this.$t('uiComponents.months.february').toString(),
      this.$t('uiComponents.months.march').toString(),
      this.$t('uiComponents.months.april').toString(),
      this.$t('uiComponents.months.may').toString(),
      this.$t('uiComponents.months.june').toString(),
      this.$t('uiComponents.months.july').toString(),
      this.$t('uiComponents.months.august').toString(),
      this.$t('uiComponents.months.september').toString(),
      this.$t('uiComponents.months.october').toString(),
      this.$t('uiComponents.months.november').toString(),
      this.$t('uiComponents.months.december').toString(),
    ];
    const dayNumbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];

    // Get day, month, and year components
    const day = dayNumbers[date.getDate() - 1] || date.getDate().toString();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date string
    return `${day} ${month} ${year}`;
  }

  async handleDownload(item: {startDate: number; endDate: number}, index: number) {
    this.showLoader = true;
    this.showLoadingIndex = index;
    try {
      const file = await this.downLoadTenant({ start: item.startDate, end: item.endDate, project_id: this.$route.params.id });
      // is needed so no empty file is downloaded
      if (!file) {
        this.showLoader = false;
        this.showLoadingIndex = 0;
        return;
      }
      // creates link to download report
      const downloadAnchorNode = document.createElement('a');

      const zipBlob = new Blob([file], { type: 'application/zip' });
      // add attributes for download link
      downloadAnchorNode.setAttribute('href', URL.createObjectURL(zipBlob));
      downloadAnchorNode.setAttribute('download', 'report.zip');
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
      this.showLoader = false;
    } catch (error) {
      console.log(error);
      this.showLoader = false;
      this.showLoadingIndex = 0;
    }
  }
}
