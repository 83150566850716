
import { Component, Prop, Vue } from 'vue-property-decorator';
import { icon, latLng } from 'leaflet';
import {
  LIcon, LMap, LMarker, LPopup, LTileLayer, LTooltip,
} from 'vue2-leaflet';
import { Action, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IAppState } from '@/store/modules/app/types';
import { IProject, IProjectLocation } from '@/types/project.types';
import { CenterCoords, GroupedMarkedArray } from '@/types/common.types';

/**
 * Component that represent map on Home page
 */
@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    InfoTooltip,
  },
})
export default class MapBanner extends Vue {
  @Prop({ default: [] }) projects!: IProject[];
  @Prop({ default: true }) isInHome!: boolean;

  @State('projects') projectsState!: any;
  @Action('members/fetchMember') fetchMember!: (payload: { memberId: string; projectId: string }) => Promise<void>;
  @State('app') appState!: IAppState;

  zoom = 7
  center = latLng(47.128733, 10.468258)
  url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  currentZoom = 11.5
  // maxZoom = 9
  minZoom = 2.3
  currentCenter: CenterCoords = latLng(47.41322, -1.219482)
  mapOptions = {
    zoomSnap: 0.5,
  }
  showMap = true
  icons = {
    error: icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    ok: icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    suspend: icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
  }

  groupedMarkerArr: GroupedMarkedArray[] = [];
  intervalVar: any;

  get filterProjectByStatus() {
    return this.projectsState.projectFilters.projectStatus;
  }

  get filteredGroupedMarkerArr() {
    if (!this.isInHome) {
      // filter by project status is only needed in home page
      return this.groupedMarkerArr;
    }
    if (this.filterProjectByStatus === '' || this.filterProjectByStatus === undefined) {
      // if no filter is selected return all groups and show group status like it is when initialy grouped
      return this.groupedMarkerArr.map((group: GroupedMarkedArray) => {
        const groupCopy = group;
        let status: 'error' | 'ok' | 'suspend' = 'ok';
        if (group.projects.some((project: IProject) => this.projectStatusClass(project, true) === 'error')) {
          status = 'error';
        } else if (group.projects.some((project: IProject) => this.projectStatusClass(project, true) === 'suspend')) {
          status = 'suspend';
        }
        groupCopy.status = status;
        return groupCopy;
      });
    } else {
      const updateGroupStatus = this.groupedMarkerArr.map((group: GroupedMarkedArray) => {
        const groupCopy = group;
        if (group.projects.some((project: IProject) => this.projectStatusClass(project, true) === this.filterProjectByStatus)) {
          groupCopy.status = this.filterProjectByStatus;
        }
        return group;
      });
      const filteredGroupsByOverallStatus = this.groupedMarkerArr.filter((group: GroupedMarkedArray) => group.status === this.filterProjectByStatus);

      // filter out projects in group by project status
      return filteredGroupsByOverallStatus.map((group: GroupedMarkedArray) => {
        const filteredProjectList = group.projects.filter((project: IProject) => this.projectStatusClass(project, true) === this.filterProjectByStatus);
        return { ...group, projects: filteredProjectList };
      });
    }
  }
  get filteredProjectsByCoords() {
    return this.projects.filter((project: IProject) => project.meta.location);
  }
  get projectsCoords() {
    return this.filteredProjectsByCoords.length
      ? this.filteredProjectsByCoords.map((project: IProject) => project.meta.location)
      : [];
  }
  get uniqueCoords() {
    return this.projectsCoords.filter((item: IProjectLocation | undefined, index: number) => {
      return this.projectsCoords.findIndex((obj: IProjectLocation | undefined) => obj?.lat === item?.lat && obj?.lon === item?.lon) === index;
    });
  }

  projectStatusClass(project: IProject, noDot = false) {
    if (!this.isInHome || !project.stats) return 'ok-dot';

    if (project.stats.warnings === 0 && project.stats.errors === 0) return !noDot ? 'ok-dot' : 'ok';
    if (project.stats.warnings !== 0 && project.stats.errors === 0) return !noDot ? 'warning-dot' : 'suspend';
    else return !noDot ? 'error-dot' : 'error';
  }

  /**
   * Group projects which has equal coordinates
   */
  async initProjectsGroups() {
    this.groupedMarkerArr = [];
    this.uniqueCoords.forEach((item: IProjectLocation | undefined) => {
      const projects = this.filteredProjectsByCoords
        .filter((project: IProject) => {
          const { location } = project.meta;
          return item?.lat === location?.lat && item?.lon === location?.lon;
        });

      if (this.isInHome) {
        let errors = 0;
        let warnings = 0;
        projects.map((p: IProject) => p.stats).forEach((el: any) => {
          errors += el.errors;
          warnings += el.warnings;
        });
        const getStatus = () => {
          if (errors) return 'error';
          if (!errors && warnings) return 'suspend';
          return 'ok';
        };
        this.groupedMarkerArr.push({
          coords: latLng(Number(item?.lat), Number(item?.lon)),
          status: getStatus(),
          visible: true,
          projects,
        });
      } else {
        this.groupedMarkerArr.push({
          coords: latLng(Number(item?.lat), Number(item?.lon)),
          status: 'ok',
          visible: true,
          projects,
        });
      }
    });
  }

  get user() {
    return this.appState.user;
  }

  async handleTooltip(project: IProject, group: GroupedMarkedArray) {
    if (!this.isInHome) {
      await this.$router.push({ path: `/partner-workspace/${project.id}` });
      return;
    }
    // Member needed when pushing into room with limited access like eventlist
    if (project.id) {
      await this.fetchMember({ memberId: this.user.id, projectId: project.id });
    }
    if (group.status !== 'ok') {
      await this.$router.push({ path: `/projects/${project.id}/eventlist` });
    } else {
      await this.$router.push({ path: `/projects/${project.id}/favorites` });
    }
  }
  zoomUpdate(zoom: number) {
    this.currentZoom = zoom;
  }
  centerUpdate(center: CenterCoords) {
    this.currentCenter = center;
  }

  async created() {
    await this.initProjectsGroups();

    // updates project groups every 60 seconds
    this.intervalVar = setInterval(async () => {
      await this.initProjectsGroups();
    }, 60000);
  }

  destroyed() {
    clearInterval(this.intervalVar);
  }
}
