import { render, staticRenderFns } from "./BatteryStatusBar.vue?vue&type=template&id=871d0070&scoped=true&"
import script from "./BatteryStatusBar.vue?vue&type=script&lang=ts&"
export * from "./BatteryStatusBar.vue?vue&type=script&lang=ts&"
import style0 from "./BatteryStatusBar.vue?vue&type=style&index=0&id=871d0070&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "871d0070",
  null
  
)

export default component.exports