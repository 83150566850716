
import { Component, Prop, Watch } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import ChargingStationButton from '@/ui/components/devices/devices/base/PushButtonBase/ButtonTypes/ChargingStationButton.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import { isEqual } from 'lodash';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, State } from 'vuex-class';
import { IDevice } from '@/types/devices.types';
import { IMember } from '@/types/members.types';
import { IUser } from '@/types/app.types';
import { IAppState } from '@/store/modules/app/types';
import { IMeasurements } from '@/types/measurements.types';

/**
 * Component that represent ElectricChargingStationV2 device
 */
@Component({
  components: {
    OutputFieldBase,
    ShowEventDotBase,
    DeviceLayout,
    LabelUnitWrapper,
    ChargingStationButton,
    Switch2VBase,
  },
})
export default class ElectricChargingStationV2 extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('app') appState!: IAppState;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('members/members') members!: IMember[];
  @Getter('app/getUser') currentUser!: IUser;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;

  selectedUsers: { text: string; value: string }[] = [];

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }

  // Watch for changes in selected users to update the selectedUsers array
  @Watch('deviceData.data.meta.selectedUsers', { deep: true })
  handleChange() {
    this.selectedUsers = this.deviceData.data.meta.selectedUsers.map((user: string) => {
      const member: any = this.members.find((el: IMember) => el.id === user);
      return { text: `${member.first_name} ${member.last_name}`, value: user };
    });
  }

  get super_admin() {
    return this.appState.user.super_admin;
  }

  get chargeId() {
    return this.deviceData.data.meta.chargeID;
  }

  /**
   * If ChargeID is 0, the charge station is not used
   * If ChargeID is anything else, the charge station is used
   */
  get buttonActive() {
    // if free charging is activated, the button is always disabled
    const switchStatus = this.measurements[this.deviceData.data.mappings.Switch2V_onOff];
    return this.measurements[this.chargeId] !== 0 || switchStatus === 0;
  }

  get switchOnOffStatus() {
    return this.measurements[this.deviceData.data.mappings.Switch2V_onOff];
  }

  /**
   * Manage the visibility of the push button
   */
  get showPushbutton() {
    if (this.super_admin) {
      // super admin can allways see button
      return true;
    }
    const userAccess = this.deviceData.data.meta.selectedUsers ?? [];
    if (userAccess.length === 0) {
      // if no user is selected, we never show the button
      return false;
    }
    return userAccess.includes(this.currentUser.id);
  }

  /**
   * List of members for user selection
   */
  get selectableMembers() {
    return this.members.map((member: IMember) => ({
      value: member.id,
      text: `${member.first_name} ${member.last_name}`,
    }));
  }

  async switchSettingsView(value: boolean) {
    const usersInDevice = this.deviceData.data.meta.selectedUsers ?? [];
    const currentSelectedUsers = this.selectedUsers.map((user) => user.value);
    // close of settings view
    if (!value) {
      // check if selected users are changed and save device data
      if (!isEqual(usersInDevice, currentSelectedUsers) && this.switchOnOffStatus === 1) {
        // update device
        const payload = { ...this.deviceData };
        payload.data.meta.selectedUsers = this.selectedUsers.map((user) => user.value);
        await this.updateDevice({ device: payload, skipReport: false });
      }
    }
  }

  handleDeleteUser(item: { text: string; value: string }) {
    // remove user counter variables if a user is removed
    this.selectedUsers.splice(this.selectedUsers.indexOf(item), 1);
  }

  created() {
    this.selectedUsers = this.deviceData.data.meta.selectedUsers.map((user: string) => {
      const member: any = this.members.find((el: IMember) => el.id === user);
      return { text: `${member.first_name} ${member.last_name}`, value: user };
    });
  }
}
