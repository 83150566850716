/* eslint-disable max-classes-per-file */
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { MQTTFeedbackError } from '@/store/modules/installationWizard/MQTTFeedbackError';
import { Validation } from '@/ui/mixins/validation';
import { IReportBox } from '@/types/app.types';
import { IIncludedSystemsTypes, IMQTTVariable } from '@/types/wizards/installationWizard.types';
import { newChargeStationLimitDate, plcVersionDate } from '@/utils/versionManagementUtils';

class ProcessFailedError implements Error {
  constructor(processStatusCheckFailed: string) {
    this.message = processStatusCheckFailed;
    this.name = 'ProcessFailedError';
  }

  message: string;
  name: string;
}

/**
 * Generic WizardComponent class, which provides common functionality used throughout
 * multiple wizard pages/components, such as mqtt sending, page navigation, etc.
 */
@Component
export default class WizardComponent extends Validation {
  @Getter('installationWizard/includedSystemsTypes') includedSystems!: IIncludedSystemsTypes;
  @Getter('measurements/measurements') measurements!: any;
  @Getter('installationWizard/wasInstallationWizardCompleted') wasInstallationWizardCompleted!: boolean;
  @Mutation('installationWizard/resetLoadingState') resetLoadingState!: () => void;
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;
  @Action('installationWizard/handleIncrement') handleIncrement!: () => void;
  @Action('installationWizard/handleDecrement') handleDecrement!: () => void;
  @Action('installationWizard/sendVariables') sendVariables!: (variablesToSend: IMQTTVariable[]) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;

  // we use Map here to assure that the order in which the data is added is preserved
  variablesToSend: Map<string, IMQTTVariable> = new Map();

  processFailedMessage = 'Process status check failed';

  /**
   * Fills the variablesToSend object with the necessary data in order to send them over MQTT.
   */
  prepareVariablesToSend(): void {}

  // check process state after everything is sent
  async checkProcessStatus() {
    return true;
  }

  async startSending() {
    await this.prepareVariablesToSend();
    return this.sendVariables(Array.from(this.variablesToSend.values()));
  }

  async handleNext() {
    try {
      await this.fetchMeasurements(this.$route.params.id);
      await this.startSending();
      if (plcVersionDate(this.$store.getters['projects/project']).getTime() > newChargeStationLimitDate.getTime()) {
        const processStatus = await this.checkProcessStatus();
        if (processStatus) this.handleIncrement();
        else {
          throw new ProcessFailedError(this.processFailedMessage);
        }
      } else {
        this.handleIncrement();
      }
    } catch (e) {
      if (e instanceof ProcessFailedError) {
        this.resetLoadingState();
        this.setReport({
          type: 'error',
          message: this.processFailedMessage,
          value: true,
          timeout: 20000,
        });
      }
      if (e instanceof MQTTFeedbackError) {
        this.resetLoadingState();
        this.setReport({
          type: 'error',
          message: this.$t('installationWizard.mqttFeedbackErrorMessage', { variable: e.variable }),
          value: true,
          timeout: 20000,
        });
      }
    }
  }
}
