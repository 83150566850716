var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FeedbackProvider',{attrs:{"sendingState":_vm.sendingState}},[_c(_vm.pushButtonBaseType,_vm._b({tag:"component",on:{"down":_vm.down,"up":function($event){return _vm.trigger(_vm.up)},"sendSingleValue":function($event){return _vm.trigger(_vm.sendSingleValue)},"sendConfirmed":function($event){return _vm.trigger(_vm.sendConfirmed)},"playPauseUp":function($event){return _vm.trigger(_vm.playPauseUp)}},scopedSlots:_vm._u([{key:"buttonText",fn:function(){return [_vm._t("buttonText")]},proxy:true}],null,true)},'component',{
      icon: _vm.icon,
      buttonSize: _vm.buttonSize,
      iconSize: _vm.iconSize,
      buttonWidth: _vm.buttonWidth,
      buttonHeight: _vm.buttonHeight,
      iconColor: _vm.iconColor,
      defaultButtonCurrentState: _vm.state,
      currentState: _vm.isStateEqualToCustomButtonDisabledValue,
      isNotMapped: _vm.isNotMapped,
    },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }