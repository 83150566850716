
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter } from 'vuex-class';
import EnergyComponent from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import SystemDialog from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import SystemDialogView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialogView.vue';
import {
  EnergyCircleType,
  isHybrid,
  energyCircleIconForType,
} from '@/types/energyVisualisation/EnergyCircleType';
import EnergyVisualisation from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import { roundNumber } from '@/utils/utilsFunctions';
import {
  CirclePosition,
  EnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  energyConsumerColors,
  energyHybridColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IMeasurements } from '@/types/measurements.types';
import {
  getDefaultArrowDirection,
  getDefaultLineColors,
  getDefaultSpinnerColor,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import { kiloWattHourUnit } from '@/utils/unit/unitConstants';
import { IDevice } from '@/types/devices.types';
import { EnergyDataByPeriodResponse, EnergyDataByPeriodResponseEnergies } from '@/types/energyVisualisation/energyVisualisation.types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';

@Component({
  components: {
    SystemDialogView,
    SystemDialog,
    EnergyVisualisation,
    InfoTooltip,
  },
  methods: { roundNumber },
})
export default class EnergyFlowViewSystemDialog extends mixins(EnergyComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop() openedSystem!: EnergyCircleType;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() energyDataByPeriodResponse!: EnergyDataByPeriodResponse | null;
  @Prop({ default: false }) displayEnergyFlowHouseInfo!: boolean;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  @Provide('onDialogLineAnimationEnd') onDialogLineAnimationEnd =
    this.onDialogLineAnimationEndCallback;
  dialogHybridHighlightBottomValue = false;
  dialogHybridArrowDirection = ArrowDirection.toCenter;
  dialogHybridLineColors = energyHybridColors;
  onDialogLineAnimationEndCallback() {
    this.dialogHybridHighlightBottomValue = !this.dialogHybridHighlightBottomValue;
    this.dialogHybridArrowDirection =
      this.dialogHybridArrowDirection === ArrowDirection.toCenter
        ? ArrowDirection.awayFromCenter
        : ArrowDirection.toCenter;
    this.dialogHybridLineColors =
      this.dialogHybridLineColors === energyHybridColors
        ? energyConsumerColors
        : energyHybridColors;
  }

  getSystemInstanceValue({
    systemType,
    systemInstanceName,
    key,
  }: {
    systemType: EnergyCircleType;
    systemInstanceName: string;
    key?: 'to_consumer' | 'from_production';
  }) {
    if (!this.energyDataByPeriodResponse) return undefined;

    const systemInstanceFullKey = key ? `${systemInstanceName}_${key}` : systemInstanceName;
    const systemInstances = this.energyDataByPeriodResponse.energies[
      systemType as keyof EnergyDataByPeriodResponseEnergies
    ] as Record<string, number>;
    return systemInstances[systemInstanceFullKey];
  }

  defineHouseValue(value: any) {
    const externalMeasurementsSystemsValueList = this.externalMeasurementsSystems.map((systemType: EnergyCircleType) => {
      return this.getSumOfExternalMeasurementsSystemInstancesValues(systemType, this.energyDataByPeriodResponse, this.deviceData) ?? 0;
    });
    const sumOfExternalMeasurementsSystemsValues = externalMeasurementsSystemsValueList.reduce((acc: number, val: number) => acc + val, 0);
    return typeof value === 'number' ? value - sumOfExternalMeasurementsSystemsValues : undefined;
  }

  get systemDialogCircles() {
    if (!this.openedSystem) return [];

    const systems = this.getSystemComponents(this.deviceData, this.openedSystem);
    return Object.entries(systems).map(([key, systemComponent], index: number) => {
      let bottomValue;
      let highlightBottomValue;
      let spinnerColor;

      if (isHybrid(this.openedSystem)) {
        bottomValue = this.dialogHybridHighlightBottomValue
          ? this.getSystemInstanceValue({
            systemType: this.openedSystem,
            systemInstanceName: key,
            key: 'from_production',
          })
          : this.getSystemInstanceValue({
            systemType: this.openedSystem,
            systemInstanceName: key,
            key: 'to_consumer',
          });
        spinnerColor = this.dialogHybridLineColors.colorAtCircle;
        highlightBottomValue = this.dialogHybridHighlightBottomValue;
      } else if (this.openedSystem === EnergyCircleType.house && this.displayEnergyFlowHouseInfo === false) {
        // but only use calculated value if we dont have a hybrid system
        bottomValue =
          this.defineHouseValue((this.totalConsumption as number) /
          this.getSystemCount(this.deviceData, this.openedSystem));
      } else {
        bottomValue = this.getSystemInstanceValue({
          systemType: this.openedSystem,
          systemInstanceName: key,
        });
      }

      return {
        id: key,
        centerContent: { type: 'icon', value: energyCircleIconForType(this.openedSystem) },
        bottomValue,
        position: index < 5 ? CirclePosition.top : CirclePosition.bottom,
        errorWarningMode: this.measurements[systemComponent.error],
        title: systemComponent.title,
        spinnerColor: spinnerColor ?? getDefaultSpinnerColor(this.openedSystem, bottomValue),
        highlightBottomValue,
        unit: kiloWattHourUnit,
      } as EnergyCircleDisplayData;
    });
  }
  get systemDialogLines(): Partial<EnergyLineDisplayData>[] {
    if (!this.openedSystem) return [];

    return this.systemDialogCircles.map((displayData: EnergyCircleDisplayData) => {
      if (isHybrid(this.openedSystem)) {
        return {
          id: displayData.id,
          arrowDirection: this.dialogHybridArrowDirection,
          colors: this.dialogHybridLineColors,
          oneArrowOnly: true,
        } as Partial<EnergyLineDisplayData>;
      } else {
        return {
          id: displayData.id,
          arrowDirection: getDefaultArrowDirection(this.openedSystem, displayData),
          colors: getDefaultLineColors(this.openedSystem, displayData),
        } as Partial<EnergyLineDisplayData>;
      }
    });
  }

  get totalConsumption(): number | undefined {
    return this.energyDataByPeriodResponse?.calculations.total_consumption;
  }
}
