
import { Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';
import { Time } from '@/ui/mixins/time';
import { mixins } from 'vue-class-component';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';

/**
 * Component that represent offline timer.
 * Shows how much time has passed since last mqtt connection of project.
 */
@Component({
  components: {
    CircleSpinner,
  },
})
export default class OfflineTimer extends mixins(Time) {
  @State('projects') projectsState!: IProjectsState;
  @State('mqttClient') mqttState!: any;

  doesHover = false;
  timer: any = null;
  offlineTimerMessage = '';
  offlineTimerMessageTooltip = '';
  delay = 5000;
  showLoading = false;

  @Watch('projectName')
  onRouteChange(val: string | undefined) {
    if (val) this.loadOfflineTimer();
    else {
      this.offlineTimerMessage = '';
      this.offlineTimerMessageTooltip = '';
      clearInterval(this.timer);
    }
  }

  @Watch('projectStatus')
  onProjectStatusChange(val: boolean) {
    if (val) {
      // if project is online, dont need to show loading animation
      this.$emit('offlineTimerMessage', true);
      this.showLoading = false;
    } else {
      if (this.showLoading === false) {
        this.loadOfflineTimer(false);
      }
    }
  }

  get projectName() {
    return this.projectsState.project.name;
  }

  get lastHeartbeatMessage() {
    return this.mqttState.lastHeartbeatMessage;
  }

  get projectStatus() {
    return this.mqttState.online;
  }

  get internetConnected() {
    return this.mqttState.internet;
  }

  /**
   * Converts time to short note
   * @param time seconds
   * @return {string} Example: 2 Day/s...
   */
  getDateShort(time: number): string {
    const { days, hours, minutes } = this.getDateFromSeconds(time);

    const hoursStr = hours ? `${hours} ${this.$t('uiComponents.offlineTimerAppBar.hourTranslation')}` : '';
    const minutesStr = minutes ? `${minutes} ${this.$t('uiComponents.offlineTimerAppBar.minuteTranslation')}` : '';

    if (days) {
      return `${days} ${this.$t('uiComponents.offlineTimerAppBar.dayTranslation')}`;
    } else if (hours) {
      return hoursStr;
    } else {
      return minutesStr;
    }
  }

  /**
   * Converts time to long note
   * @param time seconds
   * @return {string} Example: 2 Day/s 2 Hour/s 2 Minute/s
   */
  getDateLong(time: number): string {
    const { days, hours, minutes } = this.getDateFromSeconds(time);

    const dayStr = days ? `${days} ${this.$t('uiComponents.offlineTimerAppBar.dayTranslation')}, ` : '';
    const hoursStr = hours ? `${hours} ${this.$t('uiComponents.offlineTimerAppBar.hourTranslation')}, ` : '';
    const minutesStr = minutes ? `${minutes} ${this.$t('uiComponents.offlineTimerAppBar.minuteTranslation')}` : '';

    return `${dayStr}${hoursStr}${minutesStr}`;
  }

  /**
   * Loads offline timer value according to last heartbeat message
   */
  loadOfflineTimer(handleLoading = true) {
    if (handleLoading) {
      // this will make StatusIndicator for Project Online state invisible
      this.$emit('offlineTimerMessage', false);
      this.showLoading = true;
    }
    this.timer = setInterval(() => {
      const current = Math.trunc(new Date().getTime() / 1000);
      const lastHB = Math.trunc(this.lastHeartbeatMessage / 1000);

      if (!this.lastHeartbeatMessage) this.offlineTimerMessage = '';
      else {
        const timeDifference = current - lastHB;
        this.offlineTimerMessage = timeDifference > 60 ? this.getDateShort(timeDifference) : '';
        this.offlineTimerMessageTooltip = timeDifference > 60 ? this.getDateLong(timeDifference) : '';
      }
      if (handleLoading) {
        this.showLoading = false;
        // this will make StatusIndicator for Project Online state visible again after 5 seconds
        this.$emit('offlineTimerMessage', true);
      }
    }, this.delay);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
