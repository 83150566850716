
import { Component } from 'vue-property-decorator';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { SendingState } from '@/ui/components/devices/devices/BasicControl';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IDevice } from '@/types/devices.types';

@Component({
  components: {
    LightPushButton,
    WizardContentView,
  },
})
export default class InstallationPage extends WizardComponent {
  sent = false;

  deviceData: IDevice = {
    id: '',
    name: this.$t('installationWizard.inviteUsers.installationPage.initialLoadDevice.name').toString(),
    data: {
      type: 'LightPushButton',
      meta: {
        deviceSchema: {
          additionalBasicDevices: [],
          basicDevices: ['PushButton'],
        },
        minMaxBorders: {},
      },
      mappings: {
        PushButton_onOff: 'prgEnergy.fbI.bStartInitCharge',
        PushButton_state: 'prgEnergy.fbI.bStartInitCharge',
        ShowEvent_errorWarningState: '',
      },
    },
    favorite: false,
    collection_id: '',
    created_at: new Date(Date.now()),
  }

  handleSent(event: any) {
    this.sent = event === SendingState.Success;
  }
}
