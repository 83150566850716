
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyVisualisationIO
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/index.vue';
import {
  allIOEnergyCircleTypes,
  IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  CirclePosition,
  IOEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import { IDevice } from '@/types/devices.types';
import {
  energyBlue,
  energyGreen,
  energyGrey,
  energyGreyColors,
  energyRed,
  energyYellow,
  energyOrange,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';
import ActualViewSystemDialogIO
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/ActualViewIO/ActualViewSystemDialogIO.vue';
import { kiloWattUnit } from '@/utils/unit/unitConstants';
import EnergyIOComponent from '../utils/EnergyIOComponent';

type SystemInstances = Record<string, SystemInstance>
type SystemInstance = Record<string, string>

const devicesWithDynamicBottomValue: string[] = ['EMS', 'EMSV2'];

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    ActualViewSystemDialogIO,
    EnergyVisualisationIO,
  },
  computed: {
    devicesWithDynamicBottomValue: () => devicesWithDynamicBottomValue,
  },
})
export default class ActualViewIO extends mixins(EnergyIOComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: false }) enableMinWidth!: boolean;
  @Getter('projects/project') project!: IProject;

  showDialog = false;
  openedSystem?: IOEnergyCircleType;
  openedSystemId = '';

  @Provide('onIOCircleClick') onIOCircleClick = this.onCircleClickCallback;

  onCircleClickCallback(circleData: IOEnergyCircleDisplayData) {
    this.openedSystemId = circleData.id;
    this.openedSystem = circleData.systemType as IOEnergyCircleType;
    this.showDialog = true;
  }

  avg(systemInstances: Record<string, Record<string, string>>, mappedVariable = 'soc') {
    const values: number[] = Object.values(systemInstances).map(
      (systemInstanceMappings: Record<string, string>) => {
        const measurement = systemInstanceMappings[mappedVariable];
        return this.measurements[measurement] as number;
      },
    );
    const sum: number = values.reduce((acc: number, value: number) => acc + value, 0);
    const result = sum / values.length;
    return Number.isNaN(result) ? 0 : result;
  }
  get shownSystems() {
    return this.filterPresentIoSystems(allIOEnergyCircleTypes);
  }

  mappingsCount(id: string, systemType: IOEnergyCircleType) {
    return Object.values(this.deviceData.data.meta.controllerMappings[systemType]?.components[id]?.power).length ?? 0;
  }

  sumAllPowers(id: string, systemType: IOEnergyCircleType) {
    let sum = 0;
    // Assuming power values should be strings as per your forEach loop
    const powers = Object.values(this.deviceData.data.meta.controllerMappings[systemType]?.components[id]?.power) as string[];
    powers.forEach((power: string) => {
      sum += this.measurements[power] as number ?? 0;
    });
    return sum;
  }

  getCirclePositionForType(systemType: IOEnergyCircleType) {
    switch (systemType) {
      case IOEnergyCircleType.inputs:
        return CirclePosition.top;
      case IOEnergyCircleType.outputs:
        return CirclePosition.bottom;
      case IOEnergyCircleType.hybrids:
        return CirclePosition.middleLeft;
      default:
        return CirclePosition.top;
    }
  }

  getColorByType(systemType: IOEnergyCircleType, value: number) {
    switch (systemType) {
      case IOEnergyCircleType.inputs:
        return energyYellow;
      case IOEnergyCircleType.outputs:
        return energyGreen;
      case IOEnergyCircleType.hybrids:
        return value < 0 ? energyGreen : energyRed;
      default:
        return energyGrey;
    }
  }

  sumAllPowersByType(systemType: IOEnergyCircleType) {
    let sum = 0;
    const components = this.getMappedIoSystemComponents(systemType);

    Object.values(components).forEach((component: any) => {
      if (systemType === IOEnergyCircleType.hybrids) {
        sum += this.measurements[component.power] as number ?? 0;
      } else {
        sum += this.sumAllPowers(component.id, systemType);
      }
    });
    return sum;
  }

  get totalDifferenceForAllInputsAndOutputs() {
    const inputs = this.sumAllPowersByType(IOEnergyCircleType.inputs);
    const outputs = this.sumAllPowersByType(IOEnergyCircleType.outputs);
    const hybrids = this.sumAllPowersByType(IOEnergyCircleType.hybrids);
    return inputs + hybrids - outputs;
  }

  get circlesDisplayData() {
    const mappedSystems: IOEnergyCircleDisplayData[] = [];
    this.shownSystems.forEach((systemType: IOEnergyCircleType) => {
      const components = this.getIoSystemComponents(systemType);
      // convert the components Record to an array of objects
      const mappedComponents = Object.keys(components).map((key: string) => {
        return {
          id: key,
          ...components[key],
        };
      });

      if (systemType === IOEnergyCircleType.outputs) {
        mappedSystems.push({
          id: 'calculated',
          title: `${this.$t(`mlModel.${this.deviceData.data.type}.difference`)}`,
          systemType,
          spinnerColor: energyOrange,
          position: CirclePosition.bottom,
          disableSpinner: false,
          unit: kiloWattUnit,
          bottomValue: this.totalDifferenceForAllInputsAndOutputs,
        });
      }
      mappedComponents.forEach((component: any) => {
        const value = (systemType !== IOEnergyCircleType.hybrids ? this.sumAllPowers(component.id, systemType) : this.measurements[component.power]) as number ?? 0;
        const spinnerColor = this.getColorByType(systemType, value);
        const circlePosition = this.getCirclePositionForType(systemType);
        mappedSystems.push({
          id: component.id,
          title: component.title,
          systemType,
          systemCount: systemType !== IOEnergyCircleType.hybrids ? this.mappingsCount(component.id, systemType) : 1,
          spinnerColor,
          position: circlePosition,
          disableSpinner: false,
          unit: kiloWattUnit,
          bottomValue: value,
        });
      });
    });
    return mappedSystems;
  }

  get linesDisplayData() {
    const firstOutputIndex = this.circlesDisplayData.findIndex((circle) => circle.systemType === IOEnergyCircleType.outputs);
    return this.circlesDisplayData.map((displayData: IOEnergyCircleDisplayData, index: number) => {
      let arrowDirection;
      let colors;

      if (displayData.bottomValue === 0) {
        arrowDirection = ArrowDirection.none;
        colors = energyGreyColors;
      } else {
        switch (displayData.systemType) {
          case IOEnergyCircleType.inputs:
            arrowDirection = ArrowDirection.toCenter;
            colors = {
              colorAtCenter: energyBlue,
              colorAtCircle: energyYellow,
            };
            break;
          case IOEnergyCircleType.outputs:
            arrowDirection = ArrowDirection.awayFromCenter;
            colors = {
              colorAtCenter: energyBlue,
              colorAtCircle: index === firstOutputIndex ? energyOrange : energyGreen,
            };
            break;
          case IOEnergyCircleType.hybrids:
            arrowDirection = displayData.bottomValue !== undefined && displayData.bottomValue > 0 ? ArrowDirection.toCenter : ArrowDirection.awayFromCenter;
            colors = {
              colorAtCenter: energyBlue,
              colorAtCircle: displayData.bottomValue !== undefined && displayData.bottomValue > 0 ? energyRed : energyGreyColors,
            };
            break;
          default:
            arrowDirection = 0;
            colors = energyGreyColors;
        }
      }

      return {
        id: displayData.id,
        arrowDirection,
        colors,
      } as Partial<EnergyLineDisplayData>;
    });
  }
}
