
import { Vue, Component } from 'vue-property-decorator';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IProject } from '@/types/project.types';
import { Getter, Action } from 'vuex-class';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    WizardContentView,
  },
})
export default class FinalPageInstallationWizard extends Vue {
  @Getter('projects/project') project!: IProject;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  localProject: any = null;
  async handleNext() {
    this.localProject = cloneDeep(this.project);
    await this.updateProject({
      ...this.localProject,
      meta: {
        ...this.localProject.meta,
        wizard_completed_at: new Date().toISOString(),
        wasInstallationWizardCompleted: true,
      },
    });
    this.$router.push(`/projects/${this.$route.params.id}/favorites`);
  }
}
