
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import ChargeStationMinPowerSelection from '@/ui/components/devices/devices/base/ChargeStationMinPowerSelection.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { IDevice } from '@/types/devices.types';

/**
 * Charge station system settings.
 */
@Component({
  components: {
    ChargeStationMinPowerSelection,
    DropDownBase,
    OutputFieldBase,
    ShowEventDotBase,
    SliderBase,
    PushButtonBase,
    Switch2VBase,
    InputFieldBase,
    LabelUnitWrapper,
  },
})
export default class ChargeStationSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() systemInstance!: string;
  @Prop() system!: EnergyCircleType;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get showEventDotCarConnected() {
    return {
      'ShowEventDot_errorWarningState': this.instanceData.car_connected,
    };
  }
  get outputFieldChargingTime() {
    return {
      'OutputField_actualValue': this.instanceData.charging_time,
    };
  }
  get outputFieldTargetPower() {
    return {
      'OutputField_actualValue': this.instanceData.target_power,
    };
  }
  get showEventDotStateCharging() {
    return {
      'ShowEventDot_errorWarningState': this.instanceData.state_charging_station,
    };
  }
  get inputFieldPriorityEMS() {
    return {
      'InputField_targetValue': this.instanceData.priority,
    };
  }
  get inputFieldEnableSoc() {
    return {
      'InputField_targetValue': this.instanceData.enable_soc,
    };
  }
  get inputFieldDisableSOC() {
    return {
      'InputField_targetValue': this.instanceData.disable_soc,
    };
  }
  get inputFieldMaxPower() {
    return {
      'InputField_targetValue': this.instanceData.max_power,
    };
  }

  get sliderManual() {
    return {
      'Slider_targetValue': this.instanceData.slider_manual,
    };
  }
  get sliderManualActualValue() {
    return {
      'OutputField_actualValue': this.instanceData.slider_target_power,
    };
  }
  get sliderManualActualValueState() {
    return this.measurements.get(this.instanceData.slider_target_power);
  }
  get sliderManualIsActive() {
    return true;
  }

  get switch2VManualOn() {
    return {
      'Switch2V_onOff': this.instanceData.switch_manual,
      'Switch2V_state': this.instanceData.state_manual,
    };
  }
  get switch2VOnEmergencyPowerOff() {
    return {
      'Switch2V_onOff': this.instanceData.switch_emergency,
      'Switch2V_state': this.instanceData.state_emergency,
    };
  }
  get switch2VEnableChargingStation() {
    return {
      'Switch2V_onOff': this.instanceData.switch_enable,
      'Switch2V_state': this.instanceData.state_enable,
    };
  }
}
