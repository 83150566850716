
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LynusChartBasicView from '@/ui/components/devices/charts/charts/LynusChart.vue';
import DataExportNew from '@/ui/components/devices/charts/charts/DataExportNew.vue';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import TotalValuesWindow from '@/ui/components/devices/charts/components/TotalValuesWindow.vue';
import {
  getChartOptions,
  Periods,
} from '@/ui/components/devices/charts/charts/ChartUtils';
import _ from 'lodash';
import CompareModal from '@/ui/components/devices/charts/components/CompareModal.vue';
import CalendarNavigation from '@/ui/components/devices/charts/charts/CalendarNavigation.vue';
import { ChartData, ChartOption } from '@/types/chart.types';

/**
 * Component that represent chart core (Highcharts, Navigation...)
 */
@Component({
  components: {
    CalendarNavigation,
    LynusChartBasicView,
    DataExportNew,
    DeviceActions,
    TotalValuesWindow,
    CompareModal,
  },
})
export default class BaseChart extends Vue {
  @Prop() chartData!: ChartData;
  @Prop() chartWidth!: number;
  @Prop({ default: 0 }) parentComponentWidth!: number;
  @Prop({ default: undefined }) chartHeight!: number;
  @Prop({ default: true }) showDeviceActions!: boolean;
  @Prop({ default: null }) navigationItemsToExclude!: null | string[];
  @Prop({ default: false }) isThresholdLine!: boolean;
  @Prop({ default: 3 }) minNumberOfSeriesForWideChart!: number;
  @Prop({ default: false }) showTotalOfSeries!: number;
  @Prop({ default: false }) enableMultiSelect!: boolean;
  @Prop({ default: null }) modularDefaultPeriod!: string;
  @Prop({ default: null }) chartScores!: Record<string, {value: number; unit: string}>;
  @Getter('projects/projectStartedAtNumber') projectStartedAtNumber!: number;

  currentPeriod = Periods.DAY;
  reRenderKey = 0;

  overflow: any = 'hidden';
  disableNavButton: any = false;
  dataToExport: any = null;
  isStackingButtonActive = true;
  differenceSeriesStats: any = [];
  otherSeriesStats: any = [];
  localLegendItems = [];
  isTooltip = false;
  errorStatus = false;
  isInitChartDone = false;
  timer: NodeJS.Timeout|undefined;

  @Watch('chartData', { deep: true })
  handleChartDataChange() {
    this.reRenderKey += 1;
  }

  handleChartInitDone() {
    this.isInitChartDone = true;
    this.handlePeriodsForAutarkiegrad();
  }
  get chartOptions() {
    return getChartOptions(this.chartData);
  }

  get numberOfColumns() {
    const optionsList = this.chartOptions.filter((el: any) => ['column', 'diff'].includes(el.type));
    return optionsList.length;
  }
  get numberOfLines() {
    const optionsList = this.chartOptions.filter((el: any) => el.type === 'line' || el.type === 'area');
    return optionsList.length;
  }

  async handleReloadButton() {
    this.errorStatus = false;
    await (this.$refs.LynusChartBasicView as any).initChart();
  }

  async handleNexPrevButtonClick(direction: any) {
    this.disableNavButton = true;
    await (this.$refs.LynusChartBasicView as any).handlePrevNextButtonCalendar(direction, this.currentPeriod);
    this.handlePeriodsForAutarkiegrad();
    this.$emit('chartRangeChange', { start: (this.$refs.LynusChartBasicView as any).chart.chartBounds.start, end: (this.$refs.LynusChartBasicView as any).chart.chartBounds.end, period: this.currentPeriod });
    this.disableNavButton = false;
    if (this.currentPeriod !== Periods.LIVE) {
      this.defineSeriesStatsSingleSelection();
    }
  }

  defineSeriesStatsSingleSelection() {
    const otherSeriesList = this.chartOptions.filter((ser: ChartOption) => ser.agg !== 'diff');
    const diffSeriesList = this.chartOptions.filter((ser: ChartOption) => ser.agg === 'diff');
    this.differenceSeriesStats = diffSeriesList.map((ser: ChartOption, inx: number) => {
      const values = this.getValuesOfSeries(inx, ser.type);
      const totalValue = _.sum(values) ?? 0;
      const seriesName = this.getSeriesNameForStats(ser, inx);
      return {
        name: seriesName,
        sum: totalValue,
        unit: ser.unit || null,
      };
    });
    this.otherSeriesStats = otherSeriesList.map((ser: ChartOption, inx: number) => {
      const values = this.getValuesOfSeries(inx, ser.type);
      const max: number = _.max(values) ?? 0;
      const min: number = _.min(values) ?? 0;
      const avg: number = _.mean(values) ?? 0;
      const seriesName = this.getSeriesNameForStats(ser, inx);
      return {
        name: seriesName,
        minValue: min.toFixed(2),
        maxValue: max.toFixed(2),
        avgValue: avg.toFixed(2),
        unit: ser.unit || null,
      };
    });
  }

  getSeriesNameForStats(series: ChartOption, index: number) {
    if (series.name !== '') {
      // if a series has a name, use it
      return series.name;
    }
    if (series.seriesType === 'View') {
      // if a series is a view, use the view name
      return series.var;
    }

    // if nothing matches, use the series type + index of the series
    return `${series.seriesType}_${index}`;
  }

  defineSeriesStatsMultiSelection() {
    this.differenceSeriesStats = [];
    this.otherSeriesStats = [];
    const chart = (this.$refs.LynusChartBasicView as any).getChartObject();
    chart.chart.series.forEach((seriesElement: any, seriesIndex: any) => {
      const currentChartOptionIndex = seriesIndex % this.chartOptions.length;
      let valuesArray;
      let seriesName = chart.chart.legend.allItems[seriesIndex].name;
      if (this.chartOptions[currentChartOptionIndex].agg === 'diff') {
        valuesArray = this.getValuesOfSeries(seriesIndex, this.chartOptions[currentChartOptionIndex].type);

        const totalValue = _.sum(valuesArray) ?? 0;
        if (seriesName === '') {
          seriesName = `${this.chartOptions[currentChartOptionIndex].seriesType}_${seriesIndex}`;
        }
        this.differenceSeriesStats.push({ name: seriesName, sum: totalValue.toFixed(2), unit: this.chartOptions[currentChartOptionIndex].unit });
      } else {
        valuesArray = this.getValuesOfSeries(seriesIndex, this.chartOptions[currentChartOptionIndex].type);

        const max: number = _.max(valuesArray) ?? 0;
        const min: number = _.min(valuesArray) ?? 0;
        const avg: number = _.mean(valuesArray) ?? 0; // return avg of all numbers in array: https://www.geeksforgeeks.org/lodash-_-mean-method/

        if (seriesName === '') {
          seriesName = `${this.chartOptions[currentChartOptionIndex].seriesType}_${seriesIndex}`;
        }
        this.otherSeriesStats.push({ name: seriesName, minValue: min.toFixed(2), maxValue: max.toFixed(2), avgValue: avg.toFixed(2), unit: this.chartOptions[currentChartOptionIndex].unit });
      }
    });
  }

  getValuesOfSeries(index: number, type: string) {
    const chart = (this.$refs.LynusChartBasicView as any).getChartObject();
    if (type !== 'column') {
      return chart.chart.series[index].data.map((dataElement: any) => {
        return dataElement.options.y;
      });
    } else {
      return (chart.chart.series[index] as any).yData;
    }
  }

  handleNavigation(period: string) {
    this.currentPeriod = period;
    if (this.isInitChartDone) {
      this.handlePeriodsForAutarkiegrad();
      this.disableNavButton = true;
      this.reRenderKey += 1;
      // generateObject for Total Sum (currently only for Difference Series)
      if (this.currentPeriod !== Periods.LIVE) {
        this.defineSeriesStatsSingleSelection();
      }
    }
  }

  chartLoaded() {
    this.disableNavButton = false;
    this.defineSeriesStatsSingleSelection();
    this.$emit('chartRangeChange', { start: (this.$refs.LynusChartBasicView as any).chart.chartBounds.start, end: (this.$refs.LynusChartBasicView as any).chart.chartBounds.end, period: this.currentPeriod });
  }

  handleFullScreen() {
    (this.$refs.LynusChartBasicView as any).handleFullScreen();
  }

  handleDataToExport(dataToExport: any) {
    this.dataToExport = dataToExport;
  }

  handleStackingOptions() {
    (this.$refs.LynusChartBasicView as any).toggleStacking();

    this.isStackingButtonActive = false;
    setTimeout(() => {
      this.isStackingButtonActive = true;
    }, 2000);
  }

  handleLineOptionsToggle() {
    (this.$refs.LynusChartBasicView as any).toggleLineOptionChange();
  }

  // loads chart by calendar state
  async handleCalendar(date: any) {
    this.disableNavButton = true;
    (this.$refs.calendarDialog as any).closeDialog();
    this.$emit('chartRangeChange', { start: (this.$refs.LynusChartBasicView as any).chart.chartBounds.start, end: (this.$refs.LynusChartBasicView as any).chart.chartBounds.end, period: this.currentPeriod });
    this.handlePeriodsForAutarkiegrad();
    await (this.$refs.LynusChartBasicView as any).handlePeriod(date);
    this.disableNavButton = false;

    // sets up needed Data for data export
    const chart = (this.$refs.LynusChartBasicView as any).getChartObject();
    this.localLegendItems = chart.chart.legend.allItems;
    this.dataToExport = chart.dataToExport;

    // generateObject for Total Sum (currently only for Difference Series)
    if (!this.enableMultiSelect) {
      if (this.currentPeriod !== Periods.LIVE) {
        this.defineSeriesStatsSingleSelection();
      }
    } else {
      this.defineSeriesStatsMultiSelection();
    }
  }

  handlePeriodsForAutarkiegrad() {
    const chart = (this.$refs.LynusChartBasicView as any);
    this.$emit('handleAutarkiegrad', chart.chart.chartBounds);
  }

  onDataLoaded(data: any, chart: any, period: any) {
    this.$emit('onDataLoaded', data, chart, period);
    this.errorStatus = (this.$refs.LynusChartBasicView as any).chart.loadingError;
  }
}
