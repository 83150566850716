import { IFlatsConfig } from '@/types/wizards/tenant.types';
import store from '@/store';
import i18n from '@/ui/plugins/i18n';

/**
 * adds address street and city information together to show in address field
 * @param flatsConfigList Array of type IFlatsConfig
 * @returns object of addresses
 */
export function initFlatsAddresses(flatsConfigList: IFlatsConfig[]) {
  const addressList: Record<string, string> = {};
  flatsConfigList.forEach((element: IFlatsConfig) => {
    addressList[element.name] = `${element.address.street}, ${element.address.city}`;
  });
  return addressList;
}

export async function downloadDocument(downloadTimes: { start: string; end: string }, project_id: string) {
  const downloadStartTime = new Date(downloadTimes.start).getTime() / 1000;
  const donwloadEndTime = new Date(downloadTimes.end).getTime() / 1000;
  const file = await store.dispatch('tenantWizard/downLoadTenant', { project_id, start: downloadStartTime, end: donwloadEndTime });
  // is needed so no empty file is downloaded
  if (!file) {
    return;
  }
  // creates link to download report
  const downloadAnchorNode = document.createElement('a');

  const zipBlob = new Blob([file], { type: 'application/zip' });
  // add attributes for download link
  downloadAnchorNode.setAttribute('href', URL.createObjectURL(zipBlob));
  downloadAnchorNode.setAttribute('download', 'report.zip');
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

/**
 * Checks if given array has duplicate values
 * @param array array to check for duplicates ex. [1,2,3...] or ['a','b','c'...]
 * @param langPath path to get the translation for the error message
 * @returns string if duplicate value is found, true if no duplicate value is found
 */
export function hasArrayDuplicateValue(array: any[], langPath: string) {
  // Create an object to store the count of each value
  const count: any = {};

  // Iterate over each element in the array
  for (let i = 0; i < array.length; i++) {
    const value = array[i];

    // If the value is already in the count object, it's a duplicate
    if (count[value]) {
      return i18n.t(langPath, { value }).toString();
    }

    // Otherwise, add the value to the count object
    if (value !== '') {
      count[value] = 1;
    }
  }

  return true;
}

/**
 * Filters number from string for tenant wizard counter mappings
 */
export function filterNumberFromString(text: string) {
  const lastChars = text.slice(-3); // get last 3 characters
  return lastChars.replace(/[^0-9]/g, ''); // returns only numbers
}
