
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';

@Component({
  components: {
    WizardContentView,
  },
})
export default class ManualsPage extends WizardComponent {
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;

  systemType = '';
  questionsAwnsers: any = {
    inverterQuestion: { answer: false },
    constructionQuestion: { answer: false },
    startupQuestion: { answer: false },
  }

  /**
   * @description Returns true if the user has answered all questions with yes
   */
  get disableNextButton() {
    const finalQuestionCheck: any = {};
    Object.entries(this.questionsAwnsers).forEach((question: any) => {
      if (question[1].subQuestions === undefined) {
        finalQuestionCheck[question[0]] = question[1].answer;
      } else {
        if (question[1].answer) {
          finalQuestionCheck[question[0]] = question[1].answer;
        } else {
          const subQuestions = Object.values(question[1].subQuestions).every((subQuestion: any) => subQuestion.answer);
          finalQuestionCheck[question[0]] = subQuestions;
        }
      }
    });
    if (Object.values(finalQuestionCheck).every((value: any) => value)) {
      return false;
    }
    return true;
  }

  getTypeDependentTranslation(key: string) {
    if (this.isSolarmax && key === 'inverterQuestion') return this.$t('installationWizard.defineComponents.manualsPage.solarmaxInverterQuestion');
    return this.$t(`installationWizard.defineComponents.manualsPage.${key}`);
  }

  /**
   * @description Returns the page numbers for the PDF file. Where the user can find the information
   */
  get pageCount() {
    if (this.systemType === 'aios') {
      return {
        inverterQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.3.9`,
        constructionQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')} 4`,
        startupQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.8`,
        powerConverterQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.3.3`,
        arePowerConvertersDismantled: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.3.3.5`,
      };
    } else {
      return {
        inverterQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.3.2`,
        constructionQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')} 4`,
        startupQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.8`,
        powerConverterQuestion: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.5.3`,
        arePowerConvertersDismantled: `${this.$t('installationWizard.defineComponents.manualsPage.chapter')}: 4.5.3.4`,
      };
    }
  }

  /**
   * @description Returns the URL for the Lynus documentation
   */
  get redirectURL() {
    if (this.systemType === 'aios') {
      return 'https://lynus.zammad.com/help/de-de/10-all-in-one-batteriespeichersysteme';
    }
    return 'https://lynus.zammad.com/help/de-de/7-standalone-batteriespeicher';
  }

  /**
   * @description Opens the Lynus documentation in a new tab
   */
  openTab() {
    const url = this.redirectURL;
    window.open(url, '_blank');
  }

  nextButtonClick() {
    this.handleIncrement();
  }

  created() {
    if (!this.isSolarmax) this.questionsAwnsers.powerConverterQuestion = { answer: true, showSubQuestion: false, subQuestions: { arePowerConvertersDismantled: { answer: false } } };
    if (this.project.meta.controller.batterySystemType.includes('AIOS')) {
      this.systemType = 'aios';
    } else {
      this.systemType = 'standalone';
    }
  }
}
