
import { Component, Prop, Watch } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Getter, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { cloneDeep } from 'lodash';
import { IMember } from '@/types/members.types';
import { IFlatsConfig } from '@/types/wizards/tenant.types';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class FlatDetails extends mixins(Validation) {
  @Prop({ default: true }) showNumberOfFlats!: boolean;
  @Prop({ default: true }) showTitle!: boolean;
  @Prop({ default: false }) showBuildingAddress!: boolean;
  @Prop({ default: false }) showRenterSwapInfo!: boolean;
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('members/members') members!: IMember[];
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;

  indexChanged: any[] = [];
  valid = false;
  isInitialLoadingDone = false;
  buildingAddress = {
    clientName: '',
    street: '',
    zip: '',
    city: '',
    country: '',
  };

  flatsConfigList: IFlatsConfig[] = [
    {
      name: '',
      mail: '',
      clientId: '',
      address: {
        clientName: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        houseNumber: '',
      },
      additionalText: this.$t('tenantWizard.flatsInformation.defaultAdditionalText') as string,
      sideCostPercentage: 0,
      hasChargingStation: false,
      counters: {
        energyCounter: [],
        heatingCounter: [],
        serviceWaterCounter: [],
        coldWaterCounter: [],
        coolingCounter: [],
      },
    },
  ]
  flatsCount = 1;
  oldFlatsCount = 1;
  saved = true;
  currentFlatConfig: any = undefined;

  // checks if all flats are filled
  get allFlatsFilled() {
    const statusList = this.flatsConfigList.map((value: IFlatsConfig, index: number) => {
      return this.hasEmptyValue(index);
    });
    return statusList.every(element => element === false);
  }

  /**
   * returns a array of all available member mails
   */
  get availableMemberMails() {
    return this.members.map((member: IMember) => member.email).filter((email: string) => !email.includes('support'));
  }

  panels: any = [];

  @Watch('panels')
  updatePanels(newVal: any, oldVal: any) {
    if (this.showNumberOfFlats) {
      if (newVal) {
        this.handleClickPanelHeader();
      }
      return;
    }
    const defaultFlatConfig = {
      name: '',
      mail: '',
      clientId: '',
      address: {
        clientName: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        houseNumber: '',
      },
      additionalText: this.$t('tenantWizard.flatsInformation.defaultAdditionalText') as string,
      sideCostPercentage: 0,
      counters: {
        energyCounter: [{ id: '', name: '' }],
        heatingCounter: [{ id: '', name: '' }],
        serviceWaterCounter: [{ id: '', name: '' }],
        coldWaterCounter: [{ id: '', name: '' }],
      },
    };

    if (newVal) {
      // update the currentFlatConfig when panel is opened to then compare if it was changed
      this.currentFlatConfig = this.flatsConfigList[newVal[0]];
      // trigger validation for textfields when panel is opened
      this.handleClickPanelHeader();
    } else {
      // update config with default values when panel is closed
      this.currentFlatConfig = defaultFlatConfig;
    }

    if (oldVal !== undefined && this.saved === false && !this.showNumberOfFlats) {
      // update the flatConfigList when panel is closed and the flat was changed
      this.$emit('updateSpecificFlat', { flat: { ...this.flatsConfigList[oldVal] }, index: oldVal });
    }
    // set saved to true
    this.saved = true;
  }

  @Watch('valid')
  updateValid() {
    if (this.allFlatsFilled === false) {
      this.$emit('updateValid', { status: false });
      return;
    }
    this.$emit('updateValid', { status: this.valid });
  }

  // emits the flatsConfigList to the parent component on input action of any field
  handleInput(index = null) {
    if (this.currentFlatConfig !== this.flatsConfigList[this.panels]) {
      this.saved = false;
    } else {
      this.saved = true;
    }
    if (index !== null) {
      if (!this.indexChanged.includes(index)) {
        this.indexChanged.push(index);
      }
    }
    // case inside when going through the tenant wizard, there it is not relevant to check inital loading
    if (this.showNumberOfFlats) {
      this.$emit('updateFlats', { flats: this.flatsConfigList });
    }
    if (this.isInitialLoadingDone === true) {
      this.$emit('updateFlats', { flats: this.flatsConfigList });
    }
  }

  handleBuildingAddressChange() {
    this.$emit('updateBuildingAddress', { buildingAddress: this.buildingAddress });
    // case inside when going through the tenant wizard, there it is not relevant to check inital loading
    if (this.showNumberOfFlats) {
      this.$emit('updateFlats', { flats: this.flatsConfigList });
    }
    if (this.isInitialLoadingDone === true) {
      this.$emit('updateFlats', { flats: this.flatsConfigList });
    }
  }

  // adds or removes flats based on the input value of the v-select
  handleChange() {
    const newValue = this.flatsCount;
    const oldValue = this.oldFlatsCount;
    const valueDifference = newValue - oldValue;
    if (valueDifference > 0) {
      for (let index = 0; index < valueDifference; index++) {
        this.flatsConfigList.push({
          name: '',
          mail: '',
          clientId: '',
          address: {
            clientName: '',
            street: '',
            zip: '',
            city: '',
            country: '',
            houseNumber: '',
          },
          additionalText: this.$t('tenantWizard.flatsInformation.defaultAdditionalText') as string,
          sideCostPercentage: 0,
          hasChargingStation: false,
          counters: {
            energyCounter: [{ id: '', name: '' }],
            heatingCounter: [{ id: '', name: '' }],
            serviceWaterCounter: [{ id: '', name: '' }],
            coldWaterCounter: [{ id: '', name: '' }],
            coolingCounter: [{ id: '', name: '' }],
          },
        });
      }
      this.valid = false;
    } else if (valueDifference < 0) {
      this.flatsConfigList = this.flatsConfigList.slice(0, valueDifference);
    }
    this.oldFlatsCount = this.flatsCount;
    this.valid = this.allFlatsFilled;
    this.$emit('updateFlats', { flats: this.flatsConfigList });
  }

  mapFlatConfigList() {
    const tenantFlatsCopy = this.tenantState.tenant.general.flats;

    // fill up building address based on saved flat address
    this.buildingAddress.city = tenantFlatsCopy.flatConfigurations[0].address.city;
    this.buildingAddress.country = tenantFlatsCopy.flatConfigurations[0].address.country;
    this.buildingAddress.zip = tenantFlatsCopy.flatConfigurations[0].address.zip;
    // eslint-disable-next-line prefer-destructuring
    this.buildingAddress.street = tenantFlatsCopy.flatConfigurations[0].address.street.split(',')[0];

    // remap housenumber from street to specific houseNumber field in address
    this.flatsConfigList.forEach((flat: any) => {
      // eslint-disable-next-line prefer-destructuring
      flat.address.houseNumber = flat.address.street.split(', ')[1];
      // eslint-disable-next-line prefer-destructuring
      flat.address.street = flat.address.street.split(',')[0];
    });
  }

  created() {
    if (this.tenantState.tenant.general.flats.flatConfigurations.length !== 0) {
      this.flatsCount = this.tenantState.tenant.general.flats.flatCount;
      this.oldFlatsCount = this.tenantState.tenant.general.flats.flatCount;
      this.flatsConfigList = cloneDeep(this.tenantState.tenant.general.flats.flatConfigurations);
      this.mapFlatConfigList();
    } else {
      this.valid = false;
    }
  }

  async mounted() {
    await this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
    this.isInitialLoadingDone = true;
  }

  // will trigger validation of the form when the panel header is clicked
  // timeout is needed in order for it to work correctly on first open of the panel
  handleClickPanelHeader() {
    setTimeout(() => {
      (this.$refs.form as any).validate();
    }, 300);
  }

  // checks if all values of a flat are filled
  hasEmptyValue(index: number) {
    const variablesToCheckObject = {
      name: this.flatsConfigList[index].name,
      mail: this.flatsConfigList[index].mail,
      // street: this.flatsConfigList[index].address.street,
      // zip: this.flatsConfigList[index].address.zip,
      // city: this.flatsConfigList[index].address.city,
      // country: this.flatsConfigList[index].address.country,
    };
    if (Object.values(variablesToCheckObject).find((element: string) => element === '') === undefined) {
      return false;
    } else {
      return true;
    }
  }

  handleSave(index: number) {
    this.$emit('updateSpecificFlat', { flat: { ...this.flatsConfigList[index] }, index });
    this.indexChanged.filter((value: number) => value !== index);
    this.saved = true;
  }
}
