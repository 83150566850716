import Information from '@/ui/components/wizards/installationWizard/steps/defineComponents/Information.vue';
import ComponentsPage from '@/ui/components/wizards/installationWizard/steps/defineComponents/ComponentsPage.vue';
import BatteryGridSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/BatteryGridSettings.vue';
import ChargingStationSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/ChargingStationSettings.vue';
import HeatingElementSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/HeatingElementSettings.vue';
import GeneralSettingsEMS from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/GeneralSettingsEMS.vue';
import PilotSpecification from '@/ui/components/wizards/installationWizard/steps/defineHardware/PilotSpecification.vue';
import FinalPageInstallationWizard from '@/ui/components/wizards/installationWizard/steps/finalPage/FinalPage.vue';
import GeneralInformations from '@/ui/components/wizards/installationWizard/steps/generalSettings/GeneralInformations.vue';
import InviteUsersToProject
  from '@/ui/components/wizards/installationWizard/steps/inviteUsers/InviteUserToProject.vue';
import InstallationPage
  from '@/ui/components/wizards/installationWizard/steps/inviteUsers/InstallationPage.vue';
import GridDrmSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/GridDrmSettings.vue';
import GeneratorSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/GeneratorSettings.vue';
import HeatingPumpSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/HeatingPumpSettings.vue';
import BigConsumerSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/BigConsumerSettings.vue';
import ChpSettings from '@/ui/components/wizards/installationWizard/steps/defineEmsSettings/ChpSettings.vue';
import SystemTestPage
  from '@/ui/components/wizards/installationWizard/steps/inviteUsers/SystemTestPage.vue';
import ManualsPage from '@/ui/components/wizards/installationWizard/steps/defineComponents/ManualsPage.vue';
import CommonEnergyPriceSettings from '@/ui/components/wizards/baseComponents/commonPages/CommonEnergyPriceSettings.vue';
import AddStringLoggersPage from '@/ui/components/wizards/installationWizard/steps/defineHardware/AddStringLoggersPage.vue';

const installationWizardImports = {
  Information,
  ComponentsPage,
  BatteryGridSettings,
  ChargingStationSettings,
  HeatingElementSettings,
  GeneralSettingsEMS,
  PilotSpecification,
  FinalPageInstallationWizard,
  GeneralInformations,
  InviteUsersToProject,
  InstallationPage,
  GridDrmSettings,
  GeneratorSettings,
  HeatingPumpSettings,
  BigConsumerSettings,
  ChpSettings,
  SystemTestPage,
  ManualsPage,
  CommonEnergyPriceSettings,
  AddStringLoggersPage,
};

export default installationWizardImports;
