
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import EnergyLines from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyLines.vue';
import EnergyCircle from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyCircle/index.vue';
import {
  CirclePosition,
  EnergyCircleDisplayData,
  PlaceholderEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import { CanvasSize } from '@/types/energyVisualisation/energyVisualisation.types';
import { debounce } from 'lodash';

@Component({
  components: {
    EnergyLines,
    EnergyCircle,
    CentralEndpoint,
  },
})
export default class EnergyVisualisation extends Vue {
  @Prop({ default: () => [] }) circlesDisplayData!: EnergyCircleDisplayData[];
  @Prop({ default: () => [] }) linesDisplayData!: Partial<EnergyLineDisplayData>[];
  @Prop({ default: '' }) centralEndPointText?: string;

  resizeRerender = true;
  energyLinesData: EnergyLineDisplayData[] = [];
  canvasSize: CanvasSize = { width: 0, height: 0 };

  debouncedResize: any;

  get topCircles() {
    const res = this.circlesDisplayData.filter((e: EnergyCircleDisplayData) => e.position === CirclePosition.top);
    // To maintain correct spacing if current top row is empty
    return res.length ? res : [new PlaceholderEnergyCircleDisplayData()];
  }

  get middleLeftCircle() {
    return this.circlesDisplayData.find((e: EnergyCircleDisplayData) => e.position === CirclePosition.middleLeft) ?? new PlaceholderEnergyCircleDisplayData();
  }

  get middleRightCircle() {
    return this.circlesDisplayData.find((e: EnergyCircleDisplayData) => e.position === CirclePosition.middleRight) ?? new PlaceholderEnergyCircleDisplayData();
  }

  get bottomCircles() {
    const res = this.circlesDisplayData.filter((e: EnergyCircleDisplayData) => e.position === CirclePosition.bottom);
    // To maintain correct spacing if current bottom row is empty
    return res.length ? res : [new PlaceholderEnergyCircleDisplayData()];
  }

  get energyLinesDataLive() {
    return this.linesDisplayData.map((reactiveLine: Partial<EnergyLineDisplayData>) => {
      const positions = this.energyLinesData.find((staticLine: EnergyLineDisplayData) => staticLine.id === reactiveLine.id);
      return {
        ...reactiveLine,
        arrowDirection: reactiveLine.arrowDirection,
        startPoint: positions?.startPoint ?? { x: 0, y: 0 },
        endPoint: positions?.endPoint ?? { x: 0, y: 0 },
      };
    }) as EnergyLineDisplayData[];
  }

  get isLinesReady() {
    return !!this.canvasSize.width && !!this.canvasSize.height && !!this.energyLinesData.length;
  }

  onResize() {
    this.updateCircles();
    this.resizeRerender = !this.resizeRerender;
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  updateCircles() {
    const actualViewRef = this.$refs.circles as HTMLDivElement;
    if (!actualViewRef) return;
    this.canvasSize = { width: actualViewRef.clientWidth, height: actualViewRef.clientHeight };
    this.energyLinesData = [];
    this.circlesDisplayData.forEach((circle: EnergyCircleDisplayData, index: number) => {
      const ref = this.$refs[circle.id];
      const element: any = Array.isArray(ref) ? ref[0] : ref;
      if (!element) return;

      this.energyLinesData.push({
        ...this.linesDisplayData[index],
        startPoint: {
          x: element.offsetLeft + element.offsetWidth / 2,
          y: element.offsetTop + element.offsetHeight / 2,
        },
        endPoint: { x: this.canvasSize.width / 2, y: this.canvasSize.height / 2 },
      } as EnergyLineDisplayData);
    });
  }
  mounted() {
    this.debouncedResize = debounce(() => this.onResize(), 50);
    setTimeout(() => this.onResize(), 50);
    window.addEventListener('resize', this.debouncedResize);

    this.updateCircles();
  }
  destroyed() {
    window.removeEventListener('resize', this.debouncedResize);
  }
}
