
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { cloneDeep } from 'lodash';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { initFlatsAddresses, hasArrayDuplicateValue, filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import { counterLimits } from '@/ui/components/wizards/tenantWizard/wizardSettings/limits';
import { IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class ColdWater extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('tenantWizard/isAutoMappingEnabled') isAutoMappingEnabled!: boolean;
  @Getter('variables/variablesForComboBox') noAutomappingVaribles!: [];
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  localCounterLimits = counterLimits;
  valid = false;
  sideCosts = 0;
  flatsConfigList: IFlatsConfig[] = [];
  flatsFullAddress: Record<string, string> = {};
  overallColdWaterCounterIdArray: any = [];

  overallColdWaterCounterNumberList: any[] = [];

  valueNotAllowedCheck(value: any) {
    if (!this.isAutoMappingEnabled) {
      return true;
    }
    const number = parseInt(value, 10);
    if (!this.variablesForComboBox.includes(number)) {
      return this.$t('tenantWizard.valueNotAllowed');
    }
    return true;
  }

  get counterAmount() {
    let otherCounterNumber = 0;
    let flatCounterNumber = 0;

    if (this.isAutoMappingEnabled) {
      otherCounterNumber = this.overallColdWaterCounterNumberList.length + 2;
    } else {
      otherCounterNumber = this.overallColdWaterCounterIdArray.length + 2;
    }

    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flatCounterNumber += flat.counters.coldWaterCounter.length;
    });
    return otherCounterNumber + flatCounterNumber;
  }

  get variablesForComboBox() {
    // if automapping is disabled we just return normal meassurement variables
    if (!this.isAutoMappingEnabled) {
      return this.noAutomappingVaribles;
    }
    // if automapping is enabled we return all numbers from 1 to 255
    return Array.from({ length: 255 }, (_, index) => index + 1);
  }

  get hasDuplicateValue(): boolean | string {
    // will contain all numbers that are set either inside overall counters or inside flats counters
    let array = [];
    // if automapping is disabled we disable this validation
    if (!this.isAutoMappingEnabled) {
      array = [this.overallColdWaterCounterIdArray.map((value: { id: string; name: string}) => value.id)].flat();
    } else {
      array = [this.overallColdWaterCounterNumberList.map((value: { id: string; name: string}) => value.id)].flat();
    }

    // add flats counters
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.coldWaterCounter.forEach((counter: any) => {
        array.push(counter.id);
      });
    });

    return hasArrayDuplicateValue(array, 'tenantWizard.hasDuplicateValue');
  }

  get hasDuplicateName(): boolean | string {
    // will contain all numbers that are set either inside overall counters or inside flats counters
    let array = [];

    if (this.isAutoMappingEnabled) {
      array = [this.overallColdWaterCounterNumberList.map((value: { id: string; name: string}) => value.name)].flat();
    } else {
      array = [this.overallColdWaterCounterIdArray.map((value: { id: string; name: string}) => value.name)].flat();
    }

    // add flats counters
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.coldWaterCounter.forEach((counter: any) => {
        array.push(counter.name);
      });
    });

    return hasArrayDuplicateValue(array, 'tenantWizard.hasDuplicateName') ?? true;
  }

  created() {
    this.fetchVariables(this.$route.params.id);
    this.flatsConfigList = this.tenantState.tenant.general.flats.flatConfigurations;
    this.flatsConfigList.forEach((flat: IFlatsConfig, index: number) => {
      if (this.flatsConfigList[index].counters?.coldWaterCounter === null) {
        this.flatsConfigList[index].counters.coldWaterCounter = [];
      }
    });
    if (this.flatsConfigList[0].counters.coldWaterCounter.length === 0) {
      this.flatsConfigList.forEach((flat: IFlatsConfig) => {
        flat.counters.coldWaterCounter.push({ id: '', name: '' });
      });
    }
    if (this.tenantState.tenant.general.overallCounters.coldWaterCounter !== null) {
      this.overallColdWaterCounterIdArray = this.tenantState.tenant.general.overallCounters.coldWaterCounter;
    }
    if (this.isAutoMappingEnabled) {
      this.fillNumbers();
    }
  }

  fillNumbers() {
    // overall part
    this.overallColdWaterCounterNumberList = this.overallColdWaterCounterIdArray.map((counter: any) => {
      return { id: filterNumberFromString(counter.id), name: counter.name };
    });
    // flats part
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.coldWaterCounter.forEach((counter: any) => {
        counter.id = filterNumberFromString(counter.id);
      });
    });
  }

  removeOverallItem(index: number) {
    if (this.isAutoMappingEnabled) {
      this.overallColdWaterCounterNumberList.splice(index, 1);
    } else {
      this.overallColdWaterCounterIdArray.splice(index, 1);
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  addOverallItem() {
    if (this.isAutoMappingEnabled) {
      this.overallColdWaterCounterNumberList.push({ id: '', name: '' });
    } else {
      this.overallColdWaterCounterIdArray.push({ id: '', name: '' });
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  removeCounterFromFlat(flatIndex: number, counterIndex: number) {
    this.flatsConfigList[flatIndex].counters.coldWaterCounter.splice(counterIndex, 1);
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  addCounterToFlat(flatIndex: number) {
    this.flatsConfigList[flatIndex].counters.coldWaterCounter.push({ id: '', name: '' });
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  mounted() {
    this.flatsFullAddress = initFlatsAddresses(this.flatsConfigList);
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  handleBack() {
    // if we are going back and we are in the automapping mode we need to convert the numbers to the variable names
    if (this.isAutoMappingEnabled) {
      this.convertNumbersToVariables();
    }
    this.handleDecrement();
  }

  handleNext() {
    if (this.isAutoMappingEnabled) {
      this.convertNumbersToVariables();
    }
    this.updateTenant();
    this.handleIncrement();
  }

  convertNumbersToVariables() {
    // overall part
    this.overallColdWaterCounterIdArray = this.overallColdWaterCounterNumberList.map((counter: any) => {
      return { id: `prgCWM.lrCounter_CW_C_${counter.id}`, name: counter.name };
    });
    // flats part
    this.flatsConfigList.forEach((flat: IFlatsConfig) => {
      flat.counters.coldWaterCounter.forEach((counter: any) => {
        counter.id = `prgCWM.lrCounter_CW_C_${counter.id}`;
      });
    });
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.flats.flatConfigurations = this.flatsConfigList;
    tenantCopy.general.overallCounters.coldWaterCounter = this.overallColdWaterCounterIdArray;
    this.updateTenantObject(tenantCopy);
  }
}
