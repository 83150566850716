import Unit from '@/utils/unit/unit';

/**
 * Error/warning mode of the border of the circle.
 */
export enum ErrorWarningMode {
  none,
  warning,
  error,
}

/**
 * Describes in which row the circle is placed.
 */
export enum CirclePosition {
  top,
  middleLeft,
  middleRight,
  bottom,
}

export type IconContent = { type: 'icon'; value: string };
export type SocContent = { type: 'soc'; value: number };

export interface EnergyCircleDisplayData {
  /**
   * Unique id (e.g. "pv", "pv1", can be used for testing)
   */
  id: string;
  /**
   * The main content of the circle. Can either be an icon (string) or the value for the battery SOC.
   */
  centerContent: IconContent | SocContent;
  /**
   * Value to display at the bottom of the circle (main value).
   */
  bottomValue?: number;
  /**
   * The unit used for top and bottom values.
   */
  unit?: Unit;
  /**
   * Number of systems for the given circle type. If not set, it is not displayed.
   */
  systemCount?: number;
  /**
   * System. If not set, it is not displayed.
   */
  title?: string;
  /**
   * Whether the circle is positioned in the top, middle (left/right) or bottom row.
   */
  position: CirclePosition;
  /**
   * Color of the rotating spinner around the circle. As a hex string.
   * If undefined, the spinner is not displayed.
   */
  spinnerColor?: string;
  /**
   * Disable spinner without changing color
   */
  disableSpinner?: boolean;
  /**
   * The error/warning mode of the circle's border.
   */
  errorWarningMode?: ErrorWarningMode;
  /**
   * Whether the bottom value should be highlighted.
   */
  highlightBottomValue?: boolean;
  /**
   * Used to let know that opposite dynamic bottomValue active
   */
  bottomDynamicValue?: boolean;

  /**
   * Alternative value to take into account for line movement,
   * if the bottomValue for a system is not set.
   */
  alternativeValue?: number;

  /**
   * Hide value display
   */
  hideValue?: boolean;

  /**
   * Additional Display Data
   * forceCharge: boolean is used to display the force charge icon next to the battery
   * showLoadingAnimationHouseCircle: boolean is used to display the loading animation for the house circle
   * forceAnimation: boolean is used to force the animation of the circle and line
   */
  additionalDisplayData?: {
    forceCharge?: boolean;
    calibrationCharge?: boolean;
    maintenanceCharge?: boolean;
    chargeMaintenance?: boolean;
    showLoadingAnimationHouseCircle?: boolean;
    forceAnimation?: boolean;
  };
}

export interface IOEnergyCircleDisplayData {
  /**
   * Unique id (e.g. "pv", "pv1", can be used for testing)
   */
  id: string;
  /**
   * Define position by either passing inputs, outputs or hybrids
   */
  systemType: string;
  /**
   * Value to display at the bottom of the circle (main value).
   */
  bottomValue?: number;
  /**
   * The unit used for top and bottom values.
   */
  unit?: Unit;
  /**
   * Number of systems for the given circle type. If not set, it is not displayed.
   */
  systemCount?: number;
  /**
   * System. If not set, it is not displayed.
   */
  title?: string;
  /**
   * Color of the rotating spinner around the circle. As a hex string.
   * If undefined, the spinner is not displayed.
   */
  spinnerColor?: string;
  /**
   * Disable spinner without changing color
   */
  disableSpinner?: boolean;
  /**
   * The error/warning mode of the circle's border.
   */
  errorWarningMode?: ErrorWarningMode;
  /**
   * Whether the bottom value should be highlighted.
   */
  highlightBottomValue?: boolean;
  /**
   * Used to let know that opposite dynamic bottomValue active
   */
  bottomDynamicValue?: boolean;

  /**
   * Alternative value to take into account for line movement,
   * if the bottomValue for a system is not set.
   */
  alternativeValue?: number;

  /**
   * Hide value display
   */
  hideValue?: boolean;

  /**
   * Position in the circle grid
   */
  position: CirclePosition;
}

/**
 * Display data for a placeholder circle. Can be used for battery/grid to keep the flex
 * alignment of the middle row correct.
 */
export class PlaceholderEnergyCircleDisplayData implements EnergyCircleDisplayData {
  id = '';
  centerContent = { type: 'icon', value: '' } as IconContent;
  bottomValue = undefined;
  systemCount = 0;
  title = '';
  position = CirclePosition.top;
  spinnerColor = '';
  errorWarningMode = ErrorWarningMode.none;
  highlightBottomValue = false;
}

export class PlaceholderIOEnergyCircleDisplayData implements IOEnergyCircleDisplayData {
  id = '';
  systemType = 'hybrids';
  centerContent = { type: 'icon', value: '' } as IconContent;
  bottomValue = undefined;
  systemCount = 0;
  title = '';
  spinnerColor = '';
  position = CirclePosition.top;
  errorWarningMode = ErrorWarningMode.none;
  highlightBottomValue = false;
}
