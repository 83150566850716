import { IDevice } from '@/types/devices.types';

export const SYSTEMS_WHERE_SETTINGS_WINDOW_ACTIVE = ['EMSV2', 'EMS', 'SetpointOptimizer'];
export const SYSTEMS_WHERE_EXTERNAL_ENERGY_MEASUREMENTS_WORKS = ['charge_station', 'electric_heating', 'heating_pump', 'big_consumer', 'battery', 'pv', 'generator', 'chp'];
export const EXTERNAL_ENERGY_MEASUREMENTS = ['power', 'energy_counter'];
export const EXTERNAL_ENERGY_MEASUREMENTS_FOR_HYBRID_SYSTEMS = ['power', 'energy_counter', 'reverse_energy_counter'];

export const externalEnergyMeasurementsSelected = (
  { system, systemInstance, device }: { system: string; systemInstance: string; device: IDevice },
) => {
  const isSystemWithExternalMeasurements = SYSTEMS_WHERE_EXTERNAL_ENERGY_MEASUREMENTS_WORKS.includes(system);
  if (!isSystemWithExternalMeasurements) return false;
  return device.data.meta.controllerMappings[system].components?.[systemInstance]?.external_energy_measurement;
};
