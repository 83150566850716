
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { isNil, cloneDeep } from 'lodash';
import { Validation } from '@/ui/mixins/validation';
import BasicControl, { SendingState } from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';
import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { calculateMinPowerPercent } from '@/utils/utilsFunctions';
import { IDevice } from '@/types/devices.types';
import { IChargeStationMinPowerSelectedOption } from '@/types/common.types';
import { IProject } from '@/types/project.types';

export enum ChargeModeOptionsKeys {
  full = 'full',
  sun = 'sun',
  minimum = 'minimum',
}

@Component({
  components: { FeedbackProvider },
})
export default class ChargeStationMinPowerSelection extends mixins(BasicControl, Validation) {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() label!: string;
  @Prop() inputUnit?: string;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() system!: EnergyCircleType;
  @Prop() systemInstance!: string;
  @Prop() inputMin?: number;
  @Prop() inputMax?: number;
  @Getter('projects/project') project!: IProject;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;

  timer?: NodeJS.Timeout;

  selectedKey: ChargeModeOptionsKeys | null = null;
  inputValue: number | null = null;

  value: number | null = null;

  get state() {
    return this.measurements.get(this.systemInstanceData.min_power) as number;
  }

  get dropDownInputSelectOptions(): IChargeStationMinPowerSelectedOption[] {
    return [
      { key: ChargeModeOptionsKeys.full, value: 100, text: this.$t('mlModel.EMS.systems.charge_station.settings.fullCharge') },
      { key: ChargeModeOptionsKeys.sun, value: 0, text: this.$t('mlModel.EMS.systems.charge_station.settings.sunCharge') },
      {
        key: ChargeModeOptionsKeys.minimum,
        value: 0,
        text: this.$t('mlModel.EMS.systems.charge_station.settings.minimumCharge'),
        component: 'VTextField',
      },
    ];
  }

  get inputRules() {
    const min = typeof this.inputMin === 'number' ? this.fieldMoreThan(this.inputMin) : true;
    const max = typeof this.inputMax === 'number' ? this.fieldLessThan(this.inputMax) : true;
    return [min, max];
  }
  get buttonDisabled() {
    const isInputValueNumber = typeof this.inputValue === 'number';
    if (!isInputValueNumber) return true;
    const checkMin = typeof this.inputMin !== 'number' || this.inputMin <= (this.inputValue as number);
    const checkMax = typeof this.inputMax !== 'number' || this.inputMax >= (this.inputValue as number);
    return !(checkMin && checkMax);
  }

  get systemInstanceData() {
    return this.deviceData.data.meta.controllerMappings?.[this.system].components?.[this.systemInstance];
  }

  get selectedOption() {
    return this.dropDownInputSelectOptions
      .find((opt: IChargeStationMinPowerSelectedOption) => opt.key === this.selectedKey);
  }
  get inputFieldVisible() {
    return !!this.selectedOption?.component;
  }

  get maxPowerVariable() {
    return this.systemInstanceData?.max_power;
  }
  get maxPowerValue(): number {
    return Number(this.measurements.get(this.maxPowerVariable));
  }

  // if this.value was successfully set to this.state,
  // show notification about successful sending + store selected option data in EMS object
  @Watch('state')
  async onStateChange(val: number) {
    // check if we got correct state update after sending
    if (this.sendingState === SendingState.Sending && val === this.value) {
      // successful send
      this.onSendSuccess();
      await this.sendHTTP({
        minimum_power_selection: this.selectedKey,
        current: this.inputValue,
      });
      clearTimeout(this.timer);
    }
  }

  async sendMQTT() {
    this.onSendStart();
    if (this.project.id) {
      await this.fetchMeasurements(this.project.id);
    }
    const currentValue = this.measurements.get(this.systemInstanceData.min_power);
    if (currentValue === this.value) {
      this.onSendSuccess();
      return;
    }
    this.send([{ v: this.value as number, n: this.systemInstanceData.slider_min_power, u: '' }]);

    // if after 30 seconds this.state not changed
    // show notification about error sending + reset value to previous state value
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.value = this.state;
      // this.selectedKey = stored selected key
      this.onSendError();
    }, 30000);
  }
  async sendHTTP(value: any) {
    const copy = cloneDeep(this.deviceData);
    copy.data.meta.controllerMappings[this.system].components[this.systemInstance] = {
      ...copy.data.meta.controllerMappings[this.system].components[this.systemInstance],
      ...value,
    };
    this.updateDevice({ device: copy, skipReport: false });
  }

  // Send option value only when there are no additional input components required to set the value
  async onSelectChange(option: IChargeStationMinPowerSelectedOption) {
    const hasComponent = !!option.component;
    // this.inputValue = null;
    this.selectedKey = option.key;
    // if selected option not has special field to set value, sends mqtt records
    if (!hasComponent) {
      this.value = Number(option.value);
      await this.sendMQTT();
    } else {
      // if min power selected, set input value to current value saved in the EMS object or set to 0
      if (option.key === 'minimum') {
        this.inputValue = this.deviceData.data.meta.controllerMappings[this.system].components[this.systemInstance].current ?? option.value;
      }
    }
  }

  async onInputClick() {
    if (!isNil(this.inputValue)) {
      this.value = calculateMinPowerPercent(Number(this.inputValue), this.maxPowerValue);
      if (this.value === this.state) {
        await this.sendHTTP({
          minimum_power_selection: this.selectedKey,
          current: this.inputValue,
        });
      } else {
        await this.sendMQTT();
      }
    }
  }

  created() {
    if (!isNil(this.systemInstanceData?.minimum_power_selection)) {
      this.selectedKey = this.systemInstanceData.minimum_power_selection;
    }
    if (!isNil(this.systemInstanceData?.current)) {
      this.inputValue = this.systemInstanceData.current;
    }
  }
}
