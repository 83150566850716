import _ from 'lodash';
import store from '@/store';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import { IMappedIds } from '@/types/common.types';
import { IMember, IMemberMetaMapElement } from '@/types/members.types';
import { getDefaultRulesObject } from './utilsFunctions';

const autoRuleIdList: any = [];
export async function sortRoom(originalElement: any, newElement: any, mappings: any): Promise<IRoom> {
  if (
    Object.keys(mappings[originalElement.id]).length !== 0 &&
    newElement.meta.devicesPositions !== undefined
  ) {
    newElement.meta.devicesPositions.forEach((position: any, index: any) => {
      newElement.meta.devicesPositions[index].i = mappings[originalElement.id].devices[position.i];
    });
    newElement.id = mappings[originalElement.id].room;
    const payload: any = { room: newElement };
    await store.dispatch('rooms/updateRoom', payload);
    return newElement;
  }
  return newElement;
}

export async function updateMemberOrders(member: IMember, project: IProject, newProject: IProject, mappings: any) {
  if (member.meta.area_positions === undefined) return true;
  const roomOrder = member.meta.area_positions;
  const deviceOrder = member.meta.device_positions;
  const favoriteOrder = member.meta.favorite_positions;
  const favoriteDevices = member.meta.favorite_devices;
  const newRoomOrder: any = [];
  const newFavoriteOrder: any = [];
  const newFavoriteDevices: any = [];
  const newDeviceOrder: any = {};

  const allDevices = Object.values(mappings)
    .flatMap((obj: any) => Object.entries(obj.devices).map(([key, value]) => ({ [key]: value }))).reduce((acc, curr) => Object.assign(acc, curr), {});

  // Update params for member room order
  if ((roomOrder !== undefined) && ((Object.keys(roomOrder).length > 0))) {
    Object.entries(roomOrder).forEach((room: any) => {
      if (Object.keys(mappings).includes(room[1].i)) {
        const newRoom = _.cloneDeep(room);
        newRoom[1].i = mappings[room[1].i].room;
        newRoomOrder.push(newRoom[1]);
      }
    });
  }

  // Update params for member device order
  if (deviceOrder !== undefined) {
    if (Object.keys(deviceOrder).length > 0) {
      Object.entries(deviceOrder).forEach((room: any) => {
        if (Object.keys(mappings).includes(room[0])) {
          const newRoom = _.cloneDeep(room);
          const newDevicePosition: any = [];
          newRoom[1].forEach((device: any) => {
            const newPosition = _.cloneDeep(device);
            newPosition.i = mappings[room[0]].devices[device.i];
            newDevicePosition.push(newPosition);
          });
          newDeviceOrder[mappings[room[0]].room] = newDevicePosition;
        }
      });
    }
  }
  // Update favorite devices positions
  const favoriteOrderIDs = (favoriteOrder ?? []).map((favorite: any) => favorite.i);
  (favoriteDevices ?? []).forEach((device: any) => {
    if (device in allDevices) {
      if (favoriteOrderIDs.includes(device)) {
        const dev = favoriteOrder.find((favorite: any) => favorite.i === device);
        const newPosition: any = _.cloneDeep(dev);
        newPosition.i = allDevices[device];
        newFavoriteOrder.push(newPosition);
        newFavoriteDevices.push(newPosition.i);
      } else {
        newFavoriteDevices.push(allDevices[device]);
      }
    }
  });

  member.meta.area_positions = newRoomOrder;
  member.meta.device_positions = newDeviceOrder;
  member.meta.favorite_positions = newFavoriteOrder;
  member.meta.favorite_devices = newFavoriteDevices;

  function gridSortCallback(a: any, b: any) {
    if (a.y === b.y) {
      // If the y values are the same, sort by x
      return a.x - b.x;
    }
    // Otherwise, sort by y
    return a.y - b.y;
  }

  // App Order
  member.meta.app_area_order = [...newRoomOrder].sort(gridSortCallback).map((room: any) => room.i);
  const newAppDeviceOrder: any = { ...newDeviceOrder };
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in newAppDeviceOrder) {
    newAppDeviceOrder[key] = newAppDeviceOrder[key].sort(gridSortCallback).map((item: IMemberMetaMapElement) => item.i);
  }
  member.meta.app_area_device_order = newAppDeviceOrder;

  const positionStatus = await store.dispatch('members/updateMemberMeta', { project_id: newProject.id, member });

  return positionStatus;
}

export async function copyDevices(devicesList: IDevice[], resultRoom: IRoom, resultNewProject: IProject): Promise<IMappedIds> {
  const localResultObject: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const device of devicesList) {
    if (Object.keys(device).includes('project_id')) {
      const mpc: any = {
        collection_id: resultRoom.id,
        data: device.data,
        name: device.name,
        project_id: resultNewProject.id,
      };
      if (mpc.data.meta.controllerMappings.errorWarning && mpc.data.meta.controllerMappings.errorWarning !== '') {
        // put id's of current rules inside autoRuleIdList to make the rules that are already duplicated
        autoRuleIdList.push(device.data.meta.errorRule);
        autoRuleIdList.push(device.data.meta.warningRule);
        // ems general rules
        const generalRulesMPC: any = getDefaultRulesObject(mpc.name, mpc.data.meta.controllerMappings.errorWarning, 'Device');
        // create new rules
        const res: any = await store.dispatch('rules/addRules', {
          project_id: resultNewProject.id,
          rulesList: generalRulesMPC,
        });

        mpc.data.meta.warningRule = res[0].id;
        mpc.data.meta.errorRule = res[1].id;
      }
      const newMPC: any = await store.dispatch('mpc/createMCCInstance', mpc);
      if (newMPC === null) {
        localResultObject[device.id] = undefined;
      } else {
        if (device.favorite) {
          await store.dispatch('mpc/addMPCToFavorites', newMPC.id);
        }
        localResultObject[device.id] = newMPC.id;
      }
    } else {
      const newDeviceObject: any = {
        collection_id: resultRoom.id,
        data: device.data,
        name: device.name,
      };
      const isChart = device.data.type !== 'chart';
      const isDeviceHasErrorWarning = device.data?.mappings?.ShowEvent_errorWarningState;
      if (isChart && isDeviceHasErrorWarning) {
        // put id's of current rules inside autoRuleIdList to make the rules that are already duplicated
        autoRuleIdList.push(device.data.meta.errorRule);
        autoRuleIdList.push(device.data.meta.warningRule);
        // add rules to newDeviceObjects
        const rulesList: any = getDefaultRulesObject(device.name, device.data.mappings.ShowEvent_errorWarningState, 'Device');
        const res = await store.dispatch('rules/addRules', {
          project_id: resultNewProject.id,
          rulesList,
        });
        newDeviceObject.data.meta.warningRule = res[0].id;
        newDeviceObject.data.meta.errorRule = res[1].id;
      }
      const newDevice = await store.dispatch('devices/createDevice', newDeviceObject);
      if (newDevice === null) {
        localResultObject[device.id] = undefined;
      } else {
        if (device.favorite) {
          await store.dispatch('devices/addDeviceToFavorites', newDevice.id);
        }
        localResultObject[device.id] = newDevice.id;
      }
    }
  }

  return localResultObject;
}

export async function copyRoomWithDevices(roomItem: any, resultNewProject: any, mappings: any) {
  const newRoomObject = _.cloneDeep(roomItem);
  const devicesToCreate = await store.dispatch('devices/getAllDevicesByCollectionID', roomItem.id);
  const mpcToCreate = await store.dispatch('mpc/getMpcList', roomItem.id);

  delete newRoomObject.id;
  delete newRoomObject.created_at;

  // contains resultRoom in order to be able to add deivces to the right room
  const resultRoom = await store.dispatch('rooms/copyRoom', {
    room: newRoomObject,
    cover: newRoomObject.meta.cover,
  });

  const fullDeviceList = [...devicesToCreate, ...mpcToCreate].sort((a: any, b: any) => {
    const first = new Date(a.created_at).getTime();
    const second = new Date(b.created_at).getTime();
    if (first > second) return 1;
    if (first < second) return -1;
    return 0;
  });

  const resultDevices = await copyDevices(fullDeviceList, resultRoom, resultNewProject);

  mappings[roomItem.id] = {};
  mappings[roomItem.id].devices = { ...resultDevices };
  mappings[roomItem.id].room = resultRoom.id;

  if (resultRoom?.error !== undefined) {
    resultRoom.name = roomItem[1].name;
  }

  return { resultRoom, resultDevices };
}

export async function duplicateSingleRoom(roomData: any, project: IProject, member: IMember) {
  const room: any = roomData;
  room.name = `${room.name} (copy)`;
  const mappedDevices: any = {};
  const oldMember = { ...member };
  const originalProject = { ...project };
  const roomMeta: any = {};
  /* copies room with devices */
  if (room !== undefined) {
    await copyRoomWithDevices(room, originalProject, mappedDevices);

    // Sorts new room the same way as the old one
    const newElement = _.cloneDeep(room);
    roomMeta[newElement.room] = await sortRoom(
      room,
      newElement,
      mappedDevices,
    );

    // Update members meta
    await updateMemberOrders(oldMember, originalProject, originalProject, mappedDevices);
  }
}
