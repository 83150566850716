// Utils for version management inside project
import { IProject } from '@/types/project.types';

/**
 * @param project project object as IProject
 * @returns date of last update of plc or started_at if plcLastUpdate is not defined
 */
export function plcVersionDate(project: IProject) {
  const { started_at } = project;
  const { plcLastUpdate } = project.meta;
  let date;

  // If plcLastUpdate is not defined, return started_at
  if (!plcLastUpdate) {
    date = new Date(started_at);
  } else {
    date = new Date(plcLastUpdate);
  }

  return date;
}

// contains all ChargeStations that are limited by version
// (Used in InstallationWizard)
export const versionLimitedChargeStations = {
  weidmueller11kWInternal: new Date('2023-06-08'),
  weidmueller22kWInternal: new Date('2023-06-08'),
};

// limit display of project offline symbol
// Used in (AppBar)
export const displayProjectOfflineStatus = new Date('2024-03-14');

// limit display of installation wizard info popup
// Used in (InstallationWizardInfoPopup)
export const showInstallationWizardInfoPopup = new Date('2023-10-06');

// date on which legionella protection was added
export const legionellaProtectionDate = new Date('2024-03-20');

// limit of charge stations went from 4 to 10 on this date
// Used in (wizardLimits)
export const newChargeStationLimitDate = new Date('2024-05-22');

// limit display of charging station v2 feature
// Used in:
// Installation Wizard (ChargingStationSettings, Components Page)
// Tenant (Electricity)
export const chargingStationV2Feature = new Date('2024-05-22');

// Tenant Only and Tenant with Hybrid System will update EMS / Energy View
// and will disable some parts inside the installation wizard
// Used in Installation Wizard -> Components Page, Pilot Page
// Used in Tenant -> Final Page and index file of tenant
// Used in EMS Settings -> for battery enable button visibility
export const tenantUpdateWagoCounters = new Date('2024-05-22');

// Activates mapping of ac batteries in the tenant
// Used in Electricity Counter Page
export const tenantAcBatteryUpdate = new Date('2024-05-22');
