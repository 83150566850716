
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import Wizard from '@/ui/components/wizards/baseComponents/Wizard.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { IAppState } from '@/store/modules/app/types';
import { IProjectsState } from '@/store/modules/projects/types';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IWizardLoadingState, IWizardPage } from '@/types/wizards/wizard.general.types';
import { plcVersionDate, tenantUpdateWagoCounters } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    CircleSpinner,
    Wizard,
  },
})
export default class TenantWizard extends Vue {
  @State('app') appState!: IAppState;
  @State('projects') projectsState!: IProjectsState;
  @State('tenantWizard') wizardState!: ITenantWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('tenantWizard/currentStep') activeStep!: number;
  @Getter('tenantWizard/wizardPages') wizardPages!: IWizardPage[];
  @Getter('tenantWizard/defaultWizardStatePages') defaultWizardStatePages!: IWizardPage[];
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('tenantWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('tenantWizard/loadingState') loadingState!: IWizardLoadingState;
  @Getter('tenantWizard/tenantWithoutLynusBattery') tenantWithoutLynusBattery!: boolean;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Mutation('tenantWizard/setCurrentStep') setCurrentStep!: (payload: number) => void;
  @Mutation('tenantWizard/setCurrentPage') setCurrentPage!: (payload: number) => void;
  @Mutation('tenantWizard/reset') resetWizard!: () => Promise<void>;
  @Mutation('tenantWizard/setWasTenantDoneState') setWasTenantDoneState!: (data: boolean) => void;
  @Mutation('tenantWizard/setPages') setPages!: (data: IWizardPage[]) => void;
  @Action('tenantWizard/loadTenant') loadTenant!: (project_id: string) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('members/fetchMember') fetchMember!: (payload: { memberId: string; projectId: string }) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;

  tenantLoad = false;

  get currentPage() {
    return this.wizardState.currentPageIndex;
  }

  get user() {
    return this.appState.user;
  }

  get pages() {
    return this.wizardPages;
  }

  get wasInstallationWizardCompleted() {
    return this.project.meta.wasInstallationWizardCompleted ?? false;
  }

  mounted() {
    // update pages in store
    let pages = this.defaultWizardStatePages;
    // if user is not super admin, remove AutoMapping page
    if (!this.user.super_admin) {
      pages = pages.filter((page) => page.title !== 'AutoMapping');
    }
    if (plcVersionDate(this.project).getTime() < tenantUpdateWagoCounters.getTime()) {
      // if project is older than tenantUpdateWagoCounters update, remove SystemInformation page
      pages = pages.filter((page) => page.title !== 'SystemInformation');
    }
    this.setPages(pages);
  }

  beforeDestroy() {
    this.setCurrentStep(1);
    this.setCurrentPage(0);
  }

  handleRouteChange() {
    this.resetWizard();
  }

  async beforeRouteLeave(to: any, from: any, next: any) {
    if (this.activeStep === 6) {
      await this.resetWizard();
      next();
    }
    await (this.$refs.wizard as any).confirmAction(to, next);
  }
}
