
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import IconLights from '@/ui/components/components/IconLights.vue';
import { envLogoURLDark, envLogoURLLight } from '@/utils/env';
import {
  INavigationState,
} from '@/store/modules/navigation/types';
import { IAppState } from '@/store/modules/app/types';
import { IMemberState } from '@/store/modules/members/types';
import { IProjectNavigation, IAppNavigation } from '@/types/navigation.types';
import { CanAccessCheck } from '@/types/members.types';
import { BatteryType } from '../wizards/installationWizard/wizardSettings/systemTypes';

/**
 * Component that represent navigation drawer
 */
@Component({
  components: {
    IconLights,
  },
})
export default class AppDrawer extends Vue {
  @State('navigation') navigationState!: INavigationState;
  @State('app') appState!: IAppState;
  @State('members') membersState!: IMemberState;
  @Getter('app/getIsAllAccepted') getIsAllAccepted!: boolean;
  @Getter('navigation/projectNavigation') projectNavigation!: IProjectNavigation[];
  @Getter('navigation/partnerNavigation') partnerNavigation!: any[];
  @Getter('navigation/appNavigationFiltered') appNavigationFiltered!: IAppNavigation[];
  @Getter('projects/batterySystemType') batterySystemType!: BatteryType;
  @Mutation('navigation/handleDrawer') handleDrawer!: (payload: boolean) => void;
  @Mutation('navigation/handleMiniVariant') handleMiniVariant!: (payload: boolean) => void;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;

  get isBackToHomeButtonVisible() {
    return this.currentNavigationType !== 'appNavigation';
  }

  get currentNavigationType() {
    return (this.$route.meta as any).navigation;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get partner() {
    const partner = this.appState.user?.partner ?? '';
    return partner !== '';
  }

  /**
   * Returns different navigation lists
   * @return {array} navigation list
   */
  get navigations(): any {
    return { appNavigation: this.appNavigationFiltered, projectNavigation: this.projectNavigation, partnerNavigation: this.partnerNavigation };
  }
  get currentNavigation() {
    const fullNavigationList = this.currentNavigationType ? this.navigations[this.currentNavigationType] : [];
    // filter out all super admin only navigation items if user is not super admin
    let navigationList = this.superAdmin
      ? fullNavigationList
      : fullNavigationList.filter((item: any) => !item?.superAdminOnly);
    if (!this.superAdmin) {
      // filter out all partner only navigation items if user is not partner
      // if the user is a super admin this filter is not needed
      navigationList = this.partner
        ? navigationList
        : navigationList.filter((item: any) => !item?.partnerOnly);
    }
    // only apply filter if the current navigation is project navigation to avoid errors
    if (this.currentNavigationType === 'projectNavigation') {
      if (this.batterySystemType === BatteryType.MobileBatteryDeye) {
        // filter some navigation items for mobile battery deye as they are not needed
        return navigationList
          .filter((item: any) => this.canMemberAccess('ProjectMenuEntries', item.name) && item.filterForMobileBatteryDeye !== true);
      }
    }
    return navigationList
      .filter((item: any) => this.canMemberAccess('ProjectMenuEntries', item.name));
  }

  /**
   * Returns home route
   * @return {string} home route
   */
  get homeNavigationRoute() {
    if (this.currentNavigationType === 'partnerNavigation') {
      // set partner workspace as home route if partner navigation is active
      return '/partner-workspace';
    }
    // set default home route for every other navigation type
    return '/';
  }

  get isMini() {
    return this.navigationState.isMini;
  }
  set isMini(value: boolean) {
    this.handleMiniVariant(value);
  }

  get drawer() {
    return this.navigationState.drawer;
  }
  set drawer(value: boolean) {
    this.handleDrawer(value);
  }

  /**
   * Defines icon size
   * @return {number} size
   */
  get iconSizes(): number {
    return { 'xs': 16, 'sm': 16, 'md': 20, 'lg': 22, 'xl': 22 }[this.$vuetify.breakpoint.name];
  }

  handleLogoClick() {
    this.$router.push(this.homeNavigationRoute);
  }

  getLogo() {
    const isLight = (this.$vuetify.theme as any).isDark;
    return isLight ? envLogoURLLight : envLogoURLDark;
  }

  /**
   * Defines what icon lib to use for navigation item
   * @param {string} icon icon name
   * @return {boolean} if true use Font-Awesome icon lib
   */
  isCustomLib(icon: string): boolean {
    const reg = /^fa-|mdi-/g;
    if (!icon.length) return false;
    return reg.test(icon);
  }
}
