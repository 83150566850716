
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { kiloWattHourUnit, kilogramUnit } from '@/utils/unit/unitConstants';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import EnergyComponent from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  EnergyCircleType,
  isHybrid,
  energyCircleIconForType,
  getCirclePositionForType,
  allEnergyCircleTypes,
} from '@/types/energyVisualisation/EnergyCircleType';
import EnergyVisualisation from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import {
  getDateBoundsByPeriod,
  periodConfigurations,
  Periods,
} from '@/ui/components/devices/charts/charts/ChartUtils';
import { changeDate, countDecimalPlaces, formatDateForPeriod, roundNumber } from '@/utils/utilsFunctions';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  energyConsumerColors,
  energyHybridColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import {
  getDefaultArrowDirection,
  getDefaultLineColors,
  getDefaultSpinnerColor,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import CalendarNavigation from '@/ui/components/devices/charts/charts/CalendarNavigation.vue';
import IconValueFieldWithTooltip from '@/ui/components/components/IconValueFieldWithTooltip.vue';
import { IDevice } from '@/types/devices.types';
import { IProject } from '@/types/project.types';
import { EnergyDataByPeriodResponse } from '@/types/energyVisualisation/energyVisualisation.types';
import EnergyFlowViewSystemDialog from './EnergyFlowViewSystemDialog.vue';

@Component({
  components: {
    CalendarNavigation,
    CircleSpinner,
    EnergyVisualisation,
    EnergyFlowViewSystemDialog,
    IconValueFieldWithTooltip,
  },
  methods: { roundNumber },
})
export default class EnergyFlowView extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: false }) displayEnergyFlowHouseInfo!: boolean;
  @Getter('projects/projectStartedAtNumber') startedAt!: number;
  @Getter('projects/project') currentProject!: IProject;
  @Getter('projects/projectCurrency') projectCurrency!: '€' | 'CHF' | undefined;
  @Action('mpc/fetchDataByPeriodMpc') fetchDataByPeriodMpc!: ({
    id,
    start,
    end,
    period,
  }: {
    id: string;
    start: number;
    end: number;
    period: string;
  }) => Promise<EnergyDataByPeriodResponse>;
  @Action('devices/fetchDataByPeriod') fetchDataByPeriod!: ({
    id,
    start,
    end,
    period,
  }: {
    id: string;
    start: number;
    end: number;
    period: string;
  }) => Promise<EnergyDataByPeriodResponse>;

  loader = true;
  energyDataByPeriodResponse: EnergyDataByPeriodResponse | null = null;

  // ========================== systems dialogs ==========================
  showDialog = false;
  openedSystem?: EnergyCircleType;
  @Provide('onCircleClick') onCircleClick = this.onCircleClickCallback;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;
  hybridHighlightBottomValue = false;
  hybridArrowDirection = ArrowDirection.toCenter;
  hybridLineColors = energyHybridColors;
  onLineAnimationEndCallback() {
    this.hybridHighlightBottomValue = !this.hybridHighlightBottomValue;
    this.hybridArrowDirection =
      this.hybridArrowDirection === ArrowDirection.toCenter
        ? ArrowDirection.awayFromCenter
        : ArrowDirection.toCenter;
    this.hybridLineColors =
      this.hybridLineColors === energyHybridColors ? energyConsumerColors : energyHybridColors;
  }

  onCircleClickCallback(circleData: EnergyCircleDisplayData) {
    this.openedSystem = circleData.id as EnergyCircleType;
    this.showDialog = true;
  }

  autoReloadInterval: any = null;

  // ========================== calendar ==========================
  currentPeriod: string = Periods.DAY;
  currentCalendarStartDate: number | null = null;
  disableNavButton = false;
  navigationItemsToExclude = ['live', 'hour']

  // ========================== bottom, top info ==========================
  displayDate: string | null = null;
  changeCurrency = false;
  currentExchangeRate = 1.02;
  co2ReductionUnit = kilogramUnit;
  totalConsumptionUnit = kiloWattHourUnit;
  get costSavings() {
    return roundNumber(this.energyDataByPeriodResponse?.calculations.cost_saved as number) || 0;
  }
  get emissionReduction() {
    return roundNumber(this.energyDataByPeriodResponse?.calculations.co2_saved as number) || 0;
  }
  get autarchyScore() {
    return roundNumber(this.energyDataByPeriodResponse?.calculations.autarchy_score as number) || 0;
  }
  get calculatedAutarkiegrad() {
    return `${this.$t('mlModel.EMS.charts.autarchy')} \n${this.autarchyScore}%`;
  }

  get isProjectCurrencyUndefined() {
    return this.currentProject.meta?.projectCurrency === undefined;
  }

  get isMpc() {
    const mpcDevices: any = ['MpcEnergyView', 'EMS'];
    return mpcDevices.includes(this.deviceData.data.type);
  }

  get totalConsumption(): number | undefined {
    return this.energyDataByPeriodResponse?.calculations.total_consumption;
  }

  get shownSystems() {
    return this.filterPresentSystems(this.deviceData, allEnergyCircleTypes);
  }

  get circlesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType) => {
      const position = getCirclePositionForType(systemType);

      let bottomValue;
      let highlightBottomValue;
      let spinnerColor;
      if (isHybrid(systemType)) {
        // battery/grid
        bottomValue = this.hybridHighlightBottomValue
          ? this.defineHybridSystemsBottomValue(systemType, 'from_production')
          : this.defineHybridSystemsBottomValue(systemType, 'to_consumer');
        highlightBottomValue = this.hybridHighlightBottomValue;
        spinnerColor = this.hybridLineColors.colorAtCircle;
      } else if (systemType === EnergyCircleType.house && this.displayEnergyFlowHouseInfo === false) {
        // but only use calculated value if we dont have a hybrid system
        // house
        bottomValue = this.defineHouseBottomValue();
      } else {
        // all other systems
        bottomValue = this.defineSystemsBottomValue(systemType);
      }

      return {
        id: systemType,
        bottomValue,
        centerContent: {
          type: 'icon',
          value: energyCircleIconForType(systemType),
        },
        position,
        systemCount: this.getSystemCount(this.deviceData, systemType),
        spinnerColor: spinnerColor ?? getDefaultSpinnerColor(systemType, bottomValue),
        unit: kiloWattHourUnit,
        highlightBottomValue,
      } as EnergyCircleDisplayData;
    });
  }

  get linesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType, index: number) => {
      const displayData = this.circlesDisplayData[index];

      if (isHybrid(systemType)) {
        return {
          id: displayData.id,
          arrowDirection: this.hybridArrowDirection,
          colors: this.hybridLineColors,
          oneArrowOnly: true,
        } as Partial<EnergyLineDisplayData>;
      }

      return {
        id: displayData.id,
        arrowDirection: getDefaultArrowDirection(systemType, displayData),
        colors: getDefaultLineColors(systemType, displayData),
      } as Partial<EnergyLineDisplayData>;
    });
  }

  setDisplayDate({ start, end }: { start: number; end: number }) {
    this.currentCalendarStartDate = start;
    this.displayDate = formatDateForPeriod(
      this.currentPeriod,
      this.currentPeriod === 'week' ? ([start * 1000, end * 1000] as any) : ([start * 1000] as any),
    );
  }

  fetchEnergyDataFunction = this.isMpc ? this.fetchDataByPeriodMpc : this.fetchDataByPeriod;
  async fetchEnergyData({ start, end }: { start: number; end: number }) {
    this.disableNavButton = true;
    this.loader = true;
    try {
      this.energyDataByPeriodResponse = await this.fetchEnergyDataFunction({
        id: this.deviceData.id.replaceAll('_', '-'),
        start,
        end,
        period: this.currentPeriod,
      });
    } catch (e) {
      this.energyDataByPeriodResponse = null;
      console.error(e);
    } finally {
      this.disableNavButton = false;
      this.loader = false;
    }
    clearInterval(this.autoReloadInterval);
    this.autoReloadInterval = setInterval(() => {
      this.fetchEnergyData({ start, end });
    }, 3600000);
  }

  defineHybridSystemsBottomValue(
    systemType: EnergyCircleType,
    key: 'to_consumer' | 'from_production',
  ) {
    if (!this.energyDataByPeriodResponse) return undefined;

    const systemInstances = this.energyDataByPeriodResponse.energies[systemType];
    const systemInstancesFilteredByKey = Object.entries(systemInstances)
      .filter(([k, v]) => new RegExp(key).test(k));
    return systemInstancesFilteredByKey.length
      ? systemInstancesFilteredByKey.reduce((acc: number, [k, v]: any) => acc + v, 0)
      : undefined;
  }
  defineSystemsBottomValue(systemType: EnergyCircleType) {
    if (!this.energyDataByPeriodResponse) return undefined;

    const systemInstances = this.energyDataByPeriodResponse.energies[systemType];
    return systemInstances
      ? Object.values(systemInstances).reduce((acc: number, el: number) => acc + el, 0)
      : undefined;
  }

  defineHouseBottomValue() {
    const externalMeasurementsSystemsValueList = this.externalMeasurementsSystems.map((systemType: EnergyCircleType) => {
      return this.getSumOfExternalMeasurementsSystemInstancesValues(systemType, this.energyDataByPeriodResponse, this.deviceData) ?? 0;
    });
    const sumOfExternalMeasurementsSystemsValues = externalMeasurementsSystemsValueList.reduce((acc: number, val: number) => acc + val, 0);
    return this.getHouseValue(this.totalConsumption, sumOfExternalMeasurementsSystemsValues);
  }

  async handleNavigation(period: string) {
    this.currentPeriod = period;
    const { start, end } = periodConfigurations[this.currentPeriod].period();
    this.setDisplayDate({ start, end });
    await this.fetchEnergyData({ start, end });
  }

  async handleCalendar(data: any) {
    (this.$refs.calendarDialog as any).closeDialog();
    await this.loadCalendarRange(data);
  }
  startOfCreatedAtDate() {
    const createdAtDate = new Date(this.startedAt);
    switch (this.currentPeriod) {
      case 'day':
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate(),
        );
      case 'week':
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate() - createdAtDate.getDay(),
        );
      case 'month':
        return new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), 1);
      case 'year':
        return new Date(createdAtDate.getFullYear(), 0, 1);
      default:
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate(),
        );
    }
  }
  async handlePrevNextButtonCalendar(direction: string) {
    const date = changeDate(this.currentCalendarStartDate as number, direction, this.currentPeriod);
    const start = this.startOfCreatedAtDate().getTime() / 1000;
    const end = new Date().getTime() / 1000;
    if (date < end && date >= start) {
      await this.loadCalendarRange(date);
    }
  }
  async loadCalendarRange(date: number) {
    const now = Math.trunc(new Date().getTime() / 1000);
    const chartBounds = getDateBoundsByPeriod(date, this.currentPeriod);
    const { start, end } = chartBounds as { start: number; end: number; endChart: number };
    const maxEnd = end > now ? now : end;

    this.setDisplayDate({ start, end: maxEnd });
    await this.fetchEnergyData({ start, end: maxEnd });
  }
  beforeDestroy() {
    clearInterval(this.autoReloadInterval);
  }
  mounted() {
    if (this.projectCurrency !== undefined) {
      this.changeCurrency = this.projectCurrency === 'CHF';
    }
  }
}
