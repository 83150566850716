import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IMeasurements } from '@/types/measurements.types';
import {
  allEnergyCircleTypes,
  EnergyCircleType,
  isConsumer,
  systemsWithAlternativeValues,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  ArrowDirection,
  EnergyLineColors,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import {
  energyConsumerColors,
  energyGreyColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import {
  EnergyDataExtractor,
} from '@/types/energyVisualisation/EnergyDataExtractor';
import { IDevice } from '@/types/devices.types';
import { isNil } from 'lodash';
import Unit from '@/utils/unit/unit';
import { IGetSumOfSystemInstancesValuesParams } from '@/types/common.types';
import { EnergyDataByPeriodResponse } from '@/types/energyVisualisation/energyVisualisation.types';

/**
 * Helper for extracting data from EnergyView, EMS.
 */
@Component
export default class EnergyComponent extends Vue implements EnergyDataExtractor<EnergyCircleType> {
  @Prop({ default: null }) deviceData!: IDevice;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  /**
   * Returns list of all present systems where can be enabled external measurements.
   */
  get externalMeasurementsSystems(): EnergyCircleType[] {
    return this.filterPresentExternalMeasurementsSystems(this.deviceData, allEnergyCircleTypes);
  }

  /**
   * Returns sum of all variables in systems where external measurements was enabled.
   */
  get sumOfAllEnabledExternalMeasurementsSystemsValues(): number {
    const sum = this.externalMeasurementsSystems.map((systemType: EnergyCircleType) => {
      const components = this.getSystemComponentsWithEnabledExternalMeasurements(this.deviceData, systemType);
      return this.getSumOfSystemInstancesValues({
        systemInstances: components,
        measurements: this.measurements,
      }) ?? 0;
    });
    return sum.reduce((acc: number, val: number) => acc + val, 0);
  }

  getSystems(device: any): Record<string, any> {
    return allEnergyCircleTypes.reduce((acc: any, type: EnergyCircleType) => {
      return { ...acc, [type]: device.data.meta.controllerMappings[type] };
    }, {});
  }

  getSystem(device: any, type: EnergyCircleType): Record<string, any> {
    return device.data.meta.controllerMappings[type];
  }

  getSystemComponents(device: any, type: EnergyCircleType): Record<string, any> {
    return device.data.meta.controllerMappings[type]?.components ?? {};
  }

  getSystemKeys(device: any, type: EnergyCircleType): string[] {
    if (!this.getSystemCount(device, type)) return [];
    return Object.keys(this.getSystemComponents(device, type));
  }

  getTitle(device: any, type: EnergyCircleType, index: number): string | undefined {
    return this.getVariableMappings(device, type, 'title')[index];
  }

  getSystemCount(device: any, type: EnergyCircleType): number {
    return device.data.meta.controllerMappings?.[type]?.count ?? 0;
  }

  isSystemPresent(device: any, type: EnergyCircleType): boolean {
    return this.getSystemCount(device, type) > 0;
  }

  filterPresentSystems(device: any, types: EnergyCircleType[]): EnergyCircleType[] {
    return types.filter((type) => this.getSystemCount(device, type) > 0);
  }

  filterPresentExternalMeasurementsSystems(device: any, types: EnergyCircleType[]): EnergyCircleType[] {
    const presentSystems = this.filterPresentSystems(device, types);
    return presentSystems.filter((type: EnergyCircleType) => type !== EnergyCircleType.house && isConsumer(type));
  }

  filterComponentsWithEnabledExternalMeasurements(components: Record<string, any>): Record<string, any> {
    const list = Object.entries(components).filter(([_, mappings]: [string, Record<string, any>]) => {
      return mappings.external_energy_measurement;
    });
    return list.reduce((
      acc: Record<string, Record<string, any>>, [instance, mappings]: [string, Record<string, any>],
    ) => {
      return { ...acc, [instance]: mappings };
    }, {});
  }

  getSystemComponentsWithEnabledExternalMeasurements(device: any, type: EnergyCircleType): Record<string, any> {
    const components = this.getSystemComponents(device, type);
    return this.filterComponentsWithEnabledExternalMeasurements(components);
  }

  filterComponentsWithEnabledExternalMeasurementsList(components: Record<string, any>): string[] {
    const list = Object.entries(components).filter(([_, mappings]: [string, Record<string, any>]) => {
      return mappings.external_energy_measurement;
    });
    return list.map(([instance, _]: [string, Record<string, any>]) => instance);
  }

  getSystemInstancesWithEnabledExternalMeasurements(device: any, type: EnergyCircleType): string[] {
    const components = this.getSystemComponents(device, type);
    return this.filterComponentsWithEnabledExternalMeasurementsList(components);
  }

  getVariableValue(device: any, type: EnergyCircleType, instance: string, variableToLookFor: string) {
    return device.data.meta.controllerMappings[type].components[instance][variableToLookFor];
  }

  getVariableMappings(
    device: any,
    type: EnergyCircleType,
    variableToLookFor = 'power',
  ): (string | undefined)[] {
    return this.getSystemCount(device, type) > 0
      ? Object.values(this.getSystemComponents(device, type)).map((e: any) => e[variableToLookFor])
      : [];
  }

  getBatterySocMappings(device: any): (string | undefined)[] {
    return this.getVariableMappings(device, EnergyCircleType.battery, 'soc');
  }

  getErrorWarningMappings(device: any, type: EnergyCircleType): (string | undefined)[] {
    return this.getVariableMappings(device, type, 'error');
  }

  getAlternativeArrowDirection(value?: number): ArrowDirection | undefined {
    return value !== null && value !== undefined && value > 0
      ? ArrowDirection.awayFromCenter
      : undefined;
  }

  getAlternativeLineColors(value?: number): EnergyLineColors {
    return value != null && value !== undefined && value > 0
      ? energyConsumerColors
      : energyGreyColors;
  }

  getAlternativeVariableMappings(device: any, type: EnergyCircleType): (string | undefined)[] {
    return systemsWithAlternativeValues.includes(type)
      ? this.getVariableMappings(device, type, 'target_power')
      : [];
  }

  /**
   * Returns the prediction data points for the given [type] of system
   * and [suffix]. The [suffix] specifies the type of variable, e.g.
   * "power", "energy", ... without "_". The underscore "_" is added
   * automatically.
   *
   * The resulting `Map` looks e.g. like this:
   * {
   *   "pv1": [[<timestamp>, <value>], ...],
   * }
   */
  predictionDataPoints(
    device: any,
    type: EnergyCircleType,
    suffix: string,
  ): Map<string, (number | undefined)[][]> {
    return new Map(
      this.getSystemKeys(device, type).map((k) => [
        k,
        device.data.meta.charts.prediction.data[type][`${k}_${suffix}`] ?? [],
      ]),
    );
  }

  /**
   * Calculate sum of system instances mappedVariable.
   * @param systemInstances
   * @param mappedVariable
   * @param measurements
   */
  getSumOfSystemInstancesValues(
    { systemInstances, mappedVariable = 'power', measurements }: IGetSumOfSystemInstancesValuesParams,
  ): number | undefined {
    const values = Object.values(systemInstances).map((systemInstanceMappings: Record<string, string>) => {
      const measurement = systemInstanceMappings[mappedVariable];
      return measurements[measurement];
    });
    if (values.every((value: any) => typeof value !== 'number')) return;

    return values.reduce((acc: number, value: any) => {
      const valueNumber = typeof value === 'number' ? value : 0;
      return acc + valueNumber;
    }, 0);
  }

  getAverageOfSystemInstancesValues(
    { systemInstances, mappedVariable = 'power', measurements }: IGetSumOfSystemInstancesValuesParams,
  ): number | undefined {
    const sum = this.getSumOfSystemInstancesValues({
      systemInstances, mappedVariable, measurements,
    });
    const count = Object.keys(systemInstances).length;

    return typeof sum === 'number' ? sum / count : undefined;
  }

  /**
   * Calculate sum of system instances values which present in energyDataByPeriodResponse.
   * @param systemType one of the systems keys: pv, generator...
   * @param energyDataByPeriodResponse
   * @param device EMS or Energy device
   */
  getSumOfExternalMeasurementsSystemInstancesValues(
    systemType: EnergyCircleType,
    energyDataByPeriodResponse: EnergyDataByPeriodResponse | null,
    device: any,
  ): number | undefined {
    if (!energyDataByPeriodResponse) return undefined;

    const instancesWithEnabledExternalMeasurements = this.getSystemKeys(device, systemType);
    const systemInstances = energyDataByPeriodResponse.energies[systemType];
    return systemInstances
      ? instancesWithEnabledExternalMeasurements.map((type: any) => systemInstances[type] ?? 0).reduce((acc: number, el: number) => acc + el, 0)
      : undefined;
  }

  getHouseValue(value: any, externalMeasurementsSum: number) {
    if (typeof value === 'number') {
      const calculatedValue = value - externalMeasurementsSum;
      return calculatedValue >= 0 ? calculatedValue : value;
    } else return undefined;
  }

  getElectricHeatingValue(
    { power, temperature, status }: { power: any; temperature: any; status: boolean },
  ): { v?: number; u?: Unit; isTemperature?: boolean } {
    const hasPower = !isNil(power);
    const hasTemperature = !isNil(temperature);

    if (!hasPower && !hasTemperature) return { v: undefined, u: undefined, isTemperature: undefined };
    else {
      return {
        v: !status ? power : temperature,
        u: !status ? undefined : new Unit('°C'),
        isTemperature: status,
      };
    }
  }
}
