
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';

@Component({
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class HardwareSpecification extends WizardComponent {
  doesHover = false;
  get emsModes() {
    return [
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.0.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.1.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.2.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.3.title'),
    ];
  }

  selectedMode: any = this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.1.title');
  selectedIndex: any = 1;
  async prepareVariablesToSend(): Promise<void> {
    // Send operation mode
    this.variablesToSend.set('OperationMode', {
      variable: 'prgEMS.fbEMS.eOperationMode',
      value: this.selectedIndex,
    });

    // Enable EMS
    this.variablesToSend.set('EnableEMS', {
      variable: 'prgEMS.fbEMS.bEnable',
      value: 1,
      feedbackVariable: 'prgEMS.fbEMS.bStateEnable',
      isBoolean: true,
    });
  }

  handleSelection() {
    this.selectedIndex = this.emsModes.indexOf(this.selectedMode); // Set selected mode before save.
  }

  async created() {
    if (this.wasInstallationWizardCompleted) {
      await this.getVariableValuesFromMeasurements();
    }
  }

  async getVariableValuesFromMeasurements() {
    const mqttOperationMode = await this.measurements['prgEMS.fbEMS.eOperationMode'];
    if (mqttOperationMode) {
      this.selectedIndex = mqttOperationMode;
    }
  }
}
