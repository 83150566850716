import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Page404 from '@/ui/views/404.vue';
import Home from '@/ui/views/Home.vue';
import Workbench from '@/ui/views/Project/Workbench.vue';
import Favorites from '@/ui/views/Project/Favorites.vue';
import Measurements from '@/ui/views/Project/Measurements.vue';
import Rooms from '@/ui/views/Project/Rooms.vue';
import DevicesLibrary from '@/ui/views/Project/DevicesLibrary.vue';
import AIML from '@/ui/views/Project/AIML.vue';
import Settings from '@/ui/views/Project/Settings/index.vue';
import UserManual from '@/ui/views/Documentation/UserManual/index.vue';
import APIDocs from '@/ui/views/Documentation/APIDocs.vue';
import DeviceConnectivity from '@/ui/views/Documentation/DeviceConnectivity/index.vue';
import EdgeDevice from '@/ui/views/Documentation/DeviceConnectivity/EdgeDevice.vue';
import EventList from '@/ui/views/Project/EventList.vue';
import Rules from '@/ui/views/Project/Rules.vue';
import Report from '@/ui/views/Project/Report.vue';
import SetupAssistent from '@/ui/views/Project/WizardViews/SetupAssistent.vue';
import Tenant from '@/ui/views/Project/WizardViews/Tenant.vue';
import PartnerProjectView from '@/ui/components/partner/workspace/index.vue';
import store from '@/store';
import { canAccessRoute, isRouteEnabled } from '@/router/utils';
import { SearchType } from '@/utils/searchUtils';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: '404 - Page not found',
    component: Page404,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      appNavigation: true,
      search: true,
      searchType: SearchType.projects,
      addProject: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.home',
      displayChangelog: true,
    },
  },
  {
    path: '/projects/:id',
    component: () => import('@/ui/views/Project/index.vue'),
    children: [
      {
        path: 'workbench',
        name: 'Workbench',
        component: Workbench,
        meta: {
          search: true,
          searchType: SearchType.devices,
          workbenchButtons: true,
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.workbench',
          displayChangelog: true,
        },
      },
      {
        path: 'favorites',
        name: 'Favorites',
        component: Favorites,
        meta: {
          dndSwitch: true,
          search: true,
          searchType: SearchType.devices,
          mqtt: true,
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.favorites',
          displayChangelog: true,
        },
      },
      {
        path: 'variables',
        name: 'Variables',
        component: Measurements,
        meta: {
          search: true,
          searchType: SearchType.measurements,
          advancedTitle: 'numberOfMeasurements',
          mqtt: true,
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.variables',
          displayChangelog: true,
        },
      },
      {
        path: 'areas',
        name: 'Areas',
        component: Rooms,
        meta: {
          dndSwitch: true,
          search: true,
          searchType: SearchType.devices,
          addAreaButton: true,
          toggleAreasViewButton: true,
          mqtt: true,
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.areas',
          displayChangelog: true,
        },
      },
      {
        path: 'devices',
        name: 'Devices Library',
        component: DevicesLibrary,
        meta: {
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.devicesLibrary',
          displayChangelog: true,
        },
      },
      {
        path: 'aiml',
        name: 'AI/ML Library',
        component: AIML,
        meta: {
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.aimlLibrary',
          displayChangelog: true,
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        meta: {
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.settings',
          displayChangelog: false,
        },
        children: [
          {
            path: 'general',
            name: 'General',
            component: () => import('@/ui/views/Project/Settings/General.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'mqtt',
            name: 'MQTT',
            component: () => import('@/ui/views/Project/Settings/MQTT.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'permissions',
            name: 'Permissions',
            component: () => import('@/ui/views/Project/Settings/Permissions.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'downloads',
            name: 'Downloads',
            component: () => import('@/ui/views/Project/Settings/Downloads.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'notification',
            name: 'Notification',
            component: () => import('@/ui/views/Project/Settings/Notification.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'variables',
            name: 'SettingsVariables',
            component: () => import('@/ui/views/Project/Settings/Variables.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              mqtt: true,
              search: true,
              searchType: SearchType.measurements,
              displayChangelog: false,
            },
          },
          {
            path: 'energy-price',
            name: 'EnergyPrice',
            component: () => import('@/ui/views/Project/Settings/EnergyPrice.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
          {
            path: 'check-protocol',
            name: 'CheckProtocol',
            component: () => import('@/ui/views/Project/Settings/CheckProtocol.vue'),
            meta: {
              navigation: 'projectNavigation',
              locale: 'navigation.appNavigation.settings',
              displayChangelog: false,
            },
          },
        ],
      },
      {
        path: 'eventlist',
        name: 'Event List',
        component: EventList,
        meta: {
          search: true,
          searchType: SearchType.events,
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.eventList',
          displayChangelog: true,
        },
      },
      {
        path: 'rules',
        name: 'Rules',
        component: Rules,
        meta: {
          navigation: 'projectNavigation',
          addRuleButton: true,
          search: true,
          searchType: SearchType.rules,
          locale: 'navigation.appNavigation.rules',
          advancedTitle: 'numberOfRules',
          displayChangelog: true,
        },
      },
      {
        path: 'report',
        name: 'Report',
        component: Report,
        meta: {
          navigation: 'projectNavigation',
          createReportButton: true,
          locale: 'navigation.appNavigation.report',
          search: true,
          searchType: SearchType.reports,
          displayChangelog: true,
        },
      },
      {
        path: 'installation-wizard',
        name: 'InstallationWizard',
        component: SetupAssistent,
        meta: {
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.installationWizard',
          mqtt: true,
          displayChangelog: false,
        },
      },
      {
        path: 'tenant-wizard',
        name: 'TenantWizard',
        component: Tenant,
        meta: {
          navigation: 'projectNavigation',
          locale: 'navigation.appNavigation.tenantWizard',
          restartWizardButton: true,
          displayChangelog: false,
        },
      },
    ],
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: () => import('@/ui/views/Ticket.vue'),
    meta: {
      appNavigation: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.ticket',
      displayChangelog: true,
    },
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import('@/ui/views/Documentation/index.vue'),
    meta: {
      appNavigation: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.documentation',
      displayChangelog: true,
    },
    children: [
      {
        path: 'userManual',
        name: 'UserManual',
        meta: {
          title: 'User Manual',
          appNavigation: true,
          documentationView: true,
          navigation: 'appNavigation',
          locale: 'navigation.documentationNavigation.userManual',
          displayChangelog: true,
        },
        component: UserManual,
      },
      {
        path: 'api',
        name: 'APIDocs',
        meta: {
          title: 'API',
          appNavigation: true,
          documentationView: true,
          navigation: 'appNavigation',
          locale: 'navigation.documentationNavigation.apiDocumentation',
          displayChangelog: true,
        },
        component: APIDocs,
      },
      {
        path: 'deviceConnectivity',
        name: 'DeviceConnectivity',
        component: DeviceConnectivity,
        meta: {
          title: 'Device Connectivity',
          appNavigation: true,
          documentationView: true,
          navigation: 'appNavigation',
          locale: 'navigation.documentationNavigation.deviceConnectivity',
          displayChangelog: true,
        },
        children: [
          {
            path: 'edgeDevice',
            name: 'Documentation Modbus TCP Edge Device with Beckhoff PL',
            component: EdgeDevice,
            meta: {
              title: 'Edge Device',
              appNavigation: true,
              documentationView: true,
              navigation: 'appNavigation',
              locale: 'navigation.deviceConnectivityRoutes.modbusTCPEdgeDevice',
              displayChangelog: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: () => import('@/ui/views/Changelog.vue'),
    meta: {
      appNavigation: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.changelog',
    },
    beforeEnter: (to, from, next) => {
      const superAdmin = store.getters['app/getUser'].super_admin;
      if (!superAdmin) next({ name: from.name as string });
      else next();
    },
  },
  {
    path: '/partner-management',
    name: 'PartnerManagement',
    component: () => import('@/ui/views/PartnerManagement.vue'),
    meta: {
      appNavigation: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.PartnerManagement',
      displayChangelog: true,
    },
    beforeEnter: (to, from, next) => {
      // if the user is super admin, he can access the route
      // else the user must be a partner to access the route
      const superAdmin = store.getters['app/getUser'].super_admin;
      if (superAdmin) {
        next();
      } else {
        const partner = store.getters['app/getUser']?.partner ?? false;
        if (!partner) next({ name: from.name as string });
        else next();
      }
    },
  },
  {
    path: '/partner-workspace',
    name: 'PartnerWorkspace',
    component: () => import('@/ui/views/PartnerView.vue'),
    meta: {
      searchType: SearchType.workspaces,
      search: true,
      appNavigation: true,
      navigation: 'appNavigation',
      locale: 'navigation.appNavigation.PartnerWorkspace',
      createPartnerProjectButton: true,
    },
    beforeEnter: (to, from, next) => {
      // if the user is super admin, he can access the route
      // else the user must be a partner to access the route
      const superAdmin = store.getters['app/getUser'].super_admin;
      if (superAdmin) {
        next();
      } else {
        const partner = store.getters['app/getUser']?.partner ?? false;
        if (!partner) next({ name: from.name as string });
        else next();
      }
    },
  },
  {
    path: '/partner-workspace/:id',
    name: 'PartnerProjectView',
    component: PartnerProjectView,
    meta: {
      navigation: 'partnerNavigation',
      locale: 'navigation.appNavigation.PartnerWorkspace',
      displayChangelog: true,
    },
  },
];

const router: VueRouter = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

/**
 * Access check for project routes
 */
router.beforeEach((to, from, next) => {
  const routeEnabled = isRouteEnabled(to.name as string);
  const routeAccess = canAccessRoute(to.name as string);
  if (!routeAccess || !routeEnabled) next({ name: from.name as string });
  else next();
});

export default router;
