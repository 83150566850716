
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import ShowEventBaseGroup from '@/ui/components/devices/mpc/base/ShowEventBase.vue';
import BatteryLevel from '@/ui/components/devices/components/EnergyParts/BatteryLevel.vue';
import {
  EnergyCircleDisplayData,
  CirclePosition,
  PlaceholderEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import EnergyCircleValue from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyCircle/EnergyCircleValue.vue';
import ChargeIndicator from '@/ui/components/devices/components/EnergyParts/ChargeIndicator/index.vue';
import HouseCalculationInfo from '@/ui/components/devices/components/EnergyParts/HouseCalculationInfo/index.vue';
import { EnergyCircleType, isProducer } from '@/types/energyVisualisation/EnergyCircleType';
import { kiloWattUnit } from '@/utils/unit/unitConstants';
import { convertNegativeZero, countDecimalPlaces } from '@/utils/utilsFunctions';

@Component({
  components: {
    BatteryLevel,
    RotatingBacklight,
    ShowEventBaseGroup,
    EnergyCircleValue,
    ForceChargeIndicator: ChargeIndicator,
    HouseCalculationInfo,
  },
  methods: { convertNegativeZero },
})
export default class EnergyCircle extends Vue {
  @Prop({}) displayData!: EnergyCircleDisplayData;
  @Inject({ from: 'onCircleClick', default: () => {} }) onCircleClick?: (
    circleData: EnergyCircleDisplayData,
  ) => void;

  /**
   * Returns the bottom value formatted with one decimal place if the value has more than one decimal place.
   */
  get formattedBottomValue() {
    let val: any = this.displayData.bottomValue;
    if (typeof this.displayData.bottomValue === 'number' && countDecimalPlaces(this.displayData.bottomValue) > 1) {
      val = parseFloat(this.displayData.bottomValue.toFixed(1));
    }
    const id = this.displayData.id.replace(/[0-9]/g, '') as EnergyCircleType;
    if (val < 0 && isProducer(id)) {
      return val * -1;
    }
    return val;
  }

  get energyCircleValueValue() {
    return this.unit.unitForValue(convertNegativeZero(this.formattedBottomValue ?? 0)).formattedWithUnit;
  }
  get energyCircleValueShow() {
    return this.displayData.hideValue !== false && !this.showLoadingAnimationHouseCircle;
  }

  get titlePosition() {
    return this.displayData.position === CirclePosition.bottom ? 'bottom' : 'top';
  }

  get systemCountPosition() {
    return this.displayData.position === CirclePosition.bottom
      ? 'system-count-bottom'
      : 'system-count-top';
  }

  get isPlaceholder() {
    return this.displayData instanceof PlaceholderEnergyCircleDisplayData;
  }

  get isBatterySoc() {
    return this.displayData.centerContent.type === 'soc';
  }

  get isForceChargeIndicator() {
    return this.isBatterySoc &&
      (
        this.displayData.additionalDisplayData?.forceCharge ||
        this.displayData.additionalDisplayData?.calibrationCharge ||
        this.displayData.additionalDisplayData?.maintenanceCharge ||
        this.displayData.additionalDisplayData?.chargeMaintenance
      );
  }

  get showLoadingAnimationHouseCircle() {
    return this.displayData.additionalDisplayData?.showLoadingAnimationHouseCircle ?? false;
  }

  get unit() {
    return this.displayData.unit ?? kiloWattUnit;
  }

  get rotatingBacklightNoData() {
    if (this.displayData.additionalDisplayData?.forceAnimation) return false;
    return !(this.displayData.bottomValue ?? this.displayData.alternativeValue);
  }

  get disableSpinner() {
    return this.displayData.disableSpinner !== true;
  }

  get valuePositionClass() {
    return this.displayData.position === CirclePosition.bottom
      ? 'energy-circle-bottom-value'
      : 'energy-circle-top-value';
  }

  get circleContentStyle() {
    if (this.displayData.bottomValue === undefined) {
      return {};
    } else {
      return {
        transform:
          this.displayData.position === CirclePosition.bottom
            ? 'translateY(4px)'
            : 'translateY(-4px)',
      };
    }
  }

  energyCircleOnClick() {
    this.onCircleClick?.(this.displayData);
  }
}
