
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import FlatDetails from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/FlatsComponents/FlatDetails.vue';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { cloneDeep } from 'lodash';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { downloadDocument } from '@/utils/tenantWizardUtilsFunctions';
import InvoiceHistory from '@/ui/components/wizards/tenantWizard/components/InvoiceHistory.vue';
import { UserRoleCode } from '@/utils/userRoles';
import { timeToMinutes } from '@/utils/utilsFunctions';
import { IProject } from '@/types/project.types';
import { IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    FlatDetails,
    InfoTooltip,
    InvoiceHistory,
    DeleteModalForm,
  },
})
export default class BasicTenantView extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;
  @Getter('members/currentMember') currentMember!: any;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Mutation('tenantWizard/setWasTenantDoneState') setWasTenantDoneState!: (data: boolean) => void;
  @Action('tenantWizard/updateTenant') updateTenant!: (data: { project_id: string; tenant: ITenantModel}) => void;
  @Action('tenantWizard/downLoadTenant') downLoadTenant!: (data: {start: number; end: number; project_id: string}) => any;
  @Action('tenantWizard/deleteTenant') deleteTenant!: (project_id: string) => any;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  showLoaderSpecificDownload = false;
  tariffsValid = false;
  rerenderKey = true;
  tariffSettings: ITenantModel['general']['tariffSettings'] = {
    electricity: {
      lowTariffProduction: 0,
      highTariffProduction: 0,
      lowTariffGrid: 0,
      highTariffGrid: 0,
      allDayTariffGrid: 0,
      allDayTariffProduction: 0,
      residualElectricityComposition: [],
      lowTariffTimesProduction: {
        start: '00:00',
        end: '00:00',
      },
      lowTariffTimesGrid: {
        start: '00:00',
        end: '00:00',
      },
      residualElectricityText: '',
    },
    water: {
      priceServiceWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceColdWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceHeating: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceCooling: 0, // should have same currency like selected inside "general.paymentDetails.currency"
    },
  };
  selectedCurrency = '';
  sideCosts = [{ name: '', amount: 0 }];
  download= {
    start: '', // will contain the start date for the accounting period
    end: '', // will contain the end date for the accounting period
  };
  interval = 'Year'
  showAllDayTariffFields = false;

  get periodOptions() {
    return [
      { value: 'Year', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Year') },
      { value: 'HalfYear', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.HalfYear') },
      { value: 'QuarterYear', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.QuarterYear') },
      { value: 'Month', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Month') },
      { value: 'Never', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Never') },
    ];
  }

  /* check if time for production tariffs is valid */
  get productionTariffsValid() {
    let valid = true;
    const values: any = this.tariffSettings.electricity.lowTariffTimesProduction;
    valid = timeToMinutes(values.start) < timeToMinutes(values.end);
    if (!valid) return this.$t('modals.manageReport.errorMessages.timeError');
    return valid;
  }

  /* check if time for grid tariffs is valid */
  get gridTariffsValid() {
    let valid = true;
    const values: any = this.tariffSettings.electricity.lowTariffTimesGrid;
    valid = timeToMinutes(values.start) < timeToMinutes(values.end);
    if (!valid) return this.$t('modals.manageReport.errorMessages.timeError');
    return valid;
  }

  handleTariffSelectionChange() {
    if (!this.showAllDayTariffFields) {
      // clear all day tariff fields
      this.tariffSettings.electricity.allDayTariffGrid = 0;
      this.tariffSettings.electricity.allDayTariffProduction = 0;
    } else {
      // clear high/low tariff fields
      this.tariffSettings.electricity.lowTariffTimesProduction.start = '00:00';
      this.tariffSettings.electricity.lowTariffTimesProduction.end = '00:00';
      this.tariffSettings.electricity.lowTariffTimesGrid.start = '00:00';
      this.tariffSettings.electricity.lowTariffTimesGrid.end = '00:00';
      this.tariffSettings.electricity.lowTariffGrid = 0;
      this.tariffSettings.electricity.highTariffGrid = 0;
      this.tariffSettings.electricity.lowTariffProduction = 0;
      this.tariffSettings.electricity.highTariffProduction = 0;
    }
    this.$nextTick(() => {
      this.validate();
    });
  }

  get isCurrentMemberAdmin() {
    return this.currentMember.role === UserRoleCode.admin;
  }

  // contains current date string to set max date for calendar
  get maxDateCalendar() {
    return new Date().toDateString();
  }

  // checks if the download dates are valid
  get areDownloadDatesValid() {
    const start = new Date(this.download.start).getTime() / 1000;
    const end = new Date(this.download.end).getTime() / 1000;
    return end > start;
  }

  get areDownloadFieldsFilled() {
    if (this.download.start !== '' && this.download.end !== '' && this.download.start !== null && this.download.end !== null) {
      return true;
    }
    return false;
  }

  async handleDownload() {
    this.showLoaderSpecificDownload = true;
    try {
      await downloadDocument(this.download, this.$route.params.id);
      this.showLoaderSpecificDownload = false;
    } catch (error) {
      console.log(error);
      this.showLoaderSpecificDownload = false;
    }
  }

  created() {
    this.tariffSettings = this.tenantState.tenant.general.tariffSettings;
    this.sideCosts = this.tenantState.tenant.general.sideCosts;
    this.selectedCurrency = this.tenantState.tenant.general.paymentDetails.currency;
    this.interval = this.tenantState.tenant.billingSettings.interval;

    // if one of the all day tariff fields is not 0, show the all day tariff fields
    if (this.tariffSettings.electricity.allDayTariffGrid !== 0 || this.tariffSettings.electricity.allDayTariffProduction !== 0) {
      this.showAllDayTariffFields = true;
    }
  }

  mounted() {
    this.validate();
  }

  /**
   * Updated one specific flat inside the tenant model
   */
  async updateSpecificFlat(data: {flat: IFlatsConfig; index: number}) {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    const flatDataForBackend = { ...data.flat };
    flatDataForBackend.address.street = `${flatDataForBackend.address.street}, ${flatDataForBackend.address.houseNumber}`;
    delete flatDataForBackend.address.houseNumber;
    tenantCopy.general.flats.flatConfigurations[data.index] = flatDataForBackend;
    this.updateTenantObject(tenantCopy);
    // rerender is needed in order for the flat details to be displayed correctly
    this.rerenderKey = !this.rerenderKey;
  }

  addSideCost() {
    this.sideCosts.push({ name: '', amount: 0 });
  }

  removeSideCost(index: number) {
    this.sideCosts.splice(index, 1);
  }

  /**
   * Saves the tariff settings inside the tenant model
   */
  async saveTariffSettings() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.tariffSettings = this.tariffSettings;
    tenantCopy.general.sideCosts = this.sideCosts;
    tenantCopy.billingSettings.interval = (this.interval as 'Year' | 'HalfYear' | 'QuarterYear' | 'Month');
    this.updateTenantObject(tenantCopy);
    await this.updateTenantModel();
  }

  /**
   * Handles the input event inside the FlatDetails component
   */
  handleInput() {
    this.$emit('tenantModelUpdated', true);
  }

  /**
   * Updates the tenant model in the project meta
   */
  async updateTenantModel() {
    await this.updateTenant({ project_id: this.$route.params.id, tenant: this.tenantState.tenant });
    this.$emit('tenantModelUpdated', false);
  }

  validate() {
    (this.$refs.tariffForm as any).validate();
  }

  async handleUpdateProject() {
    const projectCopy = { ...this.project };
    projectCopy.meta.wasTenantWizardCompleted = false;
    await this.updateProject(projectCopy);
  }

  async handleDeleteTenant() {
    this.setWasTenantDoneState(false);
    await this.deleteTenant(this.$route.params.id);
    await this.handleUpdateProject();
  }
}
