import {
  IMQTTVariable,
  InstallationWizardVariables,
} from '@/types/wizards/installationWizard.types';

const buildVariable = (name: string, value: number | string, feedbackVariable: string, isBoolean = false): IMQTTVariable => {
  const variable: IMQTTVariable = {
    variable: name,
    value,
    isBoolean,
  };
  if (feedbackVariable) variable.feedbackVariable = feedbackVariable;
  return variable;
};

const chargeStationVariables = (value: number | string) => {
  return {
    setWebastoNext: {
      variable: 'prgCS.wWebasto_Next',
      value,
    },
    setWebastoUnite: {
      variable: 'prgCS.wWebasto_Unite',
      value,
    },
    setWeidmüller: {
      variable: 'prgCS.wWeidmueller',
      value,
    },
    setSchneider: {
      variable: 'prgCS.wSchneider',
      value,
    },
    chargeStationSession: (systemIndex: number) => {
      return {
        variable: `prgCS.byStartSession_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgCS.bStateStartSession_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    chargeStationEnable: (systemIndex: number) => {
      return {
        variable: `prgCS.bEnable_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgCS.bSEnabled_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    chargeStationFreeCharge: (systemIndex: number) => {
      return {
        variable: `prgCS.bUseNotFreeCharge_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgCS.bSUseNotFreeCharge_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    chargeStationEmergencyPowerOff: (systemIndex: number) => {
      return {
        variable: `prgCS.bOnEmergPowerOff_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgCS.bSOnEmergPowerOff_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    chargeStationMinPower: (systemIndex: number) => {
      return {
        variable: `prgCS.byMinPower_${systemIndex + 1}`,
        feedbackVariable: `prgCS.bySMinPower_${systemIndex + 1}`,
        value,
      };
    },
    chargeStationPriority: (systemIndex: number) => {
      return {
        variable: `prgCS.byPrio_${systemIndex + 1}`,
        value,
      };
    },
    chargeStationEnableSOC: (systemIndex: number) => {
      return {
        variable: `prgCS.byEnaSOC_${systemIndex + 1}`,
        value,
      };
    },
    chargeStationDisableSOC: (systemIndex: number) => {
      return {
        variable: `prgCS.byDisabSOC_${systemIndex + 1}`,
        value,
      };
    },
    chargeStationMaxPower: (systemIndex: number) => {
      return {
        variable: `prgCS.rMaxPower_${systemIndex + 1}`,
        value,
      };
    },
  };
};

const heatingPumpVariables = (value: number | string) => {
  return {
    setLambda: {
      variable: 'prgHP.wLambdaEureka',
      value,
    },
    setSoltop: {
      variable: 'prgHP.wSoltopMTech',
      value,
      customTimeout: 5,
      quickSend: true,
    },
    heatingPumpReset: (systemIndex: number) => {
      return {
        variable: `prgHP.bReset_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgHP.bSReset_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    heatingPumpEnable: (systemIndex: number) => {
      return {
        variable: `prgHP.bEnable_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgHP.bSEnabled_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    heatingPumpOnEmergencyPowerOff: (systemIndex: number) => {
      return {
        variable: `prgHP.bOnEmergPowerOff_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgHP.bSOnEmergPowerOff_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    heatingPumpTimeEnabled: (systemIndex: number) => {
      return {
        variable: `prgHP.bTimeEnabled_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgHP.bSTimeEnabled_${systemIndex + 1}`,
        isBoolean: true,
      };
    },
    heatingPumpTimeEnabledPower: (systemIndex: number) => {
      return {
        variable: `prgHP.byTimeEnabledPwr_${systemIndex + 1}`,
        value,
        feedbackVariable: `prgHP.bySTimeEnabledPwr_${systemIndex + 1}`,
      };
    },
    heatingPumpHourOn: (systemIndex: number) => {
      return {
        variable: `prgHP.byHourOn_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpHourOff: (systemIndex: number) => {
      return {
        variable: `prgHP.byHourOff_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpMinuteOn: (systemIndex: number) => {
      return {
        variable: `prgHP.byMinuteOn_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpMinuteOff: (systemIndex: number) => {
      return {
        variable: `prgHP.byMinuteOff_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpPriority: (systemIndex: number) => {
      return {
        variable: `prgHP.byPrio_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpEnableSOC: (systemIndex: number) => {
      return {
        variable: `prgHP.byEnaSOC_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpDisableSOC: (systemIndex: number) => {
      return {
        variable: `prgHP.byDisabSOC_${systemIndex + 1}`,
        value,
      };
    },
    heatingPumpPower: (systemIndex: number) => {
      return {
        variable: `prgHP.rMaxPower_${systemIndex + 1}_1`,
        value,
      };
    },
    heatingPumpMaxPower1: (systemIndex: number) => {
      return {
        variable: `prgHP.rMaxPower_${systemIndex + 1}_1`,
        value,
      };
    },
    heatingPumpMaxPower2: (systemIndex: number) => {
      return {
        variable: `prgHP.rMaxPower_${systemIndex + 1}_2`,
        value,
      };
    },
  };
};

const installationWizardVariables = (name: string, page: string, value: number | string = 0, systemIndex = 0) => {
  const pages = {
    componentsPage: {
      disableEMS: buildVariable('prgEMS.fbEMS.bEnable', 0, 'prgEMS.fbEMS.bStateEnable', true),
      enableEMS: {
        variable: 'prgEMS.fbEMS.bEnable',
        value: 1,
        feedbackVariable: 'prgEMS.fbEMS.bStateEnable',
        isBoolean: true,
      },
      maxPowerInverter: {
        variable: 'prgEnergy.fbI.rMaxPowerInverter',
        value,
      },
      inverterCount: {
        variable: 'prgEnergy.byNumberOfInverters',
        value,
      },
      acpvOnGrid: {
        variable: 'prgEnergy.fbI.bChrgACPVOnGrid',
        value,
        feedbackVariable: 'prgEnergy.fbI.bS_ChrgACPVOnGrid',
        isBoolean: true,
      },
      isBattery: {
        variable: 'prgEnergy.fbI.bIsABattery',
        value,
        feedbackVariable: 'prgEnergy.fbI.bS_IsABattery',
        isBoolean: true,
      },
      batteryCapacity: (systemIndex: number) => {
        return { variable: `prgEnergy.lrMaxCapacityBattery${systemIndex + 1}`, value };
      },
      electricHeatingPower: (systemIndex: number) => {
        return { variable: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.rMaxPower1`, value };
      },
      chargeStationPower: (systemIndex: number) => {
        return { variable: `prgCS.fbCS${systemIndex}.stSetupECS.rMaxPower`, value };
      },
      useNotFreeCharge: (systemIndex: number) => {
        return {
          variable: `prgCS.bUseNotFreeCharge_${systemIndex}`,
          feedbackVariable: `prgCS.bS_UseNotFreeCharge_${systemIndex}`,
          value,
          isBoolean: true,
        };
      },
      heatingPump: (systemIndex: number) => {
        return { variable: `prgHP.fbHP.stSetupHP.rMaxPower${systemIndex + 1}`, value };
      },
      bigConsumerPower: (systemIndex: number) => {
        return { variable: `prgOBC.fbBC${systemIndex + 1}.stSetupOBC.rMaxPower`, value };
      },
      loadShedding: {
        variable: 'prgIO.rPowerLDrop',
        value,
      },
      maxPowerDrop: (systemIndex: number) => {
        return { variable: `prgIO.rMaxPowerLDrop_C${systemIndex}`, value };
      },
      disableBigConsumer: (systemIndex: number) => {
        return {
          variable: `prgOBC.fbBC${systemIndex}.bEnable`,
          value: 0,
          feedbackVariable: `prgOBC.fbBC${systemIndex}.stDataOBC.bEnabled`,
          isBoolean: true,
        };
      },
      disableLegionellaProtection: {
        variable: `prgEHE.bDisableLegionellaPr_${systemIndex}`,
        value,
        feedbackVariable: `prgEHE.bSDisableLegionellaPr_${systemIndex}`,
        isBoolean: true,
      },
      disableHeatingPump: {
        variable: 'prgHP.fbHP.bEnable',
        value: 0,
        feedbackVariable: 'prgHP.fbHP.stDataHP.bEnabled',
        isBoolean: true,
      },
      useAsGenerator: {
        variable: 'prgEnergy.fbI.bUseGenPortAsGen',
        value: 1,
        feedbackVariable: 'prgEnergy.fbI.bS_UseGenPortAsGen',
      },
      useAsBHKW: {
        variable: 'prgEnergy.fbI.bUseGenPortAsBHKW',
        value: 1,
        feedbackVariable: 'prgEnergy.fbI.bS_UseGenPortAsBHKW',
      },
      useAsACPV: {
        variable: 'prgEnergy.fbI.bUseGenPortAsPV',
        value: 1,
        feedbackVariable: 'prgEnergy.fbI.bS_UseGenPortAsPV',
      },
      isACPV: {
        variable: 'prgEnergy.fbI.bIsACPV',
        value,
        feedbackVariable: 'prgEnergy.fbI.bS_IsACPV',
      },
      numberOfBHKW: {
        variable: 'prgBHKW.fbBHKW.byNumberOfBHKW',
        value: 1,
      },
      powerOfGenerator: {
        variable: 'prgEnergy.fbI.dwPowerOfGen',
        value,
      },
      parallelControl: {
        variable: 'prgEnergy.fbI.bActivateParallelControl',
        value,
        feedbackVariable: 'prgEnergy.fbI.bS_ActivateParallelControl',
      },
      batteryInput: {
        variable: 'prgEnergy.fbI.bBatteryInput2InUse',
        value: 0,
        feedbackVariable: 'prgEnergy.fbI.bS_BatteryInput2InUse',
      },
      oneBatteryInput: {
        variable: 'prgEnergy.fbI.bOneBatteryTo2Inputs',
        value,
        feedbackVariable: 'prgEnergy.fbI.bS_OneBatteryTo2Inputs',
      },
      pvPowerExt: {
        variable: 'prgEnergy.fbI.bPVPowerExt',
        value: 0,
        feedbackVariable: 'prgEnergy.fbI.bS_PVPowerExt',
      },
      internalPV: {
        variable: 'prgEnergy.fbI.bPVPowerIntern',
        value: 1,
        feedbackVariable: 'prgEnergy.fbI.bS_PVPowerIntern',
      },
      numberOfInverters: {
        variable: 'prgEnergy.fbI.byNumberOfInverters',
        value,
      },
      numberOfBatteries: {
        variable: 'prgEnergy.fbI.byNumberOfBatterys',
        value,
      },
      enableBattery: {
        variable: 'prgEnergy.fbI.bEnable',
        value: 1,
        feedbackVariable: 'prgEnergy.fbI.stDataBattInverter.bEnabled',
        isBoolean: true,
      },
      lastVariable: {
        variable: '',
        value: 0,
        customTimeout: 15,
        quickSend: true,
      },
      numberOfMeters: {
        variable: 'prgPilot.byNumberOfMeters',
        value,
      },
      disablePilot: {
        variable: 'prgPilot.bEnable',
        value: 0,
      },
      webastoType: (systemIndex: number) => {
        return {
          variable: `prgCS.byTypeECS_${systemIndex + 1}`,
          value,
        };
      },
      ...chargeStationVariables(value),
      ...heatingPumpVariables(value),
    },
    pilotPage: {
      enablePilot: {
        variable: 'prgPilot.bEnable',
        value: 1,
      },
      numberOfMeters: {
        variable: 'prgPilot.byNumberOfMeters',
        value,
      },
      ctRelationship: (systemIndex: number) => {
        return {
          variable: `prgPilot.wCT_${systemIndex + 1}`,
          value,
        };
      },
    },
    batteryGridPage: {
      enableHouse: {
        variable: 'prgEnergy.fbHouse.bEnable',
        value: 1,
        feedbackVariable: 'prgEnergy.fbHouse.stDataHouseC.bEnabled',
        isBoolean: true,
      },
      size: { value, variable: 'prgEnergy.fbGrid.rSizeGridConn' },
      priority: { value, variable: 'prgEnergy.fbI.byPriority' },
      reserve: { value, variable: 'prgEMS.fbEMS.stSetupEMS.byReserveSOCEPO' },
      switchBackTimeFromEmergencyPower: { value, variable: 'prgEnergy.fbI.wReconnTimeGrid' },
      gridMeasurementViaCHINT: {
        value,
        variable: 'prgEnergy.fbI.bUseSmartMeter',
        feedbackVariable: 'prgEnergy.fbI.bS_UseSmartMeter',
      },
      smartMeterType: { value, variable: 'prgEnergy.fbI.bySmartMeterType' },
      maximumCharge: { value, variable: 'prgEMS.fbEMS.stSetupEMS.byResChrgGridToBatt' },
      updateTime: { value, variable: 'prgEMS.fbEMS.stSetupEMS.uiUpdateTime' },
      chargeFromGrid: { value, variable: 'prgEMS.fbEMS.stSetupEMS.bAllowChrgFromGrid' },
      prioritizedCharging: { value, variable: 'prgEMS.fbEMS.stSetupEMS.byPrioChrgBattSOCInEPO' },
      minimumChargeState: { value, variable: 'prgEMS.fbEMS.stSetupEMS.byMaxDepthOfDischrgEPO' },
    },
  } as InstallationWizardVariables;
  return typeof pages[page][name] !== 'function' ? pages[page][name] : (pages[page][name] as Function)(systemIndex);
};
export default installationWizardVariables;
