var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12"}},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"sm":"6","lg":"3"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$tc( `installationWizard.defineComponents.componentsPage.systems.${_vm.system}.title`, 2, ))+" "),(_vm.system === 'pv' && _vm.isSolarmax)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('installationWizard.defineComponents.componentsPage.systems.pv.perInverter'))+" ")]):_vm._e()]),_c('v-checkbox',{staticStyle:{"margin-right":"0"},attrs:{"input-value":_vm.includedSystemsTypes[_vm.system].isSelected,"color":"accent","disabled":_vm.systemLimits[_vm.system].min > 0},on:{"change":function($event){return _vm.handleCheckbox($event, _vm.system)}}})],1),_c('v-col',{attrs:{"sm":"6","lg":"2"}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.includedSystemsTypes[_vm.system].isSelected)?_c('v-select',{key:_vm.includedSystemsTypes[_vm.system].count,attrs:{"value":_vm.includedSystemsCount[_vm.system],"items":Array.from(
                        { length: _vm.systemLimits[_vm.system].max - _vm.systemLimits[_vm.system].min + 1 },
                        (_, index) => index + _vm.systemLimits[_vm.system].min,
                      ),"label":_vm.$t('modals.manageMLModel.quantity'),"color":"accent","item-color":"accent","hide-details":"","outlined":"","rules":[..._vm.itemsContainValue(Object.values(Array.from(
                        { length: _vm.systemLimits[_vm.system].max - _vm.systemLimits[_vm.system].min + 1 },
                        (_, index) => index + _vm.systemLimits[_vm.system].min,
                      )), _vm.includedSystemsCount[_vm.system])]},on:{"change":function($event){return _vm.handleQuantitySelect($event, _vm.system)}}}):_vm._e(),(_vm.system[0] !== 'battery')?_c('div',{staticClass:"d-flex justify-center pt-1 pl-2"},[(_vm.includedSystemsTypes[_vm.system].isSelected && _vm.system !== 'battery')?_c('InfoTooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [(_vm.system !== 'heating_pump')?_c('div',[_vm._v(" "+_vm._s(_vm.$t( `installationWizard.defineComponents.componentsPage.systems.${_vm.system}.tooltip`, ))+" ")]):_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t(`installationWizard.defineComponents.componentsPage.systems.${_vm.system}.additionalTooltip`)))])])]},proxy:true}],null,false,2517112990)}):_vm._e()],1):_vm._e(),_vm._t("action")],2)])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"12"}},[_c(_vm.componentsMapping[_vm.system],{ref:_vm.componentsMapping[_vm.system],tag:"component",attrs:{"includedSystemsTypes":_vm.includedSystemsTypes},on:{"validationStatus":_vm.handleStatusChange}})],1),_c('v-col',{staticClass:"pb-0 pt-4",attrs:{"cols":"12"}},[(_vm.system[0] !== 'pilot')?_c('v-divider',{attrs:{"color":"blue"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }