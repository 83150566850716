
import { Vue, Component, Prop } from 'vue-property-decorator';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent LightPushButton device
 */
@Component({
  components: {
    SwitchBase,
    PushButtonBase,
    DeviceLayout,
  },
})
export default class LightPushButton extends Vue {
  @Prop() deviceData!: IDevice;
}
