
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import {
  batteryCapacity,
  BatteryType,
  deyeBatteryTypes,
  deyeTypes,
  inverterPowers,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { IProject } from '@/types/project.types';
import { IMeasurements } from '@/types/measurements.types';
import { itemsContainValue } from '@/utils/utilsFunctions';
import { IIncludedSystemsBatteryDefinition, IIncludedSystemsTypes } from '@/types/wizards/installationWizard.types';
import { plcVersionDate } from '@/utils/versionManagementUtils';

@Component({
  methods: { itemsContainValue },
  computed: {
    batteryTypes() {
      return BatteryType;
    },
  },
  components: {
    InfoTooltip,
    EnergyVisualisationPreview,
  },
})
export default class BatteryComponentSettings extends mixins(Validation, BasicControl) {
  @Prop({ default: {} }) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;
  @Getter('measurements/measurements') projectMeasurements!: IMeasurements;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    payload: { systemName: string; systemIndex: number; prop: string; value: any },
  ) => void;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (
    projectId: string,
  ) => Promise<void>;
  // variables are needed to use those types inside template
  localBatteryTypes = BatteryType;
  localDeyeTypes = deyeTypes;
  localBatteryCapacity: any = batteryCapacity;
  localInverterPowers = inverterPowers;

  rerenderKey = 0;
  valid = false;
  doesHover = false;
  deyeInverterPower = 0;

  get projectBatteryType() {
    // contains type of system thats selected inside the project settings
    const { batterySystemType } = this.project?.meta?.controller;
    return batterySystemType === undefined ? '' : batterySystemType;
  }

  @Watch('includedSystemsTypes.battery.definition', { deep: true })
  updateValidation() {
    this.$nextTick(() => {
      this.validate();
      this.rerenderKey++;
    });
  }

  isDeye(battery: IIncludedSystemsBatteryDefinition) {
    return deyeBatteryTypes.includes(battery.systemType as BatteryType);
  }

  get batteryInfoTooltipText() {
    if (this.isDeye(this.includedSystemsTypes.battery.definition[0])) {
      return this.$t('installationWizard.defineComponents.componentsPage.toolTips.battery.deye');
    } else {
      return this.$t('installationWizard.defineComponents.componentsPage.toolTips.battery.azzurro');
    }
  }

  validate() {
    (this.$refs.form as any).validate();
    this.$emit('validationStatus', { system: 'battery', status: this.valid });
  }

  hasMultipleBatteryTypes(system: string) {
    return [BatteryType.DEYE.toString()].includes(system);
  }

  /**
   * Returns Battery items filtered by System Type and availableFrom date
   */
  getBatteryItems(system: IIncludedSystemsBatteryDefinition) {
    if (!system.systemType) return [];
    const batteryCapacityItems = this.getBatteryCapacityItems(system);
    return batteryCapacityItems.filter((capacityElement: { name: string; value: string; availableFrom: Date }) => capacityElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());
  }
  batteryItemValue(system: IIncludedSystemsBatteryDefinition) {
    return this.getBatteryItems(system).map((capacityElement: { name: string; value: string; availableFrom: number }) => capacityElement.value);
  }

  getBatteryCapacityItems(system: IIncludedSystemsBatteryDefinition) {
    let batteryCapacityItems;
    if (this.hasMultipleBatteryTypes(system.systemType)) {
      batteryCapacityItems = system.batteryType
        ? this.localBatteryCapacity[system.systemType][system.batteryType]
        : [];
    } else {
      batteryCapacityItems = this.localBatteryCapacity[system.systemType];
    }
    return batteryCapacityItems;
  }
  /**
   * Returns Inverter items filtered by Battery Type and availableFrom date
   */
  getInverterPowers(batteryType: BatteryType) {
    if (!batteryType) {
      return [];
    }
    const inverterPowersByBatteryType = this.localInverterPowers[batteryType];
    return inverterPowersByBatteryType.filter((inverterElement: { name: string; value: string; availableFrom: Date }) => inverterElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());
  }

  // updates IncludedSystemsDefinitions in Store
  async handleChange(system_index: number | string, prop: string, value: any) {
    const index = typeof system_index === 'string' ? parseInt(system_index, 10) : system_index;
    this.handleIncludedSystemsTypesSystemDefinitionProps({ systemName: 'battery', systemIndex: index, prop, value });
    this.rerenderKey++;
    this.$nextTick(() => {
      this.validate();
    });
  }

  async created() {
    const currentInverterPower: any = Object.entries(this.projectMeasurements).find((element: any) => element[0] === 'prgEnergy.fbI.rMaxPowerInverter');
    this.deyeInverterPower = currentInverterPower !== undefined ? currentInverterPower[1] : 0;
  }

  async mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }
}
